/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealReward } from './DealReward';
import {
    DealRewardFromJSON,
    DealRewardFromJSONTyped,
    DealRewardToJSON,
} from './DealReward';

/**
 * 
 * @export
 * @interface RedemptionListReward
 */
export interface RedemptionListReward {
    /**
     * 
     * @type {number}
     * @memberof RedemptionListReward
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof RedemptionListReward
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof RedemptionListReward
     */
    readonly description: string;
    /**
     * 
     * @type {string}
     * @memberof RedemptionListReward
     */
    readonly price: string;
    /**
     * 
     * @type {string}
     * @memberof RedemptionListReward
     */
    readonly amount_saved: string;
    /**
     * 
     * @type {string}
     * @memberof RedemptionListReward
     */
    readonly image: string;
    /**
     * Specify how many menu items come with this reward 
     * @type {number}
     * @memberof RedemptionListReward
     */
    readonly quantity: number;
    /**
     * 
     * @type {boolean}
     * @memberof RedemptionListReward
     */
    readonly is_archived: boolean;
    /**
     * 
     * @type {string}
     * @memberof RedemptionListReward
     */
    readonly reel_id: string;
    /**
     * 
     * @type {string}
     * @memberof RedemptionListReward
     */
    readonly food_cost: string | null;
    /**
     * 
     * @type {string}
     * @memberof RedemptionListReward
     */
    readonly cook_time: string | null;
}

/**
 * Check if a given object implements the RedemptionListReward interface.
 */
export function instanceOfRedemptionListReward(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "amount_saved" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "is_archived" in value;
    isInstance = isInstance && "reel_id" in value;
    isInstance = isInstance && "food_cost" in value;
    isInstance = isInstance && "cook_time" in value;

    return isInstance;
}

export function RedemptionListRewardFromJSON(json: any): RedemptionListReward {
    return RedemptionListRewardFromJSONTyped(json, false);
}

export function RedemptionListRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedemptionListReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'price': json['price'],
        'amount_saved': json['amount_saved'],
        'image': json['image'],
        'quantity': json['quantity'],
        'is_archived': json['is_archived'],
        'reel_id': json['reel_id'],
        'food_cost': json['food_cost'],
        'cook_time': json['cook_time'],
    };
}

export function RedemptionListRewardToJSON(value?: RedemptionListReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

