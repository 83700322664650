/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BusinessPhysicalAddressGeoPoint } from './BusinessPhysicalAddressGeoPoint';
import {
    BusinessPhysicalAddressGeoPointFromJSON,
    BusinessPhysicalAddressGeoPointFromJSONTyped,
    BusinessPhysicalAddressGeoPointToJSON,
} from './BusinessPhysicalAddressGeoPoint';
import type { PriceLevelEnum } from './PriceLevelEnum';
import {
    PriceLevelEnumFromJSON,
    PriceLevelEnumFromJSONTyped,
    PriceLevelEnumToJSON,
} from './PriceLevelEnum';

/**
 * 
 * @export
 * @interface BusinessList
 */
export interface BusinessList {
    /**
     * 
     * @type {string}
     * @memberof BusinessList
     */
    readonly id: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessList
     */
    readonly is_pro: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessList
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessList
     */
    readonly currency_symbol: string;
    /**
     * 
     * @type {BusinessPhysicalAddressGeoPoint}
     * @memberof BusinessList
     */
    physical_address_geo_point: BusinessPhysicalAddressGeoPoint | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessList
     */
    readonly google_place_rating: string | null;
    /**
     * 
     * @type {number}
     * @memberof BusinessList
     */
    readonly google_place_review_count: number | null;
    /**
     * The price level of this business. Overridden by Google data for businesses on Google.
     * 
     * * `0` - Free
     * * `1` - Inexpensive
     * * `2` - Moderate
     * * `3` - Expensive
     * * `4` - Very Expensive
     * @type {PriceLevelEnum}
     * @memberof BusinessList
     */
    readonly price_level: PriceLevelEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessList
     */
    readonly cuisines: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BusinessList
     */
    readonly favorite_count: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessList
     */
    readonly deal_request_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessList
     */
    readonly is_open: boolean;
}

/**
 * Check if a given object implements the BusinessList interface.
 */
export function instanceOfBusinessList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "is_pro" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currency_symbol" in value;
    isInstance = isInstance && "physical_address_geo_point" in value;
    isInstance = isInstance && "google_place_rating" in value;
    isInstance = isInstance && "google_place_review_count" in value;
    isInstance = isInstance && "price_level" in value;
    isInstance = isInstance && "cuisines" in value;
    isInstance = isInstance && "favorite_count" in value;
    isInstance = isInstance && "deal_request_count" in value;
    isInstance = isInstance && "is_open" in value;

    return isInstance;
}

export function BusinessListFromJSON(json: any): BusinessList {
    return BusinessListFromJSONTyped(json, false);
}

export function BusinessListFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'is_pro': json['is_pro'],
        'name': json['name'],
        'currency_symbol': json['currency_symbol'],
        'physical_address_geo_point': BusinessPhysicalAddressGeoPointFromJSON(json['physical_address_geo_point']),
        'google_place_rating': json['google_place_rating'],
        'google_place_review_count': json['google_place_review_count'],
        'price_level': PriceLevelEnumFromJSON(json['price_level']),
        'cuisines': json['cuisines'],
        'favorite_count': json['favorite_count'],
        'deal_request_count': json['deal_request_count'],
        'is_open': json['is_open'],
    };
}

export function BusinessListToJSON(value?: BusinessList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physical_address_geo_point': BusinessPhysicalAddressGeoPointToJSON(value.physical_address_geo_point),
    };
}

