/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RedemptionsReportTotals
 */
export interface RedemptionsReportTotals {
    /**
     * 
     * @type {number}
     * @memberof RedemptionsReportTotals
     */
    unique: number;
    /**
     * 
     * @type {number}
     * @memberof RedemptionsReportTotals
     */
    repeat: number;
    /**
     * 
     * @type {number}
     * @memberof RedemptionsReportTotals
     */
    total: number;
    /**
     * 
     * @type {Date}
     * @memberof RedemptionsReportTotals
     */
    first_redemption: Date | null;
}

/**
 * Check if a given object implements the RedemptionsReportTotals interface.
 */
export function instanceOfRedemptionsReportTotals(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "unique" in value;
    isInstance = isInstance && "repeat" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "first_redemption" in value;

    return isInstance;
}

export function RedemptionsReportTotalsFromJSON(json: any): RedemptionsReportTotals {
    return RedemptionsReportTotalsFromJSONTyped(json, false);
}

export function RedemptionsReportTotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedemptionsReportTotals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unique': json['unique'],
        'repeat': json['repeat'],
        'total': json['total'],
        'first_redemption': (json['first_redemption'] === null ? null : new Date(json['first_redemption'])),
    };
}

export function RedemptionsReportTotalsToJSON(value?: RedemptionsReportTotals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unique': value.unique,
        'repeat': value.repeat,
        'total': value.total,
        'first_redemption': (value.first_redemption === null ? null : value.first_redemption.toISOString()),
    };
}

