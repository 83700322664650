/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface ManagedBusiness
 */
export interface ManagedBusiness {
    /**
     * 
     * @type {string}
     * @memberof ManagedBusiness
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedBusiness
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedBusiness
     */
    readonly cover_image: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof ManagedBusiness
     */
    readonly role: RoleEnum | null;
}

/**
 * Check if a given object implements the ManagedBusiness interface.
 */
export function instanceOfManagedBusiness(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "cover_image" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function ManagedBusinessFromJSON(json: any): ManagedBusiness {
    return ManagedBusinessFromJSONTyped(json, false);
}

export function ManagedBusinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagedBusiness {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'cover_image': json['cover_image'],
        'role': RoleEnumFromJSON(json['role']),
    };
}

export function ManagedBusinessToJSON(value?: ManagedBusiness | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

