/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserQualification } from './UserQualification';
import {
    UserQualificationFromJSON,
    UserQualificationFromJSONTyped,
    UserQualificationToJSON,
} from './UserQualification';

/**
 * 
 * @export
 * @interface ContestCurrentUserQualification
 */
export interface ContestCurrentUserQualification {
    /**
     * 
     * @type {boolean}
     * @memberof ContestCurrentUserQualification
     */
    readonly user_qualifies: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContestCurrentUserQualification
     */
    readonly entry_count: number;
}

/**
 * Check if a given object implements the ContestCurrentUserQualification interface.
 */
export function instanceOfContestCurrentUserQualification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user_qualifies" in value;
    isInstance = isInstance && "entry_count" in value;

    return isInstance;
}

export function ContestCurrentUserQualificationFromJSON(json: any): ContestCurrentUserQualification {
    return ContestCurrentUserQualificationFromJSONTyped(json, false);
}

export function ContestCurrentUserQualificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContestCurrentUserQualification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_qualifies': json['user_qualifies'],
        'entry_count': json['entry_count'],
    };
}

export function ContestCurrentUserQualificationToJSON(value?: ContestCurrentUserQualification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

