import { Dispatch, MouseEvent, SetStateAction } from "react"

export function debouncedClickHandler(
    handler: (event: MouseEvent<HTMLButtonElement>) => void,
    event: MouseEvent<HTMLButtonElement>,
    waiting: boolean,
    setWaiting: Dispatch<SetStateAction<boolean>>,
) {
    if (!waiting) {
        setWaiting(true)
        handler(event)
        setTimeout(() => setWaiting(false), 250)
    }
}
