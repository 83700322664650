/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BillingPeriod,
  ManagedBusiness,
  NotificationPreference,
  PatchedNotificationPreference,
  PatchedUser,
  PauseSubscription,
  ProStatus,
  TotalSavings,
  User,
} from '../models';
import {
    BillingPeriodFromJSON,
    BillingPeriodToJSON,
    ManagedBusinessFromJSON,
    ManagedBusinessToJSON,
    NotificationPreferenceFromJSON,
    NotificationPreferenceToJSON,
    PatchedNotificationPreferenceFromJSON,
    PatchedNotificationPreferenceToJSON,
    PatchedUserFromJSON,
    PatchedUserToJSON,
    PauseSubscriptionFromJSON,
    PauseSubscriptionToJSON,
    ProStatusFromJSON,
    ProStatusToJSON,
    TotalSavingsFromJSON,
    TotalSavingsToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface UserDestroyRequest {
    id: string;
}

export interface UserManagedBusinessesListRequest {
    id: string;
}

export interface UserNotificationPreferencesPartialUpdateRequest {
    id: string;
    PatchedNotificationPreference?: PatchedNotificationPreference;
}

export interface UserNotificationPreferencesRetrieveRequest {
    id: string;
}

export interface UserNotificationPreferencesUpdateRequest {
    id: string;
    NotificationPreference?: NotificationPreference;
}

export interface UserPartialUpdateRequest {
    id: string;
    PatchedUser?: PatchedUser;
}

export interface UserProStatusRetrieveRequest {
    id: string;
}

export interface UserRetrieveRequest {
    id: string;
}

export interface UserSavingsRetrieveRequest {
    id: string;
}

export interface UserSubscriptionsBillingPeriodEndRetrieveRequest {
    id: string;
}

export interface UserSubscriptionsPauseCreateRequest {
    id: string;
    PauseSubscription: PauseSubscription;
}

export interface UserSubscriptionsResumeCreateRequest {
    id: string;
}

export interface UserUpdateRequest {
    id: string;
    User: User;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userDestroyRaw(requestParameters: UserDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userDestroy(requestParameters: UserDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userManagedBusinessesListRaw(requestParameters: UserManagedBusinessesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManagedBusiness>>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userManagedBusinessesList(requestParameters: UserManagedBusinessesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManagedBusiness>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {PatchedNotificationPreference} [PatchedNotificationPreference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userNotificationPreferencesPartialUpdateRaw(requestParameters: UserNotificationPreferencesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationPreference>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userNotificationPreferencesPartialUpdate(requestParameters: UserNotificationPreferencesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationPreference>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userNotificationPreferencesRetrieveRaw(requestParameters: UserNotificationPreferencesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationPreference>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userNotificationPreferencesRetrieve(requestParameters: UserNotificationPreferencesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationPreference>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {NotificationPreference} [NotificationPreference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userNotificationPreferencesUpdateRaw(requestParameters: UserNotificationPreferencesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationPreference>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userNotificationPreferencesUpdate(requestParameters: UserNotificationPreferencesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationPreference>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {PatchedUser} [PatchedUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userPartialUpdateRaw(requestParameters: UserPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userPartialUpdate(requestParameters: UserPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userProStatusRetrieveRaw(requestParameters: UserProStatusRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProStatus>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userProStatusRetrieve(requestParameters: UserProStatusRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProStatus>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userRetrieveRaw(requestParameters: UserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userRetrieve(requestParameters: UserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userSavingsRetrieveRaw(requestParameters: UserSavingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TotalSavings>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userSavingsRetrieve(requestParameters: UserSavingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TotalSavings>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userSubscriptionsBillingPeriodEndRetrieveRaw(requestParameters: UserSubscriptionsBillingPeriodEndRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingPeriod>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userSubscriptionsBillingPeriodEndRetrieve(requestParameters: UserSubscriptionsBillingPeriodEndRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingPeriod>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {PauseSubscription} PauseSubscription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userSubscriptionsPauseCreateRaw(requestParameters: UserSubscriptionsPauseCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userSubscriptionsPauseCreate(requestParameters: UserSubscriptionsPauseCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userSubscriptionsResumeCreateRaw(requestParameters: UserSubscriptionsResumeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userSubscriptionsResumeCreate(requestParameters: UserSubscriptionsResumeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     * @param {string} id A unique integer value identifying this SLOeats User.
     * @param {User} User 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userUpdateRaw(requestParameters: UserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    userUpdate(requestParameters: UserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userDestroyRaw(requestParameters: UserDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userDestroy(requestParameters: UserDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userManagedBusinessesListRaw(requestParameters: UserManagedBusinessesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManagedBusiness>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userManagedBusinessesList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/managed-businesses/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManagedBusinessFromJSON));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userManagedBusinessesList(requestParameters: UserManagedBusinessesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManagedBusiness>> {
        const response = await this.userManagedBusinessesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userNotificationPreferencesPartialUpdateRaw(requestParameters: UserNotificationPreferencesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationPreference>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userNotificationPreferencesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/notification-preferences/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotificationPreferenceToJSON(requestParameters.PatchedNotificationPreference),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationPreferenceFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userNotificationPreferencesPartialUpdate(requestParameters: UserNotificationPreferencesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationPreference> {
        const response = await this.userNotificationPreferencesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userNotificationPreferencesRetrieveRaw(requestParameters: UserNotificationPreferencesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationPreference>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userNotificationPreferencesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/notification-preferences/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationPreferenceFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userNotificationPreferencesRetrieve(requestParameters: UserNotificationPreferencesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationPreference> {
        const response = await this.userNotificationPreferencesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userNotificationPreferencesUpdateRaw(requestParameters: UserNotificationPreferencesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationPreference>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userNotificationPreferencesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/notification-preferences/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationPreferenceToJSON(requestParameters.NotificationPreference),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationPreferenceFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userNotificationPreferencesUpdate(requestParameters: UserNotificationPreferencesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationPreference> {
        const response = await this.userNotificationPreferencesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userPartialUpdateRaw(requestParameters: UserPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedUserToJSON(requestParameters.PatchedUser),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userPartialUpdate(requestParameters: UserPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.userPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userProStatusRetrieveRaw(requestParameters: UserProStatusRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userProStatusRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/pro-status/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProStatusFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userProStatusRetrieve(requestParameters: UserProStatusRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProStatus> {
        const response = await this.userProStatusRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userRetrieveRaw(requestParameters: UserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userRetrieve(requestParameters: UserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.userRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userSavingsRetrieveRaw(requestParameters: UserSavingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TotalSavings>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userSavingsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/savings/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TotalSavingsFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userSavingsRetrieve(requestParameters: UserSavingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TotalSavings> {
        const response = await this.userSavingsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userSubscriptionsBillingPeriodEndRetrieveRaw(requestParameters: UserSubscriptionsBillingPeriodEndRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BillingPeriod>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userSubscriptionsBillingPeriodEndRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/subscriptions/billing-period-end/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingPeriodFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userSubscriptionsBillingPeriodEndRetrieve(requestParameters: UserSubscriptionsBillingPeriodEndRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BillingPeriod> {
        const response = await this.userSubscriptionsBillingPeriodEndRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userSubscriptionsPauseCreateRaw(requestParameters: UserSubscriptionsPauseCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userSubscriptionsPauseCreate.');
        }

        if (requestParameters.PauseSubscription === null || requestParameters.PauseSubscription === undefined) {
            throw new runtime.RequiredError('PauseSubscription','Required parameter requestParameters.PauseSubscription was null or undefined when calling userSubscriptionsPauseCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/subscriptions/pause/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PauseSubscriptionToJSON(requestParameters.PauseSubscription),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userSubscriptionsPauseCreate(requestParameters: UserSubscriptionsPauseCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSubscriptionsPauseCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userSubscriptionsResumeCreateRaw(requestParameters: UserSubscriptionsResumeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userSubscriptionsResumeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/subscriptions/resume/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userSubscriptionsResumeCreate(requestParameters: UserSubscriptionsResumeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSubscriptionsResumeCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userUpdateRaw(requestParameters: UserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userUpdate.');
        }

        if (requestParameters.User === null || requestParameters.User === undefined) {
            throw new runtime.RequiredError('User','Required parameter requestParameters.User was null or undefined when calling userUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/user/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.User),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Access and update the currently logged in user. Supply \'me\' as the id.
     */
    async userUpdate(requestParameters: UserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.userUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
