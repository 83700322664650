/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Giveaway,
} from '../models';
import {
    GiveawayFromJSON,
    GiveawayToJSON,
} from '../models';

export interface GiveawaysListRequest {
    displayable_modal?: boolean;
}

export interface GiveawaysRetrieveRequest {
    id: number;
}

/**
 * GiveawaysApi - interface
 * 
 * @export
 * @interface GiveawaysApiInterface
 */
export interface GiveawaysApiInterface {
    /**
     * 
     * @param {boolean} [displayable_modal] Displayable Modal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawaysApiInterface
     */
    giveawaysListRaw(requestParameters: GiveawaysListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Giveaway>>>;

    /**
     */
    giveawaysList(requestParameters: GiveawaysListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Giveaway>>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Giveaway.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawaysApiInterface
     */
    giveawaysRetrieveRaw(requestParameters: GiveawaysRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Giveaway>>;

    /**
     */
    giveawaysRetrieve(requestParameters: GiveawaysRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Giveaway>;

}

/**
 * 
 */
export class GiveawaysApi extends runtime.BaseAPI implements GiveawaysApiInterface {

    /**
     */
    async giveawaysListRaw(requestParameters: GiveawaysListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Giveaway>>> {
        const queryParameters: any = {};

        if (requestParameters.displayable_modal !== undefined) {
            queryParameters['displayable_modal'] = requestParameters.displayable_modal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/giveaways/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GiveawayFromJSON));
    }

    /**
     */
    async giveawaysList(requestParameters: GiveawaysListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Giveaway>> {
        const response = await this.giveawaysListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async giveawaysRetrieveRaw(requestParameters: GiveawaysRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Giveaway>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling giveawaysRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/giveaways/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GiveawayFromJSON(jsonValue));
    }

    /**
     */
    async giveawaysRetrieve(requestParameters: GiveawaysRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Giveaway> {
        const response = await this.giveawaysRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
