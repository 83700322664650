import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import { useCallback, useEffect, useRef, useState } from "react"

import { MAX_DAILY_DRINK_REDEMPTIONS } from "../constants/redemptions"
import { ExpirationStatuses } from "../types/User"

dayjs.extend(isSameOrBefore)

export default function useExpirationCheck(
    expirations: { [key: string]: string | Date },
    drinkExpirations: Array<string | Date>,
): [ExpirationStatuses, boolean, string | Date] {
    const [expirationStatuses, setExpirationStatuses] = useState<ExpirationStatuses>({})
    const [drinkExpirationStatuses, setDrinkExpirationStatuses] = useState<boolean[]>([])
    const [activeDrinkExpirations, setActiveDrinkExpirations] = useState<Array<string | Date>>([])
    const minutes = useRef<number>(new Date().getMinutes())

    useEffect(() => {
        if (Object.keys(expirations).length !== 0) {
            const statuses = {}
            Object.entries(expirations).forEach(
                ([businessID, expiration]) => (statuses[businessID] = dayjs(expiration).isSameOrBefore(new Date())),
            )
            setExpirationStatuses(statuses)
        }
    }, [expirations])

    useEffect(() => {
        if (drinkExpirations.length !== 0) {
            const drinkExpirationStatuses = drinkExpirations.map((expiration) =>
                dayjs(expiration).isSameOrBefore(new Date()),
            )
            const activeDrinkExpirations = drinkExpirations.filter((expiration) =>
                dayjs(expiration).isAfter(new Date()),
            )

            setDrinkExpirationStatuses(drinkExpirationStatuses)
            setActiveDrinkExpirations(activeDrinkExpirations)
        }
    }, [drinkExpirations])

    const checkExpirations = useCallback(() => {
        if (Object.keys(expirations).length !== 0 || drinkExpirations.length !== 0) {
            const now = new Date().getMinutes()
            // Only run the expensive check at the top of each minute
            if (now > minutes.current) {
                const newExpirationStatuses = {}
                let anyChanged = false

                Object.entries(expirations).forEach(([businessID, expiration]) => {
                    const isExpired = dayjs(expiration).isSameOrBefore(new Date())
                    anyChanged = anyChanged || expirationStatuses[businessID] != isExpired
                    newExpirationStatuses[businessID] = isExpired
                })

                if (anyChanged) {
                    setExpirationStatuses(newExpirationStatuses)
                }

                const newDrinkExpirationStatuses = []
                const newActiveDrinkExpirations = []
                let anyDrinkChanged = false

                drinkExpirations.forEach((expiration, index) => {
                    const isExpired = dayjs(expiration).isSameOrBefore(new Date())
                    anyDrinkChanged = anyDrinkChanged || drinkExpirationStatuses[index] != isExpired
                    newDrinkExpirationStatuses[index] = isExpired
                    if (!isExpired) {
                        newActiveDrinkExpirations.push(expiration)
                    }
                })

                if (anyDrinkChanged) {
                    setDrinkExpirationStatuses(newDrinkExpirationStatuses)
                    setActiveDrinkExpirations(newActiveDrinkExpirations)
                }
            }

            minutes.current = now
        }
    }, [expirations, drinkExpirations, expirationStatuses, drinkExpirationStatuses])

    useEffect(() => {
        const intervalId = setInterval(checkExpirations, 1000)
        return () => clearInterval(intervalId)
    }, [checkExpirations])

    return [
        expirationStatuses,
        drinkExpirationStatuses.length === MAX_DAILY_DRINK_REDEMPTIONS,
        activeDrinkExpirations.slice(-1).pop(),
    ]
}
