/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MenuItem
 */
export interface MenuItem {
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    readonly business_id: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    name: string;
    /**
     * This item's index within its category.
     * @type {number}
     * @memberof MenuItem
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    image?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    readonly favorite_count: number;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    readonly average_rating: string;
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    readonly user_rating: number;
    /**
     * 
     * @type {boolean}
     * @memberof MenuItem
     */
    readonly used_as_deal_reward: boolean;
}

/**
 * Check if a given object implements the MenuItem interface.
 */
export function instanceOfMenuItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "business_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "favorite_count" in value;
    isInstance = isInstance && "average_rating" in value;
    isInstance = isInstance && "user_rating" in value;
    isInstance = isInstance && "used_as_deal_reward" in value;

    return isInstance;
}

export function MenuItemFromJSON(json: any): MenuItem {
    return MenuItemFromJSONTyped(json, false);
}

export function MenuItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'business_id': json['business_id'],
        'name': json['name'],
        'index': json['index'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': json['price'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'favorite_count': json['favorite_count'],
        'average_rating': json['average_rating'],
        'user_rating': json['user_rating'],
        'used_as_deal_reward': json['used_as_deal_reward'],
    };
}

export function MenuItemToJSON(value?: MenuItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'index': value.index,
        'description': value.description,
        'price': value.price,
        'image': value.image,
    };
}

