import { useAtom } from "jotai"
import { useRouter } from "next/router"
import { useCallback, useEffect } from "react"

import useUser from "../../hooks/User/useUser"
import useJune from "../../hooks/useJune"
import { appOpenedAtom } from "../../pages/auth/index.store"
import { setUserLastAppOpen } from "../../requests/auth"

export default function AnalyticsInitializer() {
    const { user, clearAuth } = useUser()
    const analytics = useJune()
    const router = useRouter()

    const [appOpened, setAppOpened] = useAtom(appOpenedAtom)

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            analytics?.page(url)
        }

        router.events.on("routeChangeStart", handleRouteChange)

        return () => {
            router.events.off("routeChangeStart", handleRouteChange)
        }
    }, [analytics, router.events])

    useEffect(() => {
        if (analytics && user) {
            analytics.identify(user.id, {
                name: user.full_name,
                phone: user.phone,
                email: user.email,
                avatar_url: user.avatar_url,
                first_name: user.first_name,
                last_name: user.last_name,
                birthday: user.birthday,
                sex: user.sex,
                registered: user.registered,
                utm_medium: user.utm_medium,
                accepted_referral_code: user.accepted_referral_code,
                is_pro: user.is_pro,
            })
        }
    }, [user, analytics])

    const handleAppOpen = useCallback(async () => {
        if (user?.id) {
            analytics?.track("App Open")

            await setUserLastAppOpen(router, clearAuth, user?.token)
            setAppOpened(true)
        }
    }, [analytics, user?.id, user?.token, router, clearAuth, setAppOpened])

    useEffect(() => {
        if (!appOpened) {
            handleAppOpen()
        }
    }, [handleAppOpen, appOpened])

    return null
}
