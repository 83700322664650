/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BusinessPhysicalAddressGeoPoint } from './BusinessPhysicalAddressGeoPoint';
import {
    BusinessPhysicalAddressGeoPointFromJSON,
    BusinessPhysicalAddressGeoPointFromJSONTyped,
    BusinessPhysicalAddressGeoPointToJSON,
} from './BusinessPhysicalAddressGeoPoint';

/**
 * 
 * @export
 * @interface RedemptionBusiness
 */
export interface RedemptionBusiness {
    /**
     * 
     * @type {string}
     * @memberof RedemptionBusiness
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof RedemptionBusiness
     */
    readonly name: string;
    /**
     * 
     * @type {boolean}
     * @memberof RedemptionBusiness
     */
    readonly is_open: boolean;
    /**
     * 
     * @type {string}
     * @memberof RedemptionBusiness
     */
    readonly currency_symbol: string;
    /**
     * 3-letter ISO currency code. See https://stripe.com/docs/currencies.
     * @type {string}
     * @memberof RedemptionBusiness
     */
    readonly currency_code: string;
    /**
     * IETF BCP 47 language tag.
     * @type {string}
     * @memberof RedemptionBusiness
     */
    readonly language_code: string;
    /**
     * 
     * @type {BusinessPhysicalAddressGeoPoint}
     * @memberof RedemptionBusiness
     */
    physical_address_geo_point: BusinessPhysicalAddressGeoPoint | null;
}

/**
 * Check if a given object implements the RedemptionBusiness interface.
 */
export function instanceOfRedemptionBusiness(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "is_open" in value;
    isInstance = isInstance && "currency_symbol" in value;
    isInstance = isInstance && "currency_code" in value;
    isInstance = isInstance && "language_code" in value;
    isInstance = isInstance && "physical_address_geo_point" in value;

    return isInstance;
}

export function RedemptionBusinessFromJSON(json: any): RedemptionBusiness {
    return RedemptionBusinessFromJSONTyped(json, false);
}

export function RedemptionBusinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedemptionBusiness {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'is_open': json['is_open'],
        'currency_symbol': json['currency_symbol'],
        'currency_code': json['currency_code'],
        'language_code': json['language_code'],
        'physical_address_geo_point': BusinessPhysicalAddressGeoPointFromJSON(json['physical_address_geo_point']),
    };
}

export function RedemptionBusinessToJSON(value?: RedemptionBusiness | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physical_address_geo_point': BusinessPhysicalAddressGeoPointToJSON(value.physical_address_geo_point),
    };
}

