/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `cal_poly_booth` - Cal Poly Booth
 * * `cuesta_booth` - Cuesta Booth
 * * `campus_bulletin_board` - Campus Bulletin Board
 * * `campus_flyer` - Campus Flyer
 * * `car_flyer` - Car Flyer
 * * `door_hanger` - Door Hanger
 * * `farmers_booth` - Farmer's Market Booth
 * * `facebook_ad` - Facebook Ad
 * * `google_search` - Google Search
 * * `instagram_ad` - Instagram Ad
 * * `local_influencer` - Local Influencer
 * * `local_news` - KSBY or Local News
 * * `referral` - Referral (Friend)
 * * `restaurant_qr_code` - Restaurant QR Code
 * * `tshirt` - T-Shirt
 * * `quickie` - Quickie
 * * `organization` - Organization
 * * `other` - Other
 * @export
 */
export const UtmMediumEnum = {
    cal_poly_booth: 'cal_poly_booth',
    cuesta_booth: 'cuesta_booth',
    campus_bulletin_board: 'campus_bulletin_board',
    campus_flyer: 'campus_flyer',
    car_flyer: 'car_flyer',
    door_hanger: 'door_hanger',
    farmers_booth: 'farmers_booth',
    facebook_ad: 'facebook_ad',
    google_search: 'google_search',
    instagram_ad: 'instagram_ad',
    local_influencer: 'local_influencer',
    local_news: 'local_news',
    referral: 'referral',
    restaurant_qr_code: 'restaurant_qr_code',
    tshirt: 'tshirt',
    quickie: 'quickie',
    organization: 'organization',
    other: 'other'
} as const;
export type UtmMediumEnum = typeof UtmMediumEnum[keyof typeof UtmMediumEnum];


export function UtmMediumEnumFromJSON(json: any): UtmMediumEnum {
    return UtmMediumEnumFromJSONTyped(json, false);
}

export function UtmMediumEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UtmMediumEnum {
    return json as UtmMediumEnum;
}

export function UtmMediumEnumToJSON(value?: UtmMediumEnum | null): any {
    return value as any;
}

