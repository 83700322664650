import { NextRouter } from "next/router"

import {
    Configuration,
    NotificationInstanceCount,
    NotificationPreference,
    NotificationsApi,
    NotificationsListRequest,
    NotificationsMarkInteractedCreateRequest,
    NotificationsMarkSeenCreateRequest,
    PaginatedNotificationInstanceList,
    ResponseError,
    UserApi,
    UserNotificationPreferencesPartialUpdateRequest,
    UserNotificationPreferencesRetrieveRequest,
} from "../../sdk"
import { UNAUTHENTICATED_USER_MESSAGE, handleUnauthenticatedUser } from "../components/utils/AuthCheck"
import { BACKEND_BASE_URL } from "../constants/endpoints"

export async function listNotifications(
    requestParams: NotificationsListRequest,
    token: string,
    nextPage: string = null,
): Promise<PaginatedNotificationInstanceList> {
    const apiConfiguration = new Configuration({ basePath: BACKEND_BASE_URL, apiKey: () => `Token ${token}` })
    const api = new NotificationsApi(apiConfiguration)

    if (nextPage) {
        const next = new URL(nextPage)
        requestParams.limit = parseInt(next.searchParams.get("limit"), 10)
        requestParams.offset = parseInt(next.searchParams.get("offset"), 10)
    }

    try {
        const result = await api.notificationsList(requestParams)
        return result
    } catch (error) {
        if ((error as ResponseError).response?.status === 403) {
            throw new Error(UNAUTHENTICATED_USER_MESSAGE)
        } else {
            throw new Error(`There was an error while attempting to load notifications.`)
        }
    }
}

export async function retrieveNotificationUnreadCount(token: string): Promise<NotificationInstanceCount> {
    const apiConfiguration = new Configuration({ basePath: BACKEND_BASE_URL, apiKey: () => `Token ${token}` })
    const api = new NotificationsApi(apiConfiguration)

    try {
        const result = await api.notificationsUnreadCountRetrieve()
        return result
    } catch (error) {
        if ((error as ResponseError).response?.status === 403) {
            throw new Error(UNAUTHENTICATED_USER_MESSAGE)
        } else {
            throw new Error(`There was an error while attempting to retrieve unread notication count.`)
        }
    }
}

export async function markNotificationAsSeen(
    requestParams: NotificationsMarkSeenCreateRequest,
    token: string,
    router: NextRouter,
    clearAuth: () => void,
) {
    const apiConfiguration = new Configuration({ basePath: BACKEND_BASE_URL, apiKey: () => `Token ${token}` })
    const api = new NotificationsApi(apiConfiguration)

    try {
        await api.notificationsMarkSeenCreate(requestParams)
    } catch (error) {
        if ((error as ResponseError).response?.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new Error(`There was an error while attempting to mark the notification as seen.`)
        }
    }
}

export async function markNotificationAsInteracted(
    requestParams: NotificationsMarkInteractedCreateRequest,
    token: string,
    router: NextRouter,
    clearAuth: () => void,
) {
    const apiConfiguration = new Configuration({ basePath: BACKEND_BASE_URL, apiKey: () => `Token ${token}` })
    const api = new NotificationsApi(apiConfiguration)

    try {
        await api.notificationsMarkInteractedCreate(requestParams)
    } catch (error) {
        if ((error as ResponseError).response?.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new Error(`There was an error while attempting to mark the notification as interacted.`)
        }
    }
}

export async function markAllNotificationsAsInteracted(token: string, router: NextRouter, clearAuth: () => void) {
    const apiConfiguration = new Configuration({ basePath: BACKEND_BASE_URL, apiKey: () => `Token ${token}` })
    const api = new NotificationsApi(apiConfiguration)

    try {
        await api.notificationsMarkAllInteractedCreate()
    } catch (error) {
        if ((error as ResponseError).response?.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new Error(`There was an error while attempting to mark all notifications as interacted.`)
        }
    }
}

export async function retrieveUserNotificationPreferences(
    requestParams: UserNotificationPreferencesRetrieveRequest,
    token: string,
): Promise<NotificationPreference> {
    const apiConfiguration = new Configuration({ basePath: BACKEND_BASE_URL, apiKey: () => `Token ${token}` })
    const api = new UserApi(apiConfiguration)

    try {
        const result = await api.userNotificationPreferencesRetrieve(requestParams)
        return result
    } catch (error) {
        if ((error as ResponseError).response?.status === 403) {
            throw new Error(UNAUTHENTICATED_USER_MESSAGE)
        } else {
            throw new Error(`There was an error while attempting to retrieve notication preferences.`)
        }
    }
}

export async function updateUserNotificationPreferences(
    requestParams: UserNotificationPreferencesPartialUpdateRequest,
    token: string,
    router: NextRouter,
    clearAuth: () => void,
) {
    const apiConfiguration = new Configuration({ basePath: BACKEND_BASE_URL, apiKey: () => `Token ${token}` })
    const api = new UserApi(apiConfiguration)

    try {
        const result = await api.userNotificationPreferencesPartialUpdate(requestParams)
        return result
    } catch (error) {
        if ((error as ResponseError).response?.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new Error(`There was an error while attempting to retrieve notication preferences.`)
        }
    }
}
