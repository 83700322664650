import { Capacitor } from "@capacitor/core"
import { PushNotifications } from "@capacitor/push-notifications"
import { useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useCallback, useEffect } from "react"

import { NotificationInstance } from "../../../sdk"
import { PAGE_ROUTES } from "../../constants/routes"
import useUser from "../../hooks/User/useUser"
import useJune from "../../hooks/useJune"
import { setUserDeviceToken } from "../../requests/auth"
import { markNotificationAsInteracted } from "../../requests/notifications"

export default function PushNotificationListener() {
    const { user, clearAuth } = useUser()
    const router = useRouter()
    const analytics = useJune()
    const queryClient = useQueryClient()

    const addListeners = useCallback(async () => {
        await PushNotifications.addListener("registration", (token) => {
            if (user && user?.device_token !== token.value) {
                console.info("Push Notification Registration token: ", token.value)
                setUserDeviceToken(token.value, Capacitor.getPlatform(), router, clearAuth, user.token)
                user.device_token = token.value
            }
        })

        await PushNotifications.addListener("registrationError", (err) => {
            console.error("Push Notification Registration error: ", err.error)
        })

        await PushNotifications.addListener("pushNotificationReceived", (notification) => {
            console.log("Push notification received: ", JSON.stringify(notification))
        })

        await PushNotifications.addListener("pushNotificationActionPerformed", (actionPerformed) => {
            console.log("Push notification action performed: ", JSON.stringify(actionPerformed))
            const notificationData = actionPerformed.notification.data as { payload: string }
            const notificationPayload = JSON.parse(notificationData.payload) as NotificationInstance
            console.log("Push notification payload: ", notificationPayload)

            const onTap = async (notification: NotificationInstance) => {
                // Mark as interacted + read once we click the item
                await markNotificationAsInteracted({ id: notification.id }, user?.token, router, clearAuth)
                await queryClient.invalidateQueries({
                    queryKey: ["notifications_list", user?.token],
                })
                await queryClient.invalidateQueries({
                    queryKey: ["notifications_unread_count", user?.token],
                })

                analytics.track("Push Notification Tap", {
                    type: notification.notification.notification_type,
                    payload: notification.notification,
                })

                router.push(notification.notification.payload_destination || PAGE_ROUTES.ACCOUNT_NOTIFICATIONS)
            }

            onTap(notificationPayload)
        })
    }, [router, analytics, user, clearAuth, queryClient])

    const registerNotifications = useCallback(async () => {
        await addListeners()

        let permissionStatus = await PushNotifications.checkPermissions()

        if (permissionStatus.receive === "prompt") {
            permissionStatus = await PushNotifications.requestPermissions()
        }

        if (permissionStatus.receive !== "granted") {
            console.error("User denied push notification permissions.")
        }

        await PushNotifications.register()

        if (Capacitor.getPlatform() === "android") {
            await PushNotifications.createChannel({
                id: "new-deal",
                name: "New Deals",
                description: 'e.g. "The Burrito Shop added a new deal..."',
                importance: 4,
            })
            await PushNotifications.createChannel({
                id: "deal-promotion",
                name: "Deal Spotlights",
                description: 'e.g. "Check out this deal at The Burrito Shop..."',
                importance: 4,
            })
            await PushNotifications.createChannel({
                id: "new-referral",
                name: "New Referrals",
                description: 'e.g. "Patty Schmelt signed up for SLOeats..."',
                importance: 4,
            })
            await PushNotifications.createChannel({
                id: "new-giveaway",
                name: "New Giveaway",
                description: 'e.g. "Get a free burrito at The Burrito shop on..."',
                importance: 4,
            })
            await PushNotifications.createChannel({
                id: "new-feature",
                name: "New Features",
                description: 'e.g. "You can now..."',
                importance: 4,
            })
            await PushNotifications.createChannel({
                id: "new-message",
                name: "New Messages",
                description: 'e.g. "Take this survey to get a free month..."',
                importance: 4,
            })
            await PushNotifications.createChannel({
                id: "contest-announcement",
                name: "Contest Announcements",
                description: 'e.g. "Did you win the latest contest?..."',
                importance: 4,
            })
        }
    }, [addListeners])

    useEffect(() => {
        if (Capacitor.isNativePlatform() && user) {
            registerNotifications()

            return () => {
                PushNotifications.removeAllListeners()
            }
        }
    }, [registerNotifications, user])

    return null
}
