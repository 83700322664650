/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContestCurrentUserQualification } from './ContestCurrentUserQualification';
import {
    ContestCurrentUserQualificationFromJSON,
    ContestCurrentUserQualificationFromJSONTyped,
    ContestCurrentUserQualificationToJSON,
} from './ContestCurrentUserQualification';
import type { EntryTypeEnum } from './EntryTypeEnum';
import {
    EntryTypeEnumFromJSON,
    EntryTypeEnumFromJSONTyped,
    EntryTypeEnumToJSON,
} from './EntryTypeEnum';
import type { PrizeTypeEnum } from './PrizeTypeEnum';
import {
    PrizeTypeEnumFromJSON,
    PrizeTypeEnumFromJSONTyped,
    PrizeTypeEnumToJSON,
} from './PrizeTypeEnum';

/**
 * 
 * @export
 * @interface Contest
 */
export interface Contest {
    /**
     * 
     * @type {number}
     * @memberof Contest
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Contest
     */
    readonly name: string;
    /**
     * When this contest starts.
     * @type {Date}
     * @memberof Contest
     */
    readonly start_at: Date;
    /**
     * When this contest ends.
     * @type {Date}
     * @memberof Contest
     */
    readonly end_at: Date;
    /**
     * The type of entry required for this contest.
     * 
     * * `1` - Redemption
     * @type {EntryTypeEnum}
     * @memberof Contest
     */
    readonly entry_type: EntryTypeEnum | null;
    /**
     * The amount of entries required to qualify for this contest.
     * @type {number}
     * @memberof Contest
     */
    readonly entry_threshold: number;
    /**
     * The type of each of this contest's prizes.
     * 
     * * `1` - Restaurant Gift Card
     * @type {PrizeTypeEnum}
     * @memberof Contest
     */
    readonly prize_type: PrizeTypeEnum | null;
    /**
     * The value of each of this contest's prizes. If values are different (they shouldn't be), use the smallest value.
     * @type {string}
     * @memberof Contest
     */
    readonly prize_value: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Contest
     */
    readonly prize_options: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Contest
     */
    readonly winner_id: string;
    /**
     * 
     * @type {string}
     * @memberof Contest
     */
    readonly winner_name: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Contest
     */
    readonly is_claimed: boolean;
    /**
     * 
     * @type {ContestCurrentUserQualification}
     * @memberof Contest
     */
    current_user_qualification: ContestCurrentUserQualification;
    /**
     * 
     * @type {string}
     * @memberof Contest
     */
    readonly status: string;
}

/**
 * Check if a given object implements the Contest interface.
 */
export function instanceOfContest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "start_at" in value;
    isInstance = isInstance && "end_at" in value;
    isInstance = isInstance && "entry_type" in value;
    isInstance = isInstance && "entry_threshold" in value;
    isInstance = isInstance && "prize_type" in value;
    isInstance = isInstance && "prize_value" in value;
    isInstance = isInstance && "prize_options" in value;
    isInstance = isInstance && "winner_id" in value;
    isInstance = isInstance && "winner_name" in value;
    isInstance = isInstance && "is_claimed" in value;
    isInstance = isInstance && "current_user_qualification" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function ContestFromJSON(json: any): Contest {
    return ContestFromJSONTyped(json, false);
}

export function ContestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'start_at': (new Date(json['start_at'])),
        'end_at': (new Date(json['end_at'])),
        'entry_type': EntryTypeEnumFromJSON(json['entry_type']),
        'entry_threshold': json['entry_threshold'],
        'prize_type': PrizeTypeEnumFromJSON(json['prize_type']),
        'prize_value': json['prize_value'],
        'prize_options': json['prize_options'],
        'winner_id': json['winner_id'],
        'winner_name': json['winner_name'],
        'is_claimed': json['is_claimed'],
        'current_user_qualification': ContestCurrentUserQualificationFromJSON(json['current_user_qualification']),
        'status': json['status'],
    };
}

export function ContestToJSON(value?: Contest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_user_qualification': ContestCurrentUserQualificationToJSON(value.current_user_qualification),
    };
}

