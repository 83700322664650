/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Referral,
} from '../models';
import {
    ReferralFromJSON,
    ReferralToJSON,
} from '../models';

/**
 * ReferralsApi - interface
 * 
 * @export
 * @interface ReferralsApiInterface
 */
export interface ReferralsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    referralsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Referral>>>;

    /**
     */
    referralsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Referral>>;

}

/**
 * 
 */
export class ReferralsApi extends runtime.BaseAPI implements ReferralsApiInterface {

    /**
     */
    async referralsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Referral>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/referrals/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferralFromJSON));
    }

    /**
     */
    async referralsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Referral>> {
        const response = await this.referralsListRaw(initOverrides);
        return await response.value();
    }

}
