/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BusinessPhysicalAddressGeoPoint
 */
export interface BusinessPhysicalAddressGeoPoint {
    /**
     * 
     * @type {string}
     * @memberof BusinessPhysicalAddressGeoPoint
     */
    type?: BusinessPhysicalAddressGeoPointTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof BusinessPhysicalAddressGeoPoint
     */
    coordinates?: Array<number>;
}


/**
 * @export
 */
export const BusinessPhysicalAddressGeoPointTypeEnum = {
    Point: 'Point'
} as const;
export type BusinessPhysicalAddressGeoPointTypeEnum = typeof BusinessPhysicalAddressGeoPointTypeEnum[keyof typeof BusinessPhysicalAddressGeoPointTypeEnum];


/**
 * Check if a given object implements the BusinessPhysicalAddressGeoPoint interface.
 */
export function instanceOfBusinessPhysicalAddressGeoPoint(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BusinessPhysicalAddressGeoPointFromJSON(json: any): BusinessPhysicalAddressGeoPoint {
    return BusinessPhysicalAddressGeoPointFromJSONTyped(json, false);
}

export function BusinessPhysicalAddressGeoPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessPhysicalAddressGeoPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
    };
}

export function BusinessPhysicalAddressGeoPointToJSON(value?: BusinessPhysicalAddressGeoPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'coordinates': value.coordinates,
    };
}

