/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Testimonial,
} from '../models';
import {
    TestimonialFromJSON,
    TestimonialToJSON,
} from '../models';

/**
 * TestimonialsApi - interface
 * 
 * @export
 * @interface TestimonialsApiInterface
 */
export interface TestimonialsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestimonialsApiInterface
     */
    testimonialsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Testimonial>>>;

    /**
     */
    testimonialsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Testimonial>>;

}

/**
 * 
 */
export class TestimonialsApi extends runtime.BaseAPI implements TestimonialsApiInterface {

    /**
     */
    async testimonialsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Testimonial>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/testimonials/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TestimonialFromJSON));
    }

    /**
     */
    async testimonialsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Testimonial>> {
        const response = await this.testimonialsListRaw(initOverrides);
        return await response.value();
    }

}
