/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TestimonialUser } from './TestimonialUser';
import {
    TestimonialUserFromJSON,
    TestimonialUserFromJSONTyped,
    TestimonialUserToJSON,
} from './TestimonialUser';

/**
 * 
 * @export
 * @interface Testimonial
 */
export interface Testimonial {
    /**
     * 
     * @type {number}
     * @memberof Testimonial
     */
    readonly id: number;
    /**
     * 
     * @type {TestimonialUser}
     * @memberof Testimonial
     */
    user: TestimonialUser;
    /**
     * 
     * @type {string}
     * @memberof Testimonial
     */
    readonly text: string;
}

/**
 * Check if a given object implements the Testimonial interface.
 */
export function instanceOfTestimonial(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function TestimonialFromJSON(json: any): Testimonial {
    return TestimonialFromJSONTyped(json, false);
}

export function TestimonialFromJSONTyped(json: any, ignoreDiscriminator: boolean): Testimonial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': TestimonialUserFromJSON(json['user']),
        'text': json['text'],
    };
}

export function TestimonialToJSON(value?: Testimonial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': TestimonialUserToJSON(value.user),
    };
}

