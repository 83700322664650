/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotificationInstanceCount,
  PaginatedNotificationInstanceList,
} from '../models';
import {
    NotificationInstanceCountFromJSON,
    NotificationInstanceCountToJSON,
    PaginatedNotificationInstanceListFromJSON,
    PaginatedNotificationInstanceListToJSON,
} from '../models';

export interface NotificationsListRequest {
    limit?: number;
    notification__notification_type?: NotificationsListNotificationNotificationTypeEnum;
    offset?: number;
    status?: Array<NotificationsListStatusEnum>;
}

export interface NotificationsMarkInteractedCreateRequest {
    id: number;
}

export interface NotificationsMarkSeenCreateRequest {
    id: number;
}

/**
 * NotificationsApi - interface
 * 
 * @export
 * @interface NotificationsApiInterface
 */
export interface NotificationsApiInterface {
    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {'contest-announcement' | 'deal-promotion' | 'new-deal' | 'new-feature' | 'new-giveaway' | 'new-message' | 'new-referral' | 'welcome-message'} [notification__notification_type] * &#x60;new-deal&#x60; - New Deal * &#x60;deal-promotion&#x60; - Deal Promotion * &#x60;new-referral&#x60; - New Referral * &#x60;new-giveaway&#x60; - New Giveaway * &#x60;new-feature&#x60; - New Feature * &#x60;new-message&#x60; - New Message * &#x60;contest-announcement&#x60; - Contest Announcement * &#x60;welcome-message&#x60; - Welcome Message
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<1 | 2 | 3>} [status] * &#x60;1&#x60; - Sent * &#x60;2&#x60; - Seen * &#x60;3&#x60; - Interacted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationsListRaw(requestParameters: NotificationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotificationInstanceList>>;

    /**
     */
    notificationsList(requestParameters: NotificationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotificationInstanceList>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationsMarkAllInteractedCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    notificationsMarkAllInteractedCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Notification Instance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationsMarkInteractedCreateRaw(requestParameters: NotificationsMarkInteractedCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    notificationsMarkInteractedCreate(requestParameters: NotificationsMarkInteractedCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Notification Instance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationsMarkSeenCreateRaw(requestParameters: NotificationsMarkSeenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    notificationsMarkSeenCreate(requestParameters: NotificationsMarkSeenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationsUnreadCountRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationInstanceCount>>;

    /**
     */
    notificationsUnreadCountRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationInstanceCount>;

}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI implements NotificationsApiInterface {

    /**
     */
    async notificationsListRaw(requestParameters: NotificationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotificationInstanceList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.notification__notification_type !== undefined) {
            queryParameters['notification__notification_type'] = requestParameters.notification__notification_type;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/notifications/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotificationInstanceListFromJSON(jsonValue));
    }

    /**
     */
    async notificationsList(requestParameters: NotificationsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotificationInstanceList> {
        const response = await this.notificationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notificationsMarkAllInteractedCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/notifications/mark-all-interacted/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationsMarkAllInteractedCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsMarkAllInteractedCreateRaw(initOverrides);
    }

    /**
     */
    async notificationsMarkInteractedCreateRaw(requestParameters: NotificationsMarkInteractedCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationsMarkInteractedCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/notifications/{id}/mark-interacted/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationsMarkInteractedCreate(requestParameters: NotificationsMarkInteractedCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsMarkInteractedCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notificationsMarkSeenCreateRaw(requestParameters: NotificationsMarkSeenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationsMarkSeenCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/notifications/{id}/mark-seen/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationsMarkSeenCreate(requestParameters: NotificationsMarkSeenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsMarkSeenCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notificationsUnreadCountRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationInstanceCount>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/notifications/unread-count/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationInstanceCountFromJSON(jsonValue));
    }

    /**
     */
    async notificationsUnreadCountRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationInstanceCount> {
        const response = await this.notificationsUnreadCountRetrieveRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const NotificationsListNotificationNotificationTypeEnum = {
    contest_announcement: 'contest-announcement',
    deal_promotion: 'deal-promotion',
    new_deal: 'new-deal',
    new_feature: 'new-feature',
    new_giveaway: 'new-giveaway',
    new_message: 'new-message',
    new_referral: 'new-referral',
    welcome_message: 'welcome-message'
} as const;
export type NotificationsListNotificationNotificationTypeEnum = typeof NotificationsListNotificationNotificationTypeEnum[keyof typeof NotificationsListNotificationNotificationTypeEnum];
/**
 * @export
 */
export const NotificationsListStatusEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type NotificationsListStatusEnum = typeof NotificationsListStatusEnum[keyof typeof NotificationsListStatusEnum];
