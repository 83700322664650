/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReelBusiness } from './ReelBusiness';
import {
    ReelBusinessFromJSON,
    ReelBusinessFromJSONTyped,
    ReelBusinessToJSON,
} from './ReelBusiness';

/**
 * 
 * @export
 * @interface DealRewardReelBusiness
 */
export interface DealRewardReelBusiness {
    /**
     * 
     * @type {string}
     * @memberof DealRewardReelBusiness
     */
    readonly id: string;
    /**
     * 
     * @type {boolean}
     * @memberof DealRewardReelBusiness
     */
    readonly is_pro: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealRewardReelBusiness
     */
    readonly name: string;
    /**
     * 
     * @type {number}
     * @memberof DealRewardReelBusiness
     */
    readonly favorite_count: number;
}

/**
 * Check if a given object implements the DealRewardReelBusiness interface.
 */
export function instanceOfDealRewardReelBusiness(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "is_pro" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "favorite_count" in value;

    return isInstance;
}

export function DealRewardReelBusinessFromJSON(json: any): DealRewardReelBusiness {
    return DealRewardReelBusinessFromJSONTyped(json, false);
}

export function DealRewardReelBusinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealRewardReelBusiness {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'is_pro': json['is_pro'],
        'name': json['name'],
        'favorite_count': json['favorite_count'],
    };
}

export function DealRewardReelBusinessToJSON(value?: DealRewardReelBusiness | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

