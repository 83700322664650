/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesReportList
 */
export interface SalesReportList {
    /**
     * 
     * @type {number}
     * @memberof SalesReportList
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof SalesReportList
     */
    readonly user_id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesReportList
     */
    readonly business_name: string;
    /**
     * 
     * @type {string}
     * @memberof SalesReportList
     */
    readonly reward_name: string;
    /**
     * 
     * @type {string}
     * @memberof SalesReportList
     */
    readonly estimated_sales: string;
    /**
     * 
     * @type {string}
     * @memberof SalesReportList
     */
    readonly cost: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesReportList
     */
    readonly commission: string;
    /**
     * 
     * @type {string}
     * @memberof SalesReportList
     */
    readonly estimated_net: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesReportList
     */
    readonly created_at: Date;
}

/**
 * Check if a given object implements the SalesReportList interface.
 */
export function instanceOfSalesReportList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "business_name" in value;
    isInstance = isInstance && "reward_name" in value;
    isInstance = isInstance && "estimated_sales" in value;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "commission" in value;
    isInstance = isInstance && "estimated_net" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function SalesReportListFromJSON(json: any): SalesReportList {
    return SalesReportListFromJSONTyped(json, false);
}

export function SalesReportListFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesReportList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user_id': json['user_id'],
        'business_name': json['business_name'],
        'reward_name': json['reward_name'],
        'estimated_sales': json['estimated_sales'],
        'cost': json['cost'],
        'commission': json['commission'],
        'estimated_net': json['estimated_net'],
        'created_at': (new Date(json['created_at'])),
    };
}

export function SalesReportListToJSON(value?: SalesReportList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

