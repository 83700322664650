/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgeReportTimeseries
 */
export interface AgeReportTimeseries {
    /**
     * 
     * @type {Date}
     * @memberof AgeReportTimeseries
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    _lt_18: number;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    _18_20: number;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    _21_24: number;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    _25_29: number;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    _30_39: number;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    _40_49: number;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    _50_59: number;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    _gte_60: number;
    /**
     * 
     * @type {number}
     * @memberof AgeReportTimeseries
     */
    average_age: number;
}

/**
 * Check if a given object implements the AgeReportTimeseries interface.
 */
export function instanceOfAgeReportTimeseries(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "_lt_18" in value;
    isInstance = isInstance && "_18_20" in value;
    isInstance = isInstance && "_21_24" in value;
    isInstance = isInstance && "_25_29" in value;
    isInstance = isInstance && "_30_39" in value;
    isInstance = isInstance && "_40_49" in value;
    isInstance = isInstance && "_50_59" in value;
    isInstance = isInstance && "_gte_60" in value;
    isInstance = isInstance && "average_age" in value;

    return isInstance;
}

export function AgeReportTimeseriesFromJSON(json: any): AgeReportTimeseries {
    return AgeReportTimeseriesFromJSONTyped(json, false);
}

export function AgeReportTimeseriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeReportTimeseries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        '_lt_18': json['_lt_18'],
        '_18_20': json['_18_20'],
        '_21_24': json['_21_24'],
        '_25_29': json['_25_29'],
        '_30_39': json['_30_39'],
        '_40_49': json['_40_49'],
        '_50_59': json['_50_59'],
        '_gte_60': json['_gte_60'],
        'average_age': json['average_age'],
    };
}

export function AgeReportTimeseriesToJSON(value?: AgeReportTimeseries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        '_lt_18': value._lt_18,
        '_18_20': value._18_20,
        '_21_24': value._21_24,
        '_25_29': value._25_29,
        '_30_39': value._30_39,
        '_40_49': value._40_49,
        '_50_59': value._50_59,
        '_gte_60': value._gte_60,
        'average_age': value.average_age,
    };
}

