import { Provider } from "jotai"
import { ReactNode, useEffect, useState } from "react"

import useUser from "../../hooks/User/useUser"

function TokenRetriever(props: { currentToken: string; setToken: (token: string) => void }) {
    const { currentToken, setToken } = props

    const { user, userLoading } = useUser()

    useEffect(() => {
        if (!userLoading && user && currentToken !== user?.token) {
            setToken(user?.token)
        }
    }, [currentToken, user, userLoading, setToken])

    return null
}

export default function UserStoreProvider(props: { children: ReactNode }) {
    const { children } = props

    const [token, setToken] = useState<string | null>(null)

    return (
        <Provider key={token}>
            {children}
            <TokenRetriever currentToken={token} setToken={setToken} />
        </Provider>
    )
}
