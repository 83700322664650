import { Browser } from "@capacitor/browser"

import { FRONTEND_BASE_URL } from "../constants/endpoints"

export async function externalLinkHandler(url: string) {
    await Browser.open({ url })
}

export function appLinkHandler(href: string) {
    const mail: HTMLAnchorElement = document.createElement("a")
    mail.href = href
    mail.click()
}

export function addFromParamToDestination(destination: string, currentPath: string): string {
    const destinationURL = new URL(`${FRONTEND_BASE_URL}${destination}`)
    destinationURL.searchParams.set("from", encodeURIComponent(currentPath))
    return `${destinationURL.pathname}?${destinationURL.searchParams.toString()}`
}

export function buildReturnToBusinessPath(from: string, businessID: string): string {
    const returnPath = new URL(`${FRONTEND_BASE_URL}${decodeURIComponent(from)}`)
    returnPath.searchParams.set("return_to", businessID)
    return `${returnPath.pathname}?${returnPath.searchParams.toString()}`
}
