/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `0` - Free
 * * `1` - Inexpensive
 * * `2` - Moderate
 * * `3` - Expensive
 * * `4` - Very Expensive
 * @export
 */
export const PriceLevelEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;
export type PriceLevelEnum = typeof PriceLevelEnum[keyof typeof PriceLevelEnum];


export function PriceLevelEnumFromJSON(json: any): PriceLevelEnum {
    return PriceLevelEnumFromJSONTyped(json, false);
}

export function PriceLevelEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceLevelEnum {
    return json as PriceLevelEnum;
}

export function PriceLevelEnumToJSON(value?: PriceLevelEnum | null): any {
    return value as any;
}

