/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RedeemDealRewardBusiness } from './RedeemDealRewardBusiness';
import {
    RedeemDealRewardBusinessFromJSON,
    RedeemDealRewardBusinessFromJSONTyped,
    RedeemDealRewardBusinessToJSON,
} from './RedeemDealRewardBusiness';
import type { RedemptionDeal } from './RedemptionDeal';
import {
    RedemptionDealFromJSON,
    RedemptionDealFromJSONTyped,
    RedemptionDealToJSON,
} from './RedemptionDeal';

/**
 * 
 * @export
 * @interface RedeemDealReward
 */
export interface RedeemDealReward {
    /**
     * 
     * @type {number}
     * @memberof RedeemDealReward
     */
    readonly id: number;
    /**
     * 
     * @type {RedeemDealRewardBusiness}
     * @memberof RedeemDealReward
     */
    business: RedeemDealRewardBusiness;
    /**
     * 
     * @type {Array<RedemptionDeal>}
     * @memberof RedeemDealReward
     */
    readonly deals: Array<RedemptionDeal>;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealReward
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealReward
     */
    readonly description: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealReward
     */
    readonly price: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealReward
     */
    readonly amount_saved: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealReward
     */
    readonly image: string;
    /**
     * Specify how many menu items come with this reward 
     * @type {number}
     * @memberof RedeemDealReward
     */
    readonly quantity: number;
    /**
     * 
     * @type {boolean}
     * @memberof RedeemDealReward
     */
    readonly is_archived: boolean;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealReward
     */
    readonly reel_id: string;
}

/**
 * Check if a given object implements the RedeemDealReward interface.
 */
export function instanceOfRedeemDealReward(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "business" in value;
    isInstance = isInstance && "deals" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "amount_saved" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "is_archived" in value;
    isInstance = isInstance && "reel_id" in value;

    return isInstance;
}

export function RedeemDealRewardFromJSON(json: any): RedeemDealReward {
    return RedeemDealRewardFromJSONTyped(json, false);
}

export function RedeemDealRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedeemDealReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'business': RedeemDealRewardBusinessFromJSON(json['business']),
        'deals': ((json['deals'] as Array<any>).map(RedemptionDealFromJSON)),
        'name': json['name'],
        'description': json['description'],
        'price': json['price'],
        'amount_saved': json['amount_saved'],
        'image': json['image'],
        'quantity': json['quantity'],
        'is_archived': json['is_archived'],
        'reel_id': json['reel_id'],
    };
}

export function RedeemDealRewardToJSON(value?: RedeemDealReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'business': RedeemDealRewardBusinessToJSON(value.business),
    };
}

