/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Deal,
  DealRewardReel,
  PromotedDeal,
  RedeemDealReward,
} from '../models';
import {
    DealFromJSON,
    DealToJSON,
    DealRewardReelFromJSON,
    DealRewardReelToJSON,
    PromotedDealFromJSON,
    PromotedDealToJSON,
    RedeemDealRewardFromJSON,
    RedeemDealRewardToJSON,
} from '../models';

export interface DealsDealsListRequest {
    business_id: string;
}

export interface DealsDealsRetrieveRequest {
    business_id: string;
    id: number;
    include_archived?: boolean;
}

export interface DealsRewardsRetrieveRequest {
    business_id: string;
    id: number;
}

/**
 * DealsApi - interface
 * 
 * @export
 * @interface DealsApiInterface
 */
export interface DealsApiInterface {
    /**
     * 
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    dealsDealsListRaw(requestParameters: DealsDealsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Deal>>>;

    /**
     */
    dealsDealsList(requestParameters: DealsDealsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Deal>>;

    /**
     * 
     * @param {string} business_id 
     * @param {number} id A unique integer value identifying this Deal.
     * @param {boolean} [include_archived] Whether to include archived deals in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    dealsDealsRetrieveRaw(requestParameters: DealsDealsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Deal>>;

    /**
     */
    dealsDealsRetrieve(requestParameters: DealsDealsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Deal>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    dealsPromotedListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PromotedDeal>>>;

    /**
     */
    dealsPromotedList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PromotedDeal>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    dealsRewardsReelsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealRewardReel>>>;

    /**
     */
    dealsRewardsReelsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealRewardReel>>;

    /**
     * 
     * @param {string} business_id 
     * @param {number} id A unique integer value identifying this Deal Reward.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    dealsRewardsRetrieveRaw(requestParameters: DealsRewardsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedeemDealReward>>;

    /**
     */
    dealsRewardsRetrieve(requestParameters: DealsRewardsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedeemDealReward>;

}

/**
 * 
 */
export class DealsApi extends runtime.BaseAPI implements DealsApiInterface {

    /**
     */
    async dealsDealsListRaw(requestParameters: DealsDealsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Deal>>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling dealsDealsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/deals/{business_id}/deals/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealFromJSON));
    }

    /**
     */
    async dealsDealsList(requestParameters: DealsDealsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Deal>> {
        const response = await this.dealsDealsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dealsDealsRetrieveRaw(requestParameters: DealsDealsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Deal>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling dealsDealsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealsDealsRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.include_archived !== undefined) {
            queryParameters['include_archived'] = requestParameters.include_archived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/deals/{business_id}/deals/{id}/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealFromJSON(jsonValue));
    }

    /**
     */
    async dealsDealsRetrieve(requestParameters: DealsDealsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Deal> {
        const response = await this.dealsDealsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dealsPromotedListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PromotedDeal>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/deals/promoted/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PromotedDealFromJSON));
    }

    /**
     */
    async dealsPromotedList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PromotedDeal>> {
        const response = await this.dealsPromotedListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dealsRewardsReelsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealRewardReel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/deals/rewards/reels/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealRewardReelFromJSON));
    }

    /**
     */
    async dealsRewardsReelsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealRewardReel>> {
        const response = await this.dealsRewardsReelsListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dealsRewardsRetrieveRaw(requestParameters: DealsRewardsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedeemDealReward>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling dealsRewardsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealsRewardsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/deals/{business_id}/rewards/{id}/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedeemDealRewardFromJSON(jsonValue));
    }

    /**
     */
    async dealsRewardsRetrieve(requestParameters: DealsRewardsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedeemDealReward> {
        const response = await this.dealsRewardsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
