export const FRONTEND_BASE_URL = process.env.FRONTEND_BASE_URL || process.env.NEXT_PUBLIC_FRONTEND_BASE_URL
export const BACKEND_BASE_URL = process.env.BACKEND_BASE_URL || process.env.NEXT_PUBLIC_BACKEND_BASE_URL

export const ENDPOINTS = {
    CURRENT_USER: BACKEND_BASE_URL + "/user/me/",
    REGISTER_USER: BACKEND_BASE_URL + "/user/me/",
    UPDATE_USER: BACKEND_BASE_URL + "/user/me/",
    DELETE_USER: BACKEND_BASE_URL + "/user/me/",
    CURRENT_USER_SAVINGS: BACKEND_BASE_URL + "/user/me/savings/",
    CURRENT_USER_PRO_STATUS: BACKEND_BASE_URL + "/user/me/pro-status/",
    OTP_REQUEST: BACKEND_BASE_URL + "/auth/sms/",
    OTP_VERIFY: BACKEND_BASE_URL + "/auth/token/",
    LOGOUT: BACKEND_BASE_URL + "/auth/logout/",
    PAYMENT_CREATE_CHECKOUT_SESSION: BACKEND_BASE_URL + "/payments/create-checkout-session/",
    PAYMENT_CREATE_PORTAL_SESSION: BACKEND_BASE_URL + "/payments/create-portal-session/",
    LIST_BUSINESSES: BACKEND_BASE_URL + "/businesses/",
    LIST_BUSINESS_MAP_MARKERS: BACKEND_BASE_URL + "/businesses/map-markers/",
    LIST_ALTERNATIVE_BUSINESSES: BACKEND_BASE_URL + "/businesses/{id}/alternatives/",
    SEARCH_BUSINESSES: BACKEND_BASE_URL + "/businesses/search/",
    COUNT_BUSINESSES: BACKEND_BASE_URL + "/businesses/count/",
    RETRIEVE_BUSINESS: BACKEND_BASE_URL + "/businesses/{id}/",
    LIST_BUSINESS_DEAL_REWARDS: BACKEND_BASE_URL + "/businesses/{id}/deal-rewards/",
    LIST_BUSINESS_DEALS: BACKEND_BASE_URL + "/businesses/{id}/deals/",
    FAVORITE_BUSINESS: BACKEND_BASE_URL + "/businesses/{id}/favorite/",
    UNFAVORITE_BUSINESS: BACKEND_BASE_URL + "/businesses/{id}/unfavorite/",
    LIST_CONTESTS: BACKEND_BASE_URL + "/contests/",
    CLAIM_CONTEST: BACKEND_BASE_URL + "/contests/{id}/claim/",
    REQUEST_DEAL: BACKEND_BASE_URL + "/businesses/{id}/request-deal/",
    UNREQUEST_DEAL: BACKEND_BASE_URL + "/businesses/{id}/unrequest-deal/",
    LIST_MENU_CATEGORIES: BACKEND_BASE_URL + "/menus/{business_id}/categories/",
    LIST_MENU_ITEMS: BACKEND_BASE_URL + "/menus/{business_id}/items/",
    RETRIEVE_MENU_ITEM: BACKEND_BASE_URL + "/menus/{business_id}/items/{id}/",
    FAVORITE_MENU_ITEM: BACKEND_BASE_URL + "/menus/{business_id}/items/{id}/favorite/",
    UNFAVORITE_MENU_ITEM: BACKEND_BASE_URL + "/menus/{business_id}/items/{id}/unfavorite/",
    RATE_MENU_ITEM: BACKEND_BASE_URL + "/menus/{business_id}/items/{id}/rate/",
    LIST_DEALS: BACKEND_BASE_URL + "/deals/{business_id}/deals/",
    LIST_PROMOTED_DEALS: BACKEND_BASE_URL + "/deals/promoted/",
    LIST_DEAL_IMAGES: BACKEND_BASE_URL + "/deals/images/",
    RETRIEVE_DEAL_REWARD: BACKEND_BASE_URL + "/deals/{business_id}/rewards/{id}/",
    LIST_DEAL_REWARD_REELS: BACKEND_BASE_URL + "/deals/rewards/reels/",
    LIST_GIVEAWAYS: BACKEND_BASE_URL + "/giveaways/",
    RETRIEVE_GIVEAWAY: BACKEND_BASE_URL + "/giveaways/{id}",
    LIST_REDEMPTIONS: BACKEND_BASE_URL + "/redemptions/",
    REDEEM: BACKEND_BASE_URL + "/redemptions/",
    REDEEM_VALIDATE: BACKEND_BASE_URL + "/redemptions/validate/",
    LIST_REFERRALS: BACKEND_BASE_URL + "/referrals/",
    LIST_TESTIMONIALS: BACKEND_BASE_URL + "/testimonials/",
    LIST_FAQS: BACKEND_BASE_URL + "/faqs/",
    CANCEL_MEMBERSHIP: BACKEND_BASE_URL + "/cancellations/",
}
