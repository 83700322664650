/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MapCenterPoint } from './MapCenterPoint';
import {
    MapCenterPointFromJSON,
    MapCenterPointFromJSONTyped,
    MapCenterPointToJSON,
} from './MapCenterPoint';

/**
 * 
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {number}
     * @memberof City
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    readonly state: string;
    /**
     * 
     * @type {MapCenterPoint}
     * @memberof City
     */
    map_center_geo_point: MapCenterPoint;
    /**
     * 
     * @type {number}
     * @memberof City
     */
    readonly map_zoom_level: number;
    /**
     * 
     * @type {boolean}
     * @memberof City
     */
    readonly feature_enabled_contests: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof City
     */
    readonly is_active: boolean;
}

/**
 * Check if a given object implements the City interface.
 */
export function instanceOfCity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "map_center_geo_point" in value;
    isInstance = isInstance && "map_zoom_level" in value;
    isInstance = isInstance && "feature_enabled_contests" in value;
    isInstance = isInstance && "is_active" in value;

    return isInstance;
}

export function CityFromJSON(json: any): City {
    return CityFromJSONTyped(json, false);
}

export function CityFromJSONTyped(json: any, ignoreDiscriminator: boolean): City {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'state': json['state'],
        'map_center_geo_point': MapCenterPointFromJSON(json['map_center_geo_point']),
        'map_zoom_level': json['map_zoom_level'],
        'feature_enabled_contests': json['feature_enabled_contests'],
        'is_active': json['is_active'],
    };
}

export function CityToJSON(value?: City | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'map_center_geo_point': MapCenterPointToJSON(value.map_center_geo_point),
    };
}

