/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `1` - Redemption
 * @export
 */
export const EntryTypeEnum = {
    NUMBER_1: 1
} as const;
export type EntryTypeEnum = typeof EntryTypeEnum[keyof typeof EntryTypeEnum];


export function EntryTypeEnumFromJSON(json: any): EntryTypeEnum {
    return EntryTypeEnumFromJSONTyped(json, false);
}

export function EntryTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryTypeEnum {
    return json as EntryTypeEnum;
}

export function EntryTypeEnumToJSON(value?: EntryTypeEnum | null): any {
    return value as any;
}

