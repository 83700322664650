/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RedemptionListBusiness } from './RedemptionListBusiness';
import {
    RedemptionListBusinessFromJSON,
    RedemptionListBusinessFromJSONTyped,
    RedemptionListBusinessToJSON,
} from './RedemptionListBusiness';
import type { RedemptionListDeal } from './RedemptionListDeal';
import {
    RedemptionListDealFromJSON,
    RedemptionListDealFromJSONTyped,
    RedemptionListDealToJSON,
} from './RedemptionListDeal';
import type { RedemptionListReward } from './RedemptionListReward';
import {
    RedemptionListRewardFromJSON,
    RedemptionListRewardFromJSONTyped,
    RedemptionListRewardToJSON,
} from './RedemptionListReward';

/**
 * 
 * @export
 * @interface RedemptionList
 */
export interface RedemptionList {
    /**
     * 
     * @type {number}
     * @memberof RedemptionList
     */
    readonly id: number;
    /**
     * 
     * @type {RedemptionListBusiness}
     * @memberof RedemptionList
     */
    business: RedemptionListBusiness;
    /**
     * 
     * @type {RedemptionListDeal}
     * @memberof RedemptionList
     */
    deal: RedemptionListDeal;
    /**
     * 
     * @type {string}
     * @memberof RedemptionList
     */
    readonly price: string;
    /**
     * 
     * @type {string}
     * @memberof RedemptionList
     */
    readonly amount_saved: string;
    /**
     * 
     * @type {RedemptionListReward}
     * @memberof RedemptionList
     */
    reward: RedemptionListReward;
    /**
     * 
     * @type {Date}
     * @memberof RedemptionList
     */
    readonly created_at: Date;
}

/**
 * Check if a given object implements the RedemptionList interface.
 */
export function instanceOfRedemptionList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "business" in value;
    isInstance = isInstance && "deal" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "amount_saved" in value;
    isInstance = isInstance && "reward" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function RedemptionListFromJSON(json: any): RedemptionList {
    return RedemptionListFromJSONTyped(json, false);
}

export function RedemptionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedemptionList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'business': RedemptionListBusinessFromJSON(json['business']),
        'deal': RedemptionListDealFromJSON(json['deal']),
        'price': json['price'],
        'amount_saved': json['amount_saved'],
        'reward': RedemptionListRewardFromJSON(json['reward']),
        'created_at': (new Date(json['created_at'])),
    };
}

export function RedemptionListToJSON(value?: RedemptionList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'business': RedemptionListBusinessToJSON(value.business),
        'deal': RedemptionListDealToJSON(value.deal),
        'reward': RedemptionListRewardToJSON(value.reward),
    };
}

