import RecentSearchItem from "../types/RecentSearchItem"

export const PREFERENCE_KEY_AUTH_TOKEN = "sloeats_auth"
export const PREFERENCE_KEY_AUTH_TOKEN_DEFAULT: string = null

export const PREFERENCE_KEY_RECENT_SEARCHES = "sloeats_discover_recent_searches"
export const PREFERENCE_KEY_RECENT_SEARCHES_DEFAULT: RecentSearchItem[] = []

export const PREFERENCE_KEY_HIDDEN_GIVEAWAYS = "sloeats_hidden_giveaways"
export const PREFERENCE_KEY_HIDDEN_GIVEAWAYS_DEFAULT: Array<number> = []

export const PREFERENCE_KEY_CHECKED_CONTESTS = "sloeats_checked_contests"
export const PREFERENCE_KEY_CHECKED_CONTESTS_DEFAULT: Array<number> = []

export const PREFERENCE_KEY_DISMISSED_CONTEST_ANNOUNCEMENTS = "sloeats_dismissed_contest_announcements"
export const PREFERENCE_KEY_DISMISSED_CONTEST_ANNOUNCEMENTS_DEFAULT: Array<number> = []

export const PREFERENCE_KEY_SKIP_PERMISSIONS_CHECK = "sloeats_skip_permissions_check"
export const PREFERENCE_KEY_SKIP_PERMISSIONS_CHECK_DEFAULT = false

export const PREFERENCE_KEY_LAST_REDEMPTION_DATETIME = "sloeats_last_redemption"
export const PREFERENCE_KEY_LAST_REDEMPTION_DATETIME_DEFAULT = ""

export const PREFERENCE_KEY_LAST_REVIEW_REQUEST_DATETIME = "sloeats_last_review_request"
export const PREFERENCE_KEY_LAST_REVIEW_REQUEST_DATETIME_DEFAULT = ""

export const PREFERENCE_KEY_WATCHED_REELS = "sloeats_watched_reels"
export const PREFERENCE_KEY_WATCHED_REELS_DEFAULT: Array<number> = []
