import ReactMarkdown, { Options as ReactMarkdownOptions } from "react-markdown"
import rehypeRaw from "rehype-raw"
import rehypeSanitize from "rehype-sanitize"
import remarkGfm from "remark-gfm"

export default function MarkdownDisplay(props: ReactMarkdownOptions) {
    const { remarkPlugins, rehypePlugins, ...rest } = props

    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm, ...(remarkPlugins || [])]}
            rehypePlugins={[rehypeRaw, rehypeSanitize, ...(rehypePlugins || [])]}
            {...rest}
        />
    )
}
