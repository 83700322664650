import { Capacitor } from "@capacitor/core"
import { AppUpdate, AppUpdateAvailability, AppUpdateInfo } from "@capawesome/capacitor-app-update"
import { useEffect, useState } from "react"

import UpdateScreen from "./UpdateScreen/UpdateScreen"

export default function UpdateCheck(props: { children: JSX.Element }): JSX.Element {
    const { children } = props
    const [updateInfo, setUpdateInfo] = useState<AppUpdateInfo>()

    useEffect(() => {
        const getUpdateInfo = async () => {
            const updateInfo = await AppUpdate.getAppUpdateInfo()
            setUpdateInfo(updateInfo)
        }
        if (Capacitor.isNativePlatform()) {
            getUpdateInfo()
        }
    }, [])

    if (updateInfo !== undefined && updateInfo?.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
        return (
            <main>
                <UpdateScreen updateInfo={updateInfo} />
            </main>
        )
    } else {
        return children
    }
}
