/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `1` - Restaurant Gift Card
 * @export
 */
export const PrizeTypeEnum = {
    NUMBER_1: 1
} as const;
export type PrizeTypeEnum = typeof PrizeTypeEnum[keyof typeof PrizeTypeEnum];


export function PrizeTypeEnumFromJSON(json: any): PrizeTypeEnum {
    return PrizeTypeEnumFromJSONTyped(json, false);
}

export function PrizeTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrizeTypeEnum {
    return json as PrizeTypeEnum;
}

export function PrizeTypeEnumToJSON(value?: PrizeTypeEnum | null): any {
    return value as any;
}

