/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OTPAuthenticateResponse200
 */
export interface OTPAuthenticateResponse200 {
    /**
     * 
     * @type {string}
     * @memberof OTPAuthenticateResponse200
     */
    token: string;
    /**
     * 
     * @type {boolean}
     * @memberof OTPAuthenticateResponse200
     */
    user_created: boolean;
}

/**
 * Check if a given object implements the OTPAuthenticateResponse200 interface.
 */
export function instanceOfOTPAuthenticateResponse200(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "user_created" in value;

    return isInstance;
}

export function OTPAuthenticateResponse200FromJSON(json: any): OTPAuthenticateResponse200 {
    return OTPAuthenticateResponse200FromJSONTyped(json, false);
}

export function OTPAuthenticateResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): OTPAuthenticateResponse200 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'user_created': json['user_created'],
    };
}

export function OTPAuthenticateResponse200ToJSON(value?: OTPAuthenticateResponse200 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'user_created': value.user_created,
    };
}

