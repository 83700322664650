import { FieldPath, UseFormSetError } from "react-hook-form"

export type JSONError<TFieldValues> = { [key in FieldPath<TFieldValues>]: string[] }

export default class JSONFormError<TFieldValues> extends Error {
    readonly status: number
    readonly errors: JSONError<TFieldValues>

    constructor({ status, errors }: { status: number; errors: JSONError<TFieldValues> }) {
        super(status.toString())
        this.status = status
        this.errors = errors
    }
}

export function handleJSONFormError<TFieldValues>(
    error: JSONFormError<TFieldValues> | string,
    setError: UseFormSetError<TFieldValues>,
) {
    if (error instanceof JSONFormError && error.status === 400) {
        Object.entries<string[]>(error.errors).forEach(([fieldName, messages]) =>
            setError(fieldName as FieldPath<TFieldValues>, { type: "manual", message: messages[0] }),
        )
        return true
    } else {
        console.error(error)
        return false
    }
}
