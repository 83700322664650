import { Capacitor } from "@capacitor/core"

import Option, { Choices } from "../types/Option"

export function choicesToOptions(choices: Choices, sort = false): Option<string>[] {
    let options = Object.entries(choices).map(([value, label]) => ({ label, value }))

    if (sort) {
        options = options.sort((a, b) => a.label.localeCompare(b.label))
    }

    return options
}

export function addressToDirectionsLink(address: string) {
    const encodedAddress = encodeURIComponent(address)

    // Apple needs some special treatment; android auto-opens a google maps url in maps; desktop goes to google maps
    let path = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`

    if (
        Capacitor.getPlatform() === "ios" ||
        navigator?.platform.includes("iPhone") ||
        navigator?.platform.includes("iPad") ||
        navigator?.platform.includes("iPod")
    ) {
        path = `https://maps.apple.com/?daddr=${encodedAddress}`
    }

    return path
}
