/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RoleEnum = {
    admin: 'admin',
    manager: 'manager',
    none: 'none'
} as const;
export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


export function RoleEnumFromJSON(json: any): RoleEnum {
    return RoleEnumFromJSONTyped(json, false);
}

export function RoleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleEnum {
    return json as RoleEnum;
}

export function RoleEnumToJSON(value?: RoleEnum | null): any {
    return value as any;
}

