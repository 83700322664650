/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedNotificationPreference
 */
export interface PatchedNotificationPreference {
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotificationPreference
     */
    notifications_enable_new_deal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotificationPreference
     */
    notifications_enable_deal_promotion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotificationPreference
     */
    notifications_enable_new_referral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotificationPreference
     */
    notifications_enable_new_giveaway?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotificationPreference
     */
    notifications_enable_new_feature?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotificationPreference
     */
    notifications_enable_new_message?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotificationPreference
     */
    notifications_enable_contest_announcement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNotificationPreference
     */
    notifications_enable_welcome_message?: boolean;
}

/**
 * Check if a given object implements the PatchedNotificationPreference interface.
 */
export function instanceOfPatchedNotificationPreference(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedNotificationPreferenceFromJSON(json: any): PatchedNotificationPreference {
    return PatchedNotificationPreferenceFromJSONTyped(json, false);
}

export function PatchedNotificationPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotificationPreference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notifications_enable_new_deal': !exists(json, 'notifications_enable_new_deal') ? undefined : json['notifications_enable_new_deal'],
        'notifications_enable_deal_promotion': !exists(json, 'notifications_enable_deal_promotion') ? undefined : json['notifications_enable_deal_promotion'],
        'notifications_enable_new_referral': !exists(json, 'notifications_enable_new_referral') ? undefined : json['notifications_enable_new_referral'],
        'notifications_enable_new_giveaway': !exists(json, 'notifications_enable_new_giveaway') ? undefined : json['notifications_enable_new_giveaway'],
        'notifications_enable_new_feature': !exists(json, 'notifications_enable_new_feature') ? undefined : json['notifications_enable_new_feature'],
        'notifications_enable_new_message': !exists(json, 'notifications_enable_new_message') ? undefined : json['notifications_enable_new_message'],
        'notifications_enable_contest_announcement': !exists(json, 'notifications_enable_contest_announcement') ? undefined : json['notifications_enable_contest_announcement'],
        'notifications_enable_welcome_message': !exists(json, 'notifications_enable_welcome_message') ? undefined : json['notifications_enable_welcome_message'],
    };
}

export function PatchedNotificationPreferenceToJSON(value?: PatchedNotificationPreference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notifications_enable_new_deal': value.notifications_enable_new_deal,
        'notifications_enable_deal_promotion': value.notifications_enable_deal_promotion,
        'notifications_enable_new_referral': value.notifications_enable_new_referral,
        'notifications_enable_new_giveaway': value.notifications_enable_new_giveaway,
        'notifications_enable_new_feature': value.notifications_enable_new_feature,
        'notifications_enable_new_message': value.notifications_enable_new_message,
        'notifications_enable_contest_announcement': value.notifications_enable_contest_announcement,
        'notifications_enable_welcome_message': value.notifications_enable_welcome_message,
    };
}

