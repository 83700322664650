/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `1` - Buy One Item, Get a Second Free
 * * `2` - Buy a Specific Item, Get Item(s) Free
 * * `3` - Spend a Certain Amount, Get Item(s) Free
 * * `4` - Half Off Drinks
 * * `5` - Special Offer
 * @export
 */
export const Type97cEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type Type97cEnum = typeof Type97cEnum[keyof typeof Type97cEnum];


export function Type97cEnumFromJSON(json: any): Type97cEnum {
    return Type97cEnumFromJSONTyped(json, false);
}

export function Type97cEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): Type97cEnum {
    return json as Type97cEnum;
}

export function Type97cEnumToJSON(value?: Type97cEnum | null): any {
    return value as any;
}

