import { Preferences } from "@capacitor/preferences"
import { atomWithStorage, createJSONStorage } from "jotai/utils"
import { AsyncStringStorage } from "jotai/vanilla/utils/atomWithStorage"

import {
    PREFERENCE_KEY_AUTH_TOKEN,
    PREFERENCE_KEY_AUTH_TOKEN_DEFAULT,
    PREFERENCE_KEY_CHECKED_CONTESTS,
    PREFERENCE_KEY_CHECKED_CONTESTS_DEFAULT,
    PREFERENCE_KEY_DISMISSED_CONTEST_ANNOUNCEMENTS,
    PREFERENCE_KEY_DISMISSED_CONTEST_ANNOUNCEMENTS_DEFAULT,
    PREFERENCE_KEY_HIDDEN_GIVEAWAYS,
    PREFERENCE_KEY_HIDDEN_GIVEAWAYS_DEFAULT,
    PREFERENCE_KEY_LAST_REDEMPTION_DATETIME,
    PREFERENCE_KEY_LAST_REDEMPTION_DATETIME_DEFAULT,
    PREFERENCE_KEY_LAST_REVIEW_REQUEST_DATETIME,
    PREFERENCE_KEY_LAST_REVIEW_REQUEST_DATETIME_DEFAULT,
    PREFERENCE_KEY_RECENT_SEARCHES,
    PREFERENCE_KEY_RECENT_SEARCHES_DEFAULT,
    PREFERENCE_KEY_WATCHED_REELS,
    PREFERENCE_KEY_WATCHED_REELS_DEFAULT,
} from "../constants/preferences"
import RecentSearchItem from "../types/RecentSearchItem"
import { atomWithBlockingAsyncStorage } from "../utils/storage"

const preferencesStorage: AsyncStringStorage = {
    getItem: async (key: string) => {
        try {
            const { value } = await Preferences.get({ key })
            return value
        } catch (error) {
            console.error("Error while checking preferences:", error)
            return null
        }
    },
    setItem: async (key: string, newValue: string) => {
        await Preferences.set({ key, value: newValue })
    },
    removeItem: (key: string) => Preferences.remove({ key }),
}

export const authTokenPreferenceAtom = atomWithBlockingAsyncStorage<string>(
    PREFERENCE_KEY_AUTH_TOKEN,
    PREFERENCE_KEY_AUTH_TOKEN_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)

export const recentSearchesPreferenceAtom = atomWithStorage<RecentSearchItem[]>(
    PREFERENCE_KEY_RECENT_SEARCHES,
    PREFERENCE_KEY_RECENT_SEARCHES_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)

export const hiddenGiveawaysPreferenceAtom = atomWithStorage<number[]>(
    PREFERENCE_KEY_HIDDEN_GIVEAWAYS,
    PREFERENCE_KEY_HIDDEN_GIVEAWAYS_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)

export const checkedContestsPreferenceAtom = atomWithStorage<number[]>(
    PREFERENCE_KEY_CHECKED_CONTESTS,
    PREFERENCE_KEY_CHECKED_CONTESTS_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)

export const dismissedContestAnnouncementsPreferenceAtom = atomWithStorage<number[]>(
    PREFERENCE_KEY_DISMISSED_CONTEST_ANNOUNCEMENTS,
    PREFERENCE_KEY_DISMISSED_CONTEST_ANNOUNCEMENTS_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)

export const lastRedemptionDatetimePreferenceAtom = atomWithStorage<string>(
    PREFERENCE_KEY_LAST_REDEMPTION_DATETIME,
    PREFERENCE_KEY_LAST_REDEMPTION_DATETIME_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)

export const lastReviewRequestDatetimePreferenceAtom = atomWithStorage<string>(
    PREFERENCE_KEY_LAST_REVIEW_REQUEST_DATETIME,
    PREFERENCE_KEY_LAST_REVIEW_REQUEST_DATETIME_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)

export const watchedReelsSessionPreferenceAtom = atomWithStorage<number[]>(
    PREFERENCE_KEY_WATCHED_REELS,
    PREFERENCE_KEY_WATCHED_REELS_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)

export const watchedReelsPersistancePreferenceAtom = atomWithStorage<number[]>(
    PREFERENCE_KEY_WATCHED_REELS,
    PREFERENCE_KEY_WATCHED_REELS_DEFAULT,
    createJSONStorage(() => preferencesStorage),
)
