import { useRouter } from "next/router"
import { useEffect } from "react"

import useUser from "../../hooks/User/useUser"

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || process.env.NEXT_PUBLIC_INTERCOM_APP_ID

export default function AnalyticsInitializer() {
    const { user } = useUser()
    const router = useRouter()

    useEffect(() => {
        const handleRouteChange = () => {
            window?.Intercom("update")
        }

        router.events.on("routeChangeStart", handleRouteChange)

        return () => {
            router.events.off("routeChangeStart", handleRouteChange)
        }
    }, [router.events])

    useEffect(() => {
        if (window.Intercom && user) {
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: INTERCOM_APP_ID,
                user_id: user.id,
                user_hash: user.intercom_hash,
                name: user.full_name,
                phone: user.phone,
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                birthday: new Date(user.birthday).toISOString(),
                is_pro: user.is_pro,
                created_at: new Date(user.date_joined).getTime(),
            })
        }
    }, [user])

    return null
}
