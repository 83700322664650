/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `new-deal` - New Deal
 * * `deal-promotion` - Deal Promotion
 * * `new-referral` - New Referral
 * * `new-giveaway` - New Giveaway
 * * `new-feature` - New Feature
 * * `new-message` - New Message
 * * `contest-announcement` - Contest Announcement
 * * `welcome-message` - Welcome Message
 * @export
 */
export const NotificationTypeEnum = {
    new_deal: 'new-deal',
    deal_promotion: 'deal-promotion',
    new_referral: 'new-referral',
    new_giveaway: 'new-giveaway',
    new_feature: 'new-feature',
    new_message: 'new-message',
    contest_announcement: 'contest-announcement',
    welcome_message: 'welcome-message'
} as const;
export type NotificationTypeEnum = typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum];


export function NotificationTypeEnumFromJSON(json: any): NotificationTypeEnum {
    return NotificationTypeEnumFromJSONTyped(json, false);
}

export function NotificationTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationTypeEnum {
    return json as NotificationTypeEnum;
}

export function NotificationTypeEnumToJSON(value?: NotificationTypeEnum | null): any {
    return value as any;
}

