/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Business } from './Business';
import {
    BusinessFromJSON,
    BusinessFromJSONTyped,
    BusinessToJSON,
} from './Business';
import type { BusinessImage } from './BusinessImage';
import {
    BusinessImageFromJSON,
    BusinessImageFromJSONTyped,
    BusinessImageToJSON,
} from './BusinessImage';
import type { BusinessPhysicalAddressGeoPoint } from './BusinessPhysicalAddressGeoPoint';
import {
    BusinessPhysicalAddressGeoPointFromJSON,
    BusinessPhysicalAddressGeoPointFromJSONTyped,
    BusinessPhysicalAddressGeoPointToJSON,
} from './BusinessPhysicalAddressGeoPoint';
import type { PriceLevelEnum } from './PriceLevelEnum';
import {
    PriceLevelEnumFromJSON,
    PriceLevelEnumFromJSONTyped,
    PriceLevelEnumToJSON,
} from './PriceLevelEnum';

/**
 * 
 * @export
 * @interface RedeemDealRewardBusiness
 */
export interface RedeemDealRewardBusiness {
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly id: string;
    /**
     * 
     * @type {boolean}
     * @memberof RedeemDealRewardBusiness
     */
    readonly is_pro: boolean;
    /**
     * Whether or not this business was ever a Pro.
     * @type {boolean}
     * @memberof RedeemDealRewardBusiness
     */
    readonly was_pro: boolean;
    /**
     * The message to display on the deals page for was_pro businesses.
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly was_pro_message: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly phone: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly cover_image: string;
    /**
     * 
     * @type {Array<BusinessImage>}
     * @memberof RedeemDealRewardBusiness
     */
    readonly images: Array<BusinessImage>;
    /**
     * 3-letter ISO currency code. See https://stripe.com/docs/currencies.
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly currency_code: string;
    /**
     * IETF BCP 47 language tag.
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly language_code: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly currency_symbol: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly physical_address_formatted: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly physical_address_formatted_multiline: string;
    /**
     * 
     * @type {BusinessPhysicalAddressGeoPoint}
     * @memberof RedeemDealRewardBusiness
     */
    physical_address_geo_point: BusinessPhysicalAddressGeoPoint | null;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly google_place_id: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly google_place_url: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly google_place_rating: string | null;
    /**
     * 
     * @type {number}
     * @memberof RedeemDealRewardBusiness
     */
    readonly google_place_review_count: number | null;
    /**
     * (without the @)
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly instagram_handle: string;
    /**
     * The price level of this business. Overridden by Google data for businesses on Google.
     * 
     * * `0` - Free
     * * `1` - Inexpensive
     * * `2` - Moderate
     * * `3` - Expensive
     * * `4` - Very Expensive
     * @type {PriceLevelEnum}
     * @memberof RedeemDealRewardBusiness
     */
    readonly price_level: PriceLevelEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RedeemDealRewardBusiness
     */
    readonly cuisines: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RedeemDealRewardBusiness
     */
    readonly diets: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RedeemDealRewardBusiness
     */
    readonly amenities: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RedeemDealRewardBusiness
     */
    readonly favorite_count: number;
    /**
     * 
     * @type {number}
     * @memberof RedeemDealRewardBusiness
     */
    readonly deal_request_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof RedeemDealRewardBusiness
     */
    readonly is_open: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedeemDealRewardBusiness
     */
    readonly is_redemption_open: boolean;
    /**
     * 
     * @type {string}
     * @memberof RedeemDealRewardBusiness
     */
    readonly next_open: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof RedeemDealRewardBusiness
     */
    readonly hours: { [key: string]: Array<string>; };
}

/**
 * Check if a given object implements the RedeemDealRewardBusiness interface.
 */
export function instanceOfRedeemDealRewardBusiness(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "is_pro" in value;
    isInstance = isInstance && "was_pro" in value;
    isInstance = isInstance && "was_pro_message" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "cover_image" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "currency_code" in value;
    isInstance = isInstance && "language_code" in value;
    isInstance = isInstance && "currency_symbol" in value;
    isInstance = isInstance && "physical_address_formatted" in value;
    isInstance = isInstance && "physical_address_formatted_multiline" in value;
    isInstance = isInstance && "physical_address_geo_point" in value;
    isInstance = isInstance && "google_place_id" in value;
    isInstance = isInstance && "google_place_url" in value;
    isInstance = isInstance && "google_place_rating" in value;
    isInstance = isInstance && "google_place_review_count" in value;
    isInstance = isInstance && "instagram_handle" in value;
    isInstance = isInstance && "price_level" in value;
    isInstance = isInstance && "cuisines" in value;
    isInstance = isInstance && "diets" in value;
    isInstance = isInstance && "amenities" in value;
    isInstance = isInstance && "favorite_count" in value;
    isInstance = isInstance && "deal_request_count" in value;
    isInstance = isInstance && "is_open" in value;
    isInstance = isInstance && "is_redemption_open" in value;
    isInstance = isInstance && "next_open" in value;
    isInstance = isInstance && "hours" in value;

    return isInstance;
}

export function RedeemDealRewardBusinessFromJSON(json: any): RedeemDealRewardBusiness {
    return RedeemDealRewardBusinessFromJSONTyped(json, false);
}

export function RedeemDealRewardBusinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedeemDealRewardBusiness {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'is_pro': json['is_pro'],
        'was_pro': json['was_pro'],
        'was_pro_message': json['was_pro_message'],
        'name': json['name'],
        'phone': json['phone'],
        'cover_image': json['cover_image'],
        'images': ((json['images'] as Array<any>).map(BusinessImageFromJSON)),
        'currency_code': json['currency_code'],
        'language_code': json['language_code'],
        'currency_symbol': json['currency_symbol'],
        'physical_address_formatted': json['physical_address_formatted'],
        'physical_address_formatted_multiline': json['physical_address_formatted_multiline'],
        'physical_address_geo_point': BusinessPhysicalAddressGeoPointFromJSON(json['physical_address_geo_point']),
        'google_place_id': json['google_place_id'],
        'google_place_url': json['google_place_url'],
        'google_place_rating': json['google_place_rating'],
        'google_place_review_count': json['google_place_review_count'],
        'instagram_handle': json['instagram_handle'],
        'price_level': PriceLevelEnumFromJSON(json['price_level']),
        'cuisines': json['cuisines'],
        'diets': json['diets'],
        'amenities': json['amenities'],
        'favorite_count': json['favorite_count'],
        'deal_request_count': json['deal_request_count'],
        'is_open': json['is_open'],
        'is_redemption_open': json['is_redemption_open'],
        'next_open': json['next_open'],
        'hours': json['hours'],
    };
}

export function RedeemDealRewardBusinessToJSON(value?: RedeemDealRewardBusiness | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physical_address_geo_point': BusinessPhysicalAddressGeoPointToJSON(value.physical_address_geo_point),
    };
}

