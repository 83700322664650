import clsx from "clsx"
import { useState } from "react"

import { debouncedClickHandler } from "../../../utils/debounce"
import styles from "./Button.module.scss"
import { ButtonProps } from "./Button.types"

export default function Button(props: ButtonProps) {
    const {
        type = "button",
        variant,
        content,
        clickHandler,
        ignoreDebounce = false,
        disabled = false,
        extraClasses = null,
        ...rest
    } = props

    const [waiting, setWaiting] = useState(false)

    return (
        <button
            type={type}
            className={clsx(styles.button, styles[variant], ...(extraClasses || []), {
                [styles.waiting]: waiting,
            })}
            onClick={
                clickHandler != undefined
                    ? (event) =>
                          ignoreDebounce
                              ? clickHandler(event)
                              : debouncedClickHandler(clickHandler, event, waiting, setWaiting)
                    : undefined
            }
            disabled={waiting || disabled ? true : undefined}
            {...rest}
        >
            {content}
        </button>
    )
}
