/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaymentSession,
} from '../models';
import {
    PaymentSessionFromJSON,
    PaymentSessionToJSON,
} from '../models';

/**
 * PaymentsApi - interface
 * 
 * @export
 * @interface PaymentsApiInterface
 */
export interface PaymentsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApiInterface
     */
    paymentsCreateCheckoutSessionCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentSession>>;

    /**
     */
    paymentsCreateCheckoutSessionCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentSession>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApiInterface
     */
    paymentsCreatePortalSessionCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentSession>>;

    /**
     */
    paymentsCreatePortalSessionCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentSession>;

}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI implements PaymentsApiInterface {

    /**
     */
    async paymentsCreateCheckoutSessionCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentSession>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/payments/create-checkout-session/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentSessionFromJSON(jsonValue));
    }

    /**
     */
    async paymentsCreateCheckoutSessionCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentSession> {
        const response = await this.paymentsCreateCheckoutSessionCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async paymentsCreatePortalSessionCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentSession>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/payments/create-portal-session/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentSessionFromJSON(jsonValue));
    }

    /**
     */
    async paymentsCreatePortalSessionCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentSession> {
        const response = await this.paymentsCreatePortalSessionCreateRaw(initOverrides);
        return await response.value();
    }

}
