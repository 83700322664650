import { App } from "@capacitor/app"
import { useCallback, useEffect } from "react"

import useUser from "../../hooks/User/useUser"
import { fetchUserProStatus } from "../../requests/pro"

export default function ProStatusListener() {
    const { user, reloadUser } = useUser()

    const checkProStatus = useCallback(async () => {
        if (!user) {
            return
        }

        const currentProStatus = user?.is_pro
        const newProStatus = (await fetchUserProStatus(user?.token)).is_pro

        if (currentProStatus !== newProStatus) {
            reloadUser()
        }
    }, [user, reloadUser])

    useEffect(() => {
        // Any time the user resumes the app, re-check their pro status
        const listenerPromise = App.addListener("resume", () => {
            checkProStatus()
        })

        // Check their pro status every 10 seconds
        const interval = setInterval(checkProStatus, 10000)

        return () => {
            listenerPromise.then((listener) => listener.remove())
            clearInterval(interval)
        }
    }, [checkProStatus])

    return null
}
