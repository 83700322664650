/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `1` - Male
 * * `2` - Female
 * * `0` - Rather not say
 * @export
 */
export const SexEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_0: 0
} as const;
export type SexEnum = typeof SexEnum[keyof typeof SexEnum];


export function SexEnumFromJSON(json: any): SexEnum {
    return SexEnumFromJSONTyped(json, false);
}

export function SexEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SexEnum {
    return json as SexEnum;
}

export function SexEnumToJSON(value?: SexEnum | null): any {
    return value as any;
}

