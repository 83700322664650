import { SetStateAction, atom } from "jotai"
import { AsyncStorage } from "jotai/vanilla/utils/atomWithStorage"

export function atomWithBlockingAsyncStorage<Value>(key: string, initialValue: Value, storage: AsyncStorage<Value>) {
    // Setting the promise as the initial value is a hack to prevent the atom from being read before the atom is set on mount.
    const baseAtom = atom<Value>(new Promise<Value>(() => {}) as Value)

    baseAtom.onMount = (setValue) => {
        ;(async () => {
            const item = await storage.getItem(key, initialValue)
            setValue(item)
        })()
    }
    const derivedAtom = atom(
        (get) => get(baseAtom),
        async (get, set, update: SetStateAction<Value>) => {
            const nextValue = update instanceof Function ? update(get(baseAtom)) : update
            set(baseAtom, nextValue)
            await storage.setItem(key, nextValue)
        },
    )
    return derivedAtom
}
