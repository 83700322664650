import { App, URLOpenListenerEvent } from "@capacitor/app"
import { useRouter } from "next/router"
import { useEffect } from "react"

import { FRONTEND_BASE_URL } from "../../constants/endpoints"

export default function AppUrlListener() {
    const router = useRouter()

    useEffect(() => {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            const path = event.url.split(FRONTEND_BASE_URL).pop()

            if (path) {
                router.push(path)
            }
        })
    })

    return null
}
