/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TotalSavings
 */
export interface TotalSavings {
    /**
     * 
     * @type {string}
     * @memberof TotalSavings
     */
    readonly savings: string;
}

/**
 * Check if a given object implements the TotalSavings interface.
 */
export function instanceOfTotalSavings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "savings" in value;

    return isInstance;
}

export function TotalSavingsFromJSON(json: any): TotalSavings {
    return TotalSavingsFromJSONTyped(json, false);
}

export function TotalSavingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TotalSavings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'savings': json['savings'],
    };
}

export function TotalSavingsToJSON(value?: TotalSavings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

