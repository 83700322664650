import { NextRouter } from "next/router"

import { PaginatedContestList } from "../../sdk"
import { UNAUTHENTICATED_USER_MESSAGE, handleUnauthenticatedUser } from "../components/utils/AuthCheck"
import { ENDPOINTS } from "../constants/endpoints"

export async function fetchContests(
    token: string,
    searchParamsString: string,
    nextPage: string = null,
): Promise<PaginatedContestList> {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    let fetchUrl: string

    if (nextPage) {
        fetchUrl = nextPage
    } else {
        fetchUrl = ENDPOINTS.LIST_CONTESTS + "?" + searchParamsString
    }

    const request = new Request(fetchUrl, { method: "GET", headers: headers })
    const response = await fetch(request)

    if (!response.ok) {
        if (response.status === 403) {
            throw new Error(UNAUTHENTICATED_USER_MESSAGE)
        } else {
            throw new Error(`There was an error while attempting to load contests.`)
        }
    }

    return (await response.json()) as PaginatedContestList
}

export async function claimContest<TFieldValues>(
    contestID: number,
    data: TFieldValues,
    router: NextRouter,
    clearAuth: () => void,
    token: string,
) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const endpoint = ENDPOINTS.CLAIM_CONTEST.replace("{id}", contestID.toString())

    const request = new Request(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    })
    const response = await fetch(request)

    if (!response.ok) {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new Error(`There was an error while attempting to claim a contest prize.`)
        }
    }
}
