/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `android` - Android
 * * `ios` - iOS
 * * `unknown` - Unknown
 * @export
 */
export const DevicePlatformEnum = {
    android: 'android',
    ios: 'ios',
    unknown: 'unknown'
} as const;
export type DevicePlatformEnum = typeof DevicePlatformEnum[keyof typeof DevicePlatformEnum];


export function DevicePlatformEnumFromJSON(json: any): DevicePlatformEnum {
    return DevicePlatformEnumFromJSONTyped(json, false);
}

export function DevicePlatformEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevicePlatformEnum {
    return json as DevicePlatformEnum;
}

export function DevicePlatformEnumToJSON(value?: DevicePlatformEnum | null): any {
    return value as any;
}

