import { App } from "@capacitor/app"
import { faCamera, faLocationDot } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings"
import { SetStateAction, useAtom } from "jotai"
import Image from "next/image"
import { Dispatch, useCallback, useEffect } from "react"

import useJune from "../../../hooks/useJune"
import useScanPermissions from "../../../hooks/useScanPermissions"
import Button from "../../buttons/Button/Button"
import SupportButton from "../../buttons/SupportButton/SupportButton"
import { FilterFormToggleRow } from "../../drawers/FilterDrawer/FilterDrawer"
import { skipPermissionsCheckAtom } from "../PermissionsCheck/PermissionsCheck.store"
import styles from "./PermissionsScreen.module.scss"

export default function PermissionsScreen(props: { setRenderScreen: Dispatch<SetStateAction<boolean>> }) {
    const { setRenderScreen } = props

    const analytics = useJune()
    const [, setPermissionsSkipped] = useAtom(skipPermissionsCheckAtom)

    const {
        locationGranted,
        locationDenied,
        scannerSupported,
        scannerGranted,
        scannerDenied,
        checkLocationPermissions,
        checkScanPermissions,
    } = useScanPermissions()

    const openSettings = async () => {
        await NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
        })
    }

    useEffect(() => {
        const listenerPromise = App.addListener("resume", () => {
            console.debug("App resumed. Re-checking scan and location permissions.")
            checkScanPermissions()
            checkLocationPermissions()
        })

        return () => {
            listenerPromise.then((listener) => listener.remove())
        }
    }, [checkScanPermissions, checkLocationPermissions])

    const enableLocationPermission = useCallback(async () => {
        if (locationGranted) {
            console.debug("Location already enabled. Noop.")
            return
        } else {
            if (locationDenied) {
                console.debug("Location denied. Opening settings.")
                openSettings()
                return
            } else {
                console.debug("Requesting location permissions.")
                const success = await checkLocationPermissions(true)

                // Open settings if the request was unsuccessful
                if (!success) {
                    openSettings()
                }
            }
        }
    }, [locationDenied, locationGranted, checkLocationPermissions])

    const enableScannerPermission = useCallback(async () => {
        if (scannerGranted) {
            console.debug("Scanner already enabled. Noop.")
            return
        } else {
            if (scannerDenied) {
                console.debug("Scanner denied. Opening settings.")
                openSettings()
                return
            } else {
                console.debug("Requesting scanner permissions.")
                const success = await checkScanPermissions(true)

                // Open settings if the request was unsuccessful
                if (!success) {
                    openSettings()
                }
            }
        }
    }, [scannerDenied, scannerGranted, checkScanPermissions])

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.hero}>
                    <div className={styles.heroImage}>
                        <Image
                            src="/deal_hero_bogo.svg"
                            width={203}
                            height={116}
                            alt="Deal Hero Illustration: Two celebrating people with a trophy"
                        />
                    </div>
                    <span className={styles.title}>Last Step!</span>
                    <p className={styles.subtitle}>Enable permissions for in-app use</p>
                </div>
                <div className={styles.permissions}>
                    <div className={styles.permission}>
                        <FilterFormToggleRow
                            icon={<FontAwesomeIcon icon={faLocationDot} />}
                            title={
                                <span>
                                    <strong>Location</strong> (required)
                                </span>
                            }
                            subtitle={
                                <p>
                                    Select &ldquo;Only While Using App&rdquo;. We ONLY use your location when you have
                                    the SLOeats app open.
                                </p>
                            }
                            value={locationGranted}
                            onToggle={enableLocationPermission}
                            borderPlacement="top"
                        />
                    </div>
                    <div className={styles.permission}>
                        <FilterFormToggleRow
                            icon={<FontAwesomeIcon icon={faCamera} />}
                            title={
                                <span>
                                    <strong>Camera</strong> (required)
                                </span>
                            }
                            subtitle={
                                <>
                                    <p>
                                        We ONLY use your phone&apos;s camera when you are using the SLOeats app to scan
                                        a QR code.
                                    </p>
                                    {!scannerSupported && (
                                        <p className={styles.errorMessage}>
                                            QR code scanning is not supported on this device. You won&apos;t be able to
                                            redeem deal rewards, but you can still check out the app. Click &quot;Skip
                                            for now&quot; below to continue.
                                        </p>
                                    )}
                                </>
                            }
                            value={scannerGranted}
                            onToggle={enableScannerPermission}
                            borderPlacement="top"
                        />
                    </div>
                </div>
                <Button
                    variant="primary"
                    content={!locationGranted || !scannerGranted ? "Enable Permissions to Continue" : "Continue"}
                    clickHandler={() => {
                        analytics?.track("Permission Check Success")
                        setPermissionsSkipped(true)
                        setRenderScreen(false)
                    }}
                    ignoreDebounce={true}
                    disabled={!locationGranted || !scannerGranted}
                />
                <Button
                    variant="textMuted"
                    content="Skip for now"
                    clickHandler={() => {
                        analytics?.track("Permission Check Skipped")
                        setPermissionsSkipped(true)
                        setRenderScreen(false)
                    }}
                    ignoreDebounce={true}
                />
            </div>
            <SupportButton source="permissions_screen" />
        </div>
    )
}
