/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GiveawayTypeEnum } from './GiveawayTypeEnum';
import {
    GiveawayTypeEnumFromJSON,
    GiveawayTypeEnumFromJSONTyped,
    GiveawayTypeEnumToJSON,
} from './GiveawayTypeEnum';
import type { PromotedDeal } from './PromotedDeal';
import {
    PromotedDealFromJSON,
    PromotedDealFromJSONTyped,
    PromotedDealToJSON,
} from './PromotedDeal';

/**
 * 
 * @export
 * @interface Giveaway
 */
export interface Giveaway {
    /**
     * 
     * @type {number}
     * @memberof Giveaway
     */
    readonly id: number;
    /**
     * 
     * @type {GiveawayTypeEnum}
     * @memberof Giveaway
     */
    readonly type: GiveawayTypeEnum | null;
    /**
     * Whether or not this giveaway should be shown to users after they've seen it once.
     * @type {boolean}
     * @memberof Giveaway
     */
    readonly is_persistent: boolean;
    /**
     * 
     * @type {string}
     * @memberof Giveaway
     */
    readonly title: string;
    /**
     * 
     * @type {string}
     * @memberof Giveaway
     */
    readonly subtitle: string;
    /**
     * 
     * @type {string}
     * @memberof Giveaway
     */
    readonly body: string;
    /**
     * The call to action button text for non-pro users.
     * @type {string}
     * @memberof Giveaway
     */
    readonly cta_text: string;
    /**
     * The page (relative path) the call to action button takes non-pro users on click.
     * @type {string}
     * @memberof Giveaway
     */
    readonly cta_destination: string;
    /**
     * The call to action button text for pro members.
     * @type {string}
     * @memberof Giveaway
     */
    readonly pro_cta_text: string;
    /**
     * The page (relative path) the call to action button takes pro members on click.
     * @type {string}
     * @memberof Giveaway
     */
    readonly pro_cta_destination: string;
    /**
     * 
     * @type {string}
     * @memberof Giveaway
     */
    readonly image: string;
    /**
     * 
     * @type {PromotedDeal}
     * @memberof Giveaway
     */
    deal: PromotedDeal;
    /**
     * 
     * @type {boolean}
     * @memberof Giveaway
     */
    readonly show_splash_screen: boolean;
    /**
     * 
     * @type {string}
     * @memberof Giveaway
     */
    readonly splash_screen_image: string | null;
}

/**
 * Check if a given object implements the Giveaway interface.
 */
export function instanceOfGiveaway(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "is_persistent" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "subtitle" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "cta_text" in value;
    isInstance = isInstance && "cta_destination" in value;
    isInstance = isInstance && "pro_cta_text" in value;
    isInstance = isInstance && "pro_cta_destination" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "deal" in value;
    isInstance = isInstance && "show_splash_screen" in value;
    isInstance = isInstance && "splash_screen_image" in value;

    return isInstance;
}

export function GiveawayFromJSON(json: any): Giveaway {
    return GiveawayFromJSONTyped(json, false);
}

export function GiveawayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Giveaway {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': GiveawayTypeEnumFromJSON(json['type']),
        'is_persistent': json['is_persistent'],
        'title': json['title'],
        'subtitle': json['subtitle'],
        'body': json['body'],
        'cta_text': json['cta_text'],
        'cta_destination': json['cta_destination'],
        'pro_cta_text': json['pro_cta_text'],
        'pro_cta_destination': json['pro_cta_destination'],
        'image': json['image'],
        'deal': PromotedDealFromJSON(json['deal']),
        'show_splash_screen': json['show_splash_screen'],
        'splash_screen_image': json['splash_screen_image'],
    };
}

export function GiveawayToJSON(value?: Giveaway | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deal': PromotedDealToJSON(value.deal),
    };
}

