import { BarcodeScanner, PermissionStatus } from "@capacitor-mlkit/barcode-scanning"
import { Geolocation } from "@capacitor/geolocation"
import { useCallback, useEffect, useState } from "react"

export default function useScanPermissions() {
    const [locationGranted, setLocationGranted] = useState<boolean>(null)
    const [locationDenied, setLocationDenied] = useState<boolean>(null)
    const [scannerSupported, setScannerSupported] = useState<boolean>(null)
    const [scannerDenied, setScannerDenied] = useState<boolean>(null)
    const [scannerGranted, setScannerGranted] = useState<boolean>(null)

    const checkScanPermissions = useCallback(async (request = false) => {
        let permissionStatus: PermissionStatus

        try {
            permissionStatus = await BarcodeScanner.checkPermissions()
            setScannerSupported(true)
        } catch (error) {
            setScannerSupported(false)
            setScannerGranted(false)
            return
        }

        if (request) {
            if (permissionStatus.camera === "prompt") {
                permissionStatus = await BarcodeScanner.requestPermissions()
            }

            if (!["granted", "limited"].includes(permissionStatus.camera)) {
                setScannerGranted(false)

                if (permissionStatus.camera === "denied") {
                    setScannerDenied(true)
                }
                console.error("User denied camera permissions.", permissionStatus.camera)
                return false
            } else {
                setScannerGranted(true)
                return true
            }
        } else {
            setScannerGranted(["granted", "limited"].includes(permissionStatus.camera))
        }
    }, [])

    const checkLocationPermissions = useCallback(async (request = false) => {
        let permissionStatus = await Geolocation.checkPermissions()

        if (request) {
            if (["prompt", "prompt-with-rationale"].includes(permissionStatus.location)) {
                permissionStatus = await Geolocation.requestPermissions({ permissions: ["location"] })
            }

            if (permissionStatus.location !== "granted") {
                setLocationGranted(false)

                if (permissionStatus.location === "denied") {
                    setLocationDenied(true)
                }

                console.error("User denied location permissions.", permissionStatus.location)
                return false
            } else {
                setLocationGranted(true)
                return true
            }
        } else {
            setLocationGranted(permissionStatus.location === "granted")
        }
    }, [])

    useEffect(() => {
        // Do an initial permissions check
        checkLocationPermissions()
        checkScanPermissions()
    }, [checkLocationPermissions, checkScanPermissions])

    return {
        locationGranted,
        locationDenied,
        scannerSupported,
        scannerGranted,
        scannerDenied,
        checkScanPermissions,
        checkLocationPermissions,
    }
}
