/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealRewardReelBusiness } from './DealRewardReelBusiness';
import {
    DealRewardReelBusinessFromJSON,
    DealRewardReelBusinessFromJSONTyped,
    DealRewardReelBusinessToJSON,
} from './DealRewardReelBusiness';
import type { ReelDeal } from './ReelDeal';
import {
    ReelDealFromJSON,
    ReelDealFromJSONTyped,
    ReelDealToJSON,
} from './ReelDeal';

/**
 * 
 * @export
 * @interface DealRewardReel
 */
export interface DealRewardReel {
    /**
     * 
     * @type {number}
     * @memberof DealRewardReel
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DealRewardReel
     */
    readonly name: string;
    /**
     * 
     * @type {DealRewardReelBusiness}
     * @memberof DealRewardReel
     */
    business: DealRewardReelBusiness;
    /**
     * 
     * @type {Array<ReelDeal>}
     * @memberof DealRewardReel
     */
    readonly deals: Array<ReelDeal>;
    /**
     * 
     * @type {string}
     * @memberof DealRewardReel
     */
    readonly reel_id: string;
}

/**
 * Check if a given object implements the DealRewardReel interface.
 */
export function instanceOfDealRewardReel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "business" in value;
    isInstance = isInstance && "deals" in value;
    isInstance = isInstance && "reel_id" in value;

    return isInstance;
}

export function DealRewardReelFromJSON(json: any): DealRewardReel {
    return DealRewardReelFromJSONTyped(json, false);
}

export function DealRewardReelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealRewardReel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'business': DealRewardReelBusinessFromJSON(json['business']),
        'deals': ((json['deals'] as Array<any>).map(ReelDealFromJSON)),
        'reel_id': json['reel_id'],
    };
}

export function DealRewardReelToJSON(value?: DealRewardReel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'business': DealRewardReelBusinessToJSON(value.business),
    };
}

