/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `bulk` - Bulk
 * * `individual` - Individual
 * @export
 */
export const PaymentTypeEnum = {
    bulk: 'bulk',
    individual: 'individual'
} as const;
export type PaymentTypeEnum = typeof PaymentTypeEnum[keyof typeof PaymentTypeEnum];


export function PaymentTypeEnumFromJSON(json: any): PaymentTypeEnum {
    return PaymentTypeEnumFromJSONTyped(json, false);
}

export function PaymentTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTypeEnum {
    return json as PaymentTypeEnum;
}

export function PaymentTypeEnumToJSON(value?: PaymentTypeEnum | null): any {
    return value as any;
}

