/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FAQ,
} from '../models';
import {
    FAQFromJSON,
    FAQToJSON,
} from '../models';

export interface FaqsListRequest {
    location?: FaqsListLocationEnum;
    show_on_conversion_page?: boolean;
}

/**
 * FaqsApi - interface
 * 
 * @export
 * @interface FaqsApiInterface
 */
export interface FaqsApiInterface {
    /**
     * 
     * @param {1 | 2} [location] * &#x60;1&#x60; - App * &#x60;2&#x60; - Portal
     * @param {boolean} [show_on_conversion_page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApiInterface
     */
    faqsListRaw(requestParameters: FaqsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FAQ>>>;

    /**
     */
    faqsList(requestParameters: FaqsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FAQ>>;

}

/**
 * 
 */
export class FaqsApi extends runtime.BaseAPI implements FaqsApiInterface {

    /**
     */
    async faqsListRaw(requestParameters: FaqsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FAQ>>> {
        const queryParameters: any = {};

        if (requestParameters.location !== undefined) {
            queryParameters['location'] = requestParameters.location;
        }

        if (requestParameters.show_on_conversion_page !== undefined) {
            queryParameters['show_on_conversion_page'] = requestParameters.show_on_conversion_page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/faqs/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FAQFromJSON));
    }

    /**
     */
    async faqsList(requestParameters: FaqsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FAQ>> {
        const response = await this.faqsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const FaqsListLocationEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type FaqsListLocationEnum = typeof FaqsListLocationEnum[keyof typeof FaqsListLocationEnum];
