/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FAQ
 */
export interface FAQ {
    /**
     * 
     * @type {string}
     * @memberof FAQ
     */
    readonly question: string;
    /**
     * 
     * @type {string}
     * @memberof FAQ
     */
    readonly answer: string;
    /**
     * 
     * @type {number}
     * @memberof FAQ
     */
    readonly index: number;
}

/**
 * Check if a given object implements the FAQ interface.
 */
export function instanceOfFAQ(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "answer" in value;
    isInstance = isInstance && "index" in value;

    return isInstance;
}

export function FAQFromJSON(json: any): FAQ {
    return FAQFromJSONTyped(json, false);
}

export function FAQFromJSONTyped(json: any, ignoreDiscriminator: boolean): FAQ {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'question': json['question'],
        'answer': json['answer'],
        'index': json['index'],
    };
}

export function FAQToJSON(value?: FAQ | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

