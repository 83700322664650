import { NextRouter, useRouter } from "next/router"
import { useEffect } from "react"

import { PAGE_ROUTES } from "../../constants/routes"
import useUser from "../../hooks/User/useUser"
import { UserResult } from "../../hooks/User/useUser.types"
import { CleanUser } from "../../types/User"
import Spinner from "../loading/Spinner/Spinner"

export function handleUnauthenticatedUser(router: NextRouter, clearAuth: UserResult["clearAuth"]) {
    clearAuth()
    router.replace(`${PAGE_ROUTES.AUTH_HOME}?next=${router.asPath}`)
}

export function redirectUnregisteredUser(user: CleanUser, router: NextRouter) {
    let redirected = false

    // User isn't registered yet. Redirect to registration page if we're not already there
    if (`${router.pathname}/` !== PAGE_ROUTES.AUTH_REGISTER && !user.registered) {
        router.replace(PAGE_ROUTES.AUTH_REGISTER)
        redirected = true
    }

    return redirected
}

export const UNAUTHENTICATED_USER_MESSAGE = "Looks like you've been logged out. Redirecting..."

export default function AuthCheck(props: { children: JSX.Element }): JSX.Element {
    const { children } = props
    const router = useRouter()
    const { user, userLoading, clearAuth } = useUser()

    useEffect(() => {
        if (!userLoading) {
            if (!user) {
                handleUnauthenticatedUser(router, clearAuth)
            } else {
                // Redirect the user to registration if they haven't registered yet.
                redirectUnregisteredUser(user, router)
            }
        }
    }, [user, userLoading, router, clearAuth])

    if (userLoading || !user) {
        return (
            <main>
                <Spinner />
            </main>
        )
    } else {
        return children
    }
}
