import { faComments } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"

import useJune from "../../../hooks/useJune"
import Button from "../Button/Button"
import styles from "./SupportButton.module.scss"
import { SupportButtonProps } from "./SupportButton.types"

export default function SupportButton(props: SupportButtonProps) {
    const { source, variant = "secondaryWhite", ctaText = "Need help?", inline = false } = props

    const analytics = useJune()

    return (
        <div className={clsx(styles.container, { [styles.inline]: inline })}>
            <Button
                variant={variant}
                clickHandler={() => {
                    analytics?.track("Open Support Chat", { source: source })
                }}
                content={
                    <>
                        <FontAwesomeIcon icon={faComments} />
                        {ctaText}
                    </>
                }
                extraClasses={["SupportButton"]}
            />
        </div>
    )
}
