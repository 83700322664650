/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BusinessSettings
 */
export interface BusinessSettings {
    /**
     * 
     * @type {string}
     * @memberof BusinessSettings
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSettings
     */
    point_of_contact_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSettings
     */
    point_of_contact_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSettings
     */
    point_of_contact_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSettings
     */
    point_of_contact_email?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSettings
     */
    portal_admin?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessSettings
     */
    portal_managers?: Array<string>;
}

/**
 * Check if a given object implements the BusinessSettings interface.
 */
export function instanceOfBusinessSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function BusinessSettingsFromJSON(json: any): BusinessSettings {
    return BusinessSettingsFromJSONTyped(json, false);
}

export function BusinessSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'point_of_contact_first_name': !exists(json, 'point_of_contact_first_name') ? undefined : json['point_of_contact_first_name'],
        'point_of_contact_last_name': !exists(json, 'point_of_contact_last_name') ? undefined : json['point_of_contact_last_name'],
        'point_of_contact_phone': !exists(json, 'point_of_contact_phone') ? undefined : json['point_of_contact_phone'],
        'point_of_contact_email': !exists(json, 'point_of_contact_email') ? undefined : json['point_of_contact_email'],
        'portal_admin': !exists(json, 'portal_admin') ? undefined : json['portal_admin'],
        'portal_managers': !exists(json, 'portal_managers') ? undefined : json['portal_managers'],
    };
}

export function BusinessSettingsToJSON(value?: BusinessSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'point_of_contact_first_name': value.point_of_contact_first_name,
        'point_of_contact_last_name': value.point_of_contact_last_name,
        'point_of_contact_phone': value.point_of_contact_phone,
        'point_of_contact_email': value.point_of_contact_email,
        'portal_admin': value.portal_admin,
        'portal_managers': value.portal_managers,
    };
}

