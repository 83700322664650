/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PromotedDeal
 */
export interface PromotedDeal {
    /**
     * 
     * @type {number}
     * @memberof PromotedDeal
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly business_id: string;
    /**
     * 
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly business_name: string;
    /**
     * 
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly promotion_name: string;
    /**
     * The image to show when promoting this deal. Shown in notifications, etc.
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly promotion_image: string | null;
    /**
     * The wide version of the image to show when promoting this deal. Shown in example order cards, etc.
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly promotion_image_wide: string | null;
    /**
     * Show an example order for this deal throughout the app.
     * @type {boolean}
     * @memberof PromotedDeal
     */
    readonly show_example: boolean;
    /**
     * The example order for this deal. (e.g. '2 Entrees'))
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly example_order: string;
    /**
     * The example order's total price at the business using SLOeats.
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly example_order_price_sloeats: string | null;
    /**
     * The example order's total price at the business without using SLOeats.
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly example_order_price_business: string | null;
    /**
     * The example order's total price using DoorDash.
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly example_order_price_doordash: string | null;
    /**
     * 
     * @type {string}
     * @memberof PromotedDeal
     */
    readonly example_order_breakdown: string;
}

/**
 * Check if a given object implements the PromotedDeal interface.
 */
export function instanceOfPromotedDeal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "business_id" in value;
    isInstance = isInstance && "business_name" in value;
    isInstance = isInstance && "promotion_name" in value;
    isInstance = isInstance && "promotion_image" in value;
    isInstance = isInstance && "promotion_image_wide" in value;
    isInstance = isInstance && "show_example" in value;
    isInstance = isInstance && "example_order" in value;
    isInstance = isInstance && "example_order_price_sloeats" in value;
    isInstance = isInstance && "example_order_price_business" in value;
    isInstance = isInstance && "example_order_price_doordash" in value;
    isInstance = isInstance && "example_order_breakdown" in value;

    return isInstance;
}

export function PromotedDealFromJSON(json: any): PromotedDeal {
    return PromotedDealFromJSONTyped(json, false);
}

export function PromotedDealFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromotedDeal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'business_id': json['business_id'],
        'business_name': json['business_name'],
        'promotion_name': json['promotion_name'],
        'promotion_image': json['promotion_image'],
        'promotion_image_wide': json['promotion_image_wide'],
        'show_example': json['show_example'],
        'example_order': json['example_order'],
        'example_order_price_sloeats': json['example_order_price_sloeats'],
        'example_order_price_business': json['example_order_price_business'],
        'example_order_price_doordash': json['example_order_price_doordash'],
        'example_order_breakdown': json['example_order_breakdown'],
    };
}

export function PromotedDealToJSON(value?: PromotedDeal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

