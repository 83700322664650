/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OTPAuthenticate,
  OTPAuthenticateResponse200,
  OTPRequest,
  OTPRequestResponse200,
} from '../models';
import {
    OTPAuthenticateFromJSON,
    OTPAuthenticateToJSON,
    OTPAuthenticateResponse200FromJSON,
    OTPAuthenticateResponse200ToJSON,
    OTPRequestFromJSON,
    OTPRequestToJSON,
    OTPRequestResponse200FromJSON,
    OTPRequestResponse200ToJSON,
} from '../models';

export interface AuthSmsCreateRequest {
    OTPRequest: OTPRequest;
}

export interface AuthTokenCreateRequest {
    OTPAuthenticate: OTPAuthenticate;
}

/**
 * AuthApi - interface
 * 
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authLogoutCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    authLogoutCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Request a new one time password over sms.
     * @param {OTPRequest} OTPRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authSmsCreateRaw(requestParameters: AuthSmsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OTPRequestResponse200>>;

    /**
     * Request a new one time password over sms.
     */
    authSmsCreate(requestParameters: AuthSmsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OTPRequestResponse200>;

    /**
     * Submit a one time password to retrieve an authentication token.
     * @param {OTPAuthenticate} OTPAuthenticate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authTokenCreateRaw(requestParameters: AuthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OTPAuthenticateResponse200>>;

    /**
     * Submit a one time password to retrieve an authentication token.
     */
    authTokenCreate(requestParameters: AuthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OTPAuthenticateResponse200>;

}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {

    /**
     */
    async authLogoutCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/auth/logout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authLogoutCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authLogoutCreateRaw(initOverrides);
    }

    /**
     * Request a new one time password over sms.
     */
    async authSmsCreateRaw(requestParameters: AuthSmsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OTPRequestResponse200>> {
        if (requestParameters.OTPRequest === null || requestParameters.OTPRequest === undefined) {
            throw new runtime.RequiredError('OTPRequest','Required parameter requestParameters.OTPRequest was null or undefined when calling authSmsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/auth/sms/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OTPRequestToJSON(requestParameters.OTPRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OTPRequestResponse200FromJSON(jsonValue));
    }

    /**
     * Request a new one time password over sms.
     */
    async authSmsCreate(requestParameters: AuthSmsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OTPRequestResponse200> {
        const response = await this.authSmsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit a one time password to retrieve an authentication token.
     */
    async authTokenCreateRaw(requestParameters: AuthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OTPAuthenticateResponse200>> {
        if (requestParameters.OTPAuthenticate === null || requestParameters.OTPAuthenticate === undefined) {
            throw new runtime.RequiredError('OTPAuthenticate','Required parameter requestParameters.OTPAuthenticate was null or undefined when calling authTokenCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/auth/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OTPAuthenticateToJSON(requestParameters.OTPAuthenticate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OTPAuthenticateResponse200FromJSON(jsonValue));
    }

    /**
     * Submit a one time password to retrieve an authentication token.
     */
    async authTokenCreate(requestParameters: AuthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OTPAuthenticateResponse200> {
        const response = await this.authTokenCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
