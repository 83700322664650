/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReferralReceiver } from './ReferralReceiver';
import {
    ReferralReceiverFromJSON,
    ReferralReceiverFromJSONTyped,
    ReferralReceiverToJSON,
} from './ReferralReceiver';

/**
 * 
 * @export
 * @interface Referral
 */
export interface Referral {
    /**
     * 
     * @type {number}
     * @memberof Referral
     */
    readonly id: number;
    /**
     * 
     * @type {ReferralReceiver}
     * @memberof Referral
     */
    receiver: ReferralReceiver;
    /**
     * 
     * @type {Date}
     * @memberof Referral
     */
    readonly trial_started_at: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Referral
     */
    readonly converted_at: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Referral
     */
    readonly sender_reward_credit_days: number | null;
}

/**
 * Check if a given object implements the Referral interface.
 */
export function instanceOfReferral(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "receiver" in value;
    isInstance = isInstance && "trial_started_at" in value;
    isInstance = isInstance && "converted_at" in value;
    isInstance = isInstance && "sender_reward_credit_days" in value;

    return isInstance;
}

export function ReferralFromJSON(json: any): Referral {
    return ReferralFromJSONTyped(json, false);
}

export function ReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): Referral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'receiver': ReferralReceiverFromJSON(json['receiver']),
        'trial_started_at': (json['trial_started_at'] === null ? null : new Date(json['trial_started_at'])),
        'converted_at': (json['converted_at'] === null ? null : new Date(json['converted_at'])),
        'sender_reward_credit_days': json['sender_reward_credit_days'],
    };
}

export function ReferralToJSON(value?: Referral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'receiver': ReferralReceiverToJSON(value.receiver),
    };
}

