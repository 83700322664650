/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesReportTimeseries
 */
export interface SalesReportTimeseries {
    /**
     * 
     * @type {Date}
     * @memberof SalesReportTimeseries
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof SalesReportTimeseries
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof SalesReportTimeseries
     */
    estimated_sales: string;
    /**
     * 
     * @type {string}
     * @memberof SalesReportTimeseries
     */
    cost: string;
    /**
     * 
     * @type {string}
     * @memberof SalesReportTimeseries
     */
    commission: string;
    /**
     * 
     * @type {string}
     * @memberof SalesReportTimeseries
     */
    estimated_net: string;
}

/**
 * Check if a given object implements the SalesReportTimeseries interface.
 */
export function instanceOfSalesReportTimeseries(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "estimated_sales" in value;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "commission" in value;
    isInstance = isInstance && "estimated_net" in value;

    return isInstance;
}

export function SalesReportTimeseriesFromJSON(json: any): SalesReportTimeseries {
    return SalesReportTimeseriesFromJSONTyped(json, false);
}

export function SalesReportTimeseriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesReportTimeseries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'count': json['count'],
        'estimated_sales': json['estimated_sales'],
        'cost': json['cost'],
        'commission': json['commission'],
        'estimated_net': json['estimated_net'],
    };
}

export function SalesReportTimeseriesToJSON(value?: SalesReportTimeseries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        'count': value.count,
        'estimated_sales': value.estimated_sales,
        'cost': value.cost,
        'commission': value.commission,
        'estimated_net': value.estimated_net,
    };
}

