/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginatedRedemptionListList,
  Redemption,
} from '../models';
import {
    PaginatedRedemptionListListFromJSON,
    PaginatedRedemptionListListToJSON,
    RedemptionFromJSON,
    RedemptionToJSON,
} from '../models';

export interface RedemptionsCreateRequest {
    Redemption: Redemption;
}

export interface RedemptionsListRequest {
    cursor?: string;
    ordering?: string;
}

export interface RedemptionsRetrieveRequest {
    id: number;
}

export interface RedemptionsValidateCreateRequest {
    Redemption: Redemption;
}

/**
 * RedemptionsApi - interface
 * 
 * @export
 * @interface RedemptionsApiInterface
 */
export interface RedemptionsApiInterface {
    /**
     * 
     * @param {Redemption} Redemption 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedemptionsApiInterface
     */
    redemptionsCreateRaw(requestParameters: RedemptionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Redemption>>;

    /**
     */
    redemptionsCreate(requestParameters: RedemptionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Redemption>;

    /**
     * 
     * @param {string} [cursor] The pagination cursor value.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedemptionsApiInterface
     */
    redemptionsListRaw(requestParameters: RedemptionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRedemptionListList>>;

    /**
     */
    redemptionsList(requestParameters: RedemptionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRedemptionListList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this redemption.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedemptionsApiInterface
     */
    redemptionsRetrieveRaw(requestParameters: RedemptionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Redemption>>;

    /**
     */
    redemptionsRetrieve(requestParameters: RedemptionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Redemption>;

    /**
     * 
     * @param {Redemption} Redemption 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedemptionsApiInterface
     */
    redemptionsValidateCreateRaw(requestParameters: RedemptionsValidateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    redemptionsValidateCreate(requestParameters: RedemptionsValidateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class RedemptionsApi extends runtime.BaseAPI implements RedemptionsApiInterface {

    /**
     */
    async redemptionsCreateRaw(requestParameters: RedemptionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Redemption>> {
        if (requestParameters.Redemption === null || requestParameters.Redemption === undefined) {
            throw new runtime.RequiredError('Redemption','Required parameter requestParameters.Redemption was null or undefined when calling redemptionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/redemptions/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RedemptionToJSON(requestParameters.Redemption),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedemptionFromJSON(jsonValue));
    }

    /**
     */
    async redemptionsCreate(requestParameters: RedemptionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Redemption> {
        const response = await this.redemptionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async redemptionsListRaw(requestParameters: RedemptionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRedemptionListList>> {
        const queryParameters: any = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/redemptions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRedemptionListListFromJSON(jsonValue));
    }

    /**
     */
    async redemptionsList(requestParameters: RedemptionsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRedemptionListList> {
        const response = await this.redemptionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async redemptionsRetrieveRaw(requestParameters: RedemptionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Redemption>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling redemptionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/redemptions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedemptionFromJSON(jsonValue));
    }

    /**
     */
    async redemptionsRetrieve(requestParameters: RedemptionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Redemption> {
        const response = await this.redemptionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async redemptionsValidateCreateRaw(requestParameters: RedemptionsValidateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.Redemption === null || requestParameters.Redemption === undefined) {
            throw new runtime.RequiredError('Redemption','Required parameter requestParameters.Redemption was null or undefined when calling redemptionsValidateCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/redemptions/validate/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RedemptionToJSON(requestParameters.Redemption),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async redemptionsValidateCreate(requestParameters: RedemptionsValidateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.redemptionsValidateCreateRaw(requestParameters, initOverrides);
    }

}
