/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Type97cEnum } from './Type97cEnum';
import {
    Type97cEnumFromJSON,
    Type97cEnumFromJSONTyped,
    Type97cEnumToJSON,
} from './Type97cEnum';

/**
 * 
 * @export
 * @interface RedemptionDeal
 */
export interface RedemptionDeal {
    /**
     * 
     * @type {number}
     * @memberof RedemptionDeal
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof RedemptionDeal
     */
    readonly name: string;
    /**
     * 
     * @type {Type97cEnum}
     * @memberof RedemptionDeal
     */
    readonly type: Type97cEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof RedemptionDeal
     */
    readonly is_archived: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedemptionDeal
     */
    readonly is_active: boolean;
    /**
     * Determines when this deal will be active next, based on the business's timezone.
     * @type {string}
     * @memberof RedemptionDeal
     */
    readonly next_active: string | null;
}

/**
 * Check if a given object implements the RedemptionDeal interface.
 */
export function instanceOfRedemptionDeal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "is_archived" in value;
    isInstance = isInstance && "is_active" in value;
    isInstance = isInstance && "next_active" in value;

    return isInstance;
}

export function RedemptionDealFromJSON(json: any): RedemptionDeal {
    return RedemptionDealFromJSONTyped(json, false);
}

export function RedemptionDealFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedemptionDeal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': Type97cEnumFromJSON(json['type']),
        'is_archived': json['is_archived'],
        'is_active': json['is_active'],
        'next_active': json['next_active'],
    };
}

export function RedemptionDealToJSON(value?: RedemptionDeal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

