/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReasonEnum } from './ReasonEnum';
import {
    ReasonEnumFromJSON,
    ReasonEnumFromJSONTyped,
    ReasonEnumToJSON,
} from './ReasonEnum';

/**
 * 
 * @export
 * @interface Cancellation
 */
export interface Cancellation {
    /**
     * 
     * @type {ReasonEnum}
     * @memberof Cancellation
     */
    reason: ReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof Cancellation
     */
    explanation?: string;
}

/**
 * Check if a given object implements the Cancellation interface.
 */
export function instanceOfCancellation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function CancellationFromJSON(json: any): Cancellation {
    return CancellationFromJSONTyped(json, false);
}

export function CancellationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cancellation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': ReasonEnumFromJSON(json['reason']),
        'explanation': !exists(json, 'explanation') ? undefined : json['explanation'],
    };
}

export function CancellationToJSON(value?: Cancellation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': ReasonEnumToJSON(value.reason),
        'explanation': value.explanation,
    };
}

