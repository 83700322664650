import { User } from "../../sdk"
import { CleanFilters, RawFilters } from "../types/Filters"
import { CleanUser } from "../types/User"

export function cleanUserObject(user: User, token: string): CleanUser {
    return {
        ...user,
        token,
        favorite_businesses: new Set<string>(user.favorite_businesses),
        deal_requests: new Set<string>(user.deal_requests),
        favorite_menu_items: new Set<number>(user.favorite_menu_items),
    }
}

export function cleanFilterDict(filters?: RawFilters): CleanFilters {
    return {
        isPro: filters?.is_pro?.[0] || false,
        isFavorite: filters?.is_favorite?.[0] || false,
        isOpen: filters?.is_open?.[0] || false,
        priceLevels: new Set<string>(filters?.price_level || []),
        cuisines: new Set<string>(filters?.cuisines || []),
        diets: new Set<string>(filters?.diets || []),
        amenities: new Set<string>(filters?.amenities || []),
    }
}

export function cleanTime(time: string): string {
    return time.replaceAll(":00", "").replaceAll(" AM", "am").replaceAll(" PM", "pm")
}

export function stripCurrency(time: string): string {
    return time.replaceAll(".00", "").replaceAll(",00", "")
}
