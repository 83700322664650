/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `deal_promotion` - Deal Promotion
 * * `giveaway` - Giveaway
 * * `new_feature` - New Feature
 * * `other` - Other
 * @export
 */
export const GiveawayTypeEnum = {
    deal_promotion: 'deal_promotion',
    giveaway: 'giveaway',
    new_feature: 'new_feature',
    other: 'other'
} as const;
export type GiveawayTypeEnum = typeof GiveawayTypeEnum[keyof typeof GiveawayTypeEnum];


export function GiveawayTypeEnumFromJSON(json: any): GiveawayTypeEnum {
    return GiveawayTypeEnumFromJSONTyped(json, false);
}

export function GiveawayTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GiveawayTypeEnum {
    return json as GiveawayTypeEnum;
}

export function GiveawayTypeEnumToJSON(value?: GiveawayTypeEnum | null): any {
    return value as any;
}

