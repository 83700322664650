/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MapCenterPoint
 */
export interface MapCenterPoint {
    /**
     * 
     * @type {string}
     * @memberof MapCenterPoint
     */
    type?: MapCenterPointTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof MapCenterPoint
     */
    coordinates?: Array<number>;
}


/**
 * @export
 */
export const MapCenterPointTypeEnum = {
    Point: 'Point'
} as const;
export type MapCenterPointTypeEnum = typeof MapCenterPointTypeEnum[keyof typeof MapCenterPointTypeEnum];


/**
 * Check if a given object implements the MapCenterPoint interface.
 */
export function instanceOfMapCenterPoint(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MapCenterPointFromJSON(json: any): MapCenterPoint {
    return MapCenterPointFromJSONTyped(json, false);
}

export function MapCenterPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapCenterPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
    };
}

export function MapCenterPointToJSON(value?: MapCenterPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'coordinates': value.coordinates,
    };
}

