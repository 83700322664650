/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RedemptionGeoPoint } from './RedemptionGeoPoint';
import {
    RedemptionGeoPointFromJSON,
    RedemptionGeoPointFromJSONTyped,
    RedemptionGeoPointToJSON,
} from './RedemptionGeoPoint';

/**
 * 
 * @export
 * @interface Redemption
 */
export interface Redemption {
    /**
     * 
     * @type {number}
     * @memberof Redemption
     */
    deal: number;
    /**
     * 
     * @type {number}
     * @memberof Redemption
     */
    reward: number;
    /**
     * 
     * @type {string}
     * @memberof Redemption
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof Redemption
     */
    readonly first_contest_entry: boolean;
    /**
     * 
     * @type {RedemptionGeoPoint}
     * @memberof Redemption
     */
    geo_point?: RedemptionGeoPoint | null;
    /**
     * 
     * @type {number}
     * @memberof Redemption
     */
    geo_precision?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Redemption
     */
    geo_timestamp?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Redemption
     */
    inaccurate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Redemption
     */
    inaccurate_reason?: string;
    /**
     * 
     * @type {any}
     * @memberof Redemption
     */
    inaccurate_metadata?: any | null;
    /**
     * 
     * @type {Date}
     * @memberof Redemption
     */
    readonly created_at: Date;
}

/**
 * Check if a given object implements the Redemption interface.
 */
export function instanceOfRedemption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deal" in value;
    isInstance = isInstance && "reward" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "first_contest_entry" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function RedemptionFromJSON(json: any): Redemption {
    return RedemptionFromJSONTyped(json, false);
}

export function RedemptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Redemption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deal': json['deal'],
        'reward': json['reward'],
        'key': json['key'],
        'first_contest_entry': json['first_contest_entry'],
        'geo_point': !exists(json, 'geo_point') ? undefined : RedemptionGeoPointFromJSON(json['geo_point']),
        'geo_precision': !exists(json, 'geo_precision') ? undefined : json['geo_precision'],
        'geo_timestamp': !exists(json, 'geo_timestamp') ? undefined : (json['geo_timestamp'] === null ? null : new Date(json['geo_timestamp'])),
        'inaccurate': !exists(json, 'inaccurate') ? undefined : json['inaccurate'],
        'inaccurate_reason': !exists(json, 'inaccurate_reason') ? undefined : json['inaccurate_reason'],
        'inaccurate_metadata': !exists(json, 'inaccurate_metadata') ? undefined : json['inaccurate_metadata'],
        'created_at': (new Date(json['created_at'])),
    };
}

export function RedemptionToJSON(value?: Redemption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deal': value.deal,
        'reward': value.reward,
        'key': value.key,
        'geo_point': RedemptionGeoPointToJSON(value.geo_point),
        'geo_precision': value.geo_precision,
        'geo_timestamp': value.geo_timestamp === undefined ? undefined : (value.geo_timestamp === null ? null : value.geo_timestamp.toISOString()),
        'inaccurate': value.inaccurate,
        'inaccurate_reason': value.inaccurate_reason,
        'inaccurate_metadata': value.inaccurate_metadata,
    };
}

