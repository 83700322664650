/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TestimonialUser
 */
export interface TestimonialUser {
    /**
     * 
     * @type {string}
     * @memberof TestimonialUser
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof TestimonialUser
     */
    readonly first_name: string;
    /**
     * 
     * @type {string}
     * @memberof TestimonialUser
     */
    readonly last_name: string;
    /**
     * 
     * @type {string}
     * @memberof TestimonialUser
     */
    readonly short_name: string;
    /**
     * 
     * @type {string}
     * @memberof TestimonialUser
     */
    readonly avatar_url: string;
}

/**
 * Check if a given object implements the TestimonialUser interface.
 */
export function instanceOfTestimonialUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "short_name" in value;
    isInstance = isInstance && "avatar_url" in value;

    return isInstance;
}

export function TestimonialUserFromJSON(json: any): TestimonialUser {
    return TestimonialUserFromJSONTyped(json, false);
}

export function TestimonialUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestimonialUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'short_name': json['short_name'],
        'avatar_url': json['avatar_url'],
    };
}

export function TestimonialUserToJSON(value?: TestimonialUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

