/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Cancellation,
} from '../models';
import {
    CancellationFromJSON,
    CancellationToJSON,
} from '../models';

export interface CancellationsCreateRequest {
    Cancellation: Cancellation;
}

/**
 * CancellationsApi - interface
 * 
 * @export
 * @interface CancellationsApiInterface
 */
export interface CancellationsApiInterface {
    /**
     * 
     * @param {Cancellation} Cancellation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CancellationsApiInterface
     */
    cancellationsCreateRaw(requestParameters: CancellationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cancellation>>;

    /**
     */
    cancellationsCreate(requestParameters: CancellationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cancellation>;

}

/**
 * 
 */
export class CancellationsApi extends runtime.BaseAPI implements CancellationsApiInterface {

    /**
     */
    async cancellationsCreateRaw(requestParameters: CancellationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cancellation>> {
        if (requestParameters.Cancellation === null || requestParameters.Cancellation === undefined) {
            throw new runtime.RequiredError('Cancellation','Required parameter requestParameters.Cancellation was null or undefined when calling cancellationsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/cancellations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancellationToJSON(requestParameters.Cancellation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CancellationFromJSON(jsonValue));
    }

    /**
     */
    async cancellationsCreate(requestParameters: CancellationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cancellation> {
        const response = await this.cancellationsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
