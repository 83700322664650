/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MenuCategory,
  MenuItem,
  MenuItemRating,
  PaginatedMenuItemListList,
} from '../models';
import {
    MenuCategoryFromJSON,
    MenuCategoryToJSON,
    MenuItemFromJSON,
    MenuItemToJSON,
    MenuItemRatingFromJSON,
    MenuItemRatingToJSON,
    PaginatedMenuItemListListFromJSON,
    PaginatedMenuItemListListToJSON,
} from '../models';

export interface MenusCategoriesListRequest {
    business_id: string;
}

export interface MenusItemsFavoriteCreateRequest {
    business_id: string;
    id: number;
}

export interface MenusItemsListRequest {
    business_id: string;
    category?: number;
    is_favorite?: boolean;
    limit?: number;
    offset?: number;
    ordering?: string;
}

export interface MenusItemsRateCreateRequest {
    business_id: string;
    id: number;
    MenuItemRating: MenuItemRating;
}

export interface MenusItemsRetrieveRequest {
    business_id: string;
    id: number;
}

export interface MenusItemsUnfavoriteCreateRequest {
    business_id: string;
    id: number;
}

/**
 * MenusApi - interface
 * 
 * @export
 * @interface MenusApiInterface
 */
export interface MenusApiInterface {
    /**
     * 
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    menusCategoriesListRaw(requestParameters: MenusCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MenuCategory>>>;

    /**
     */
    menusCategoriesList(requestParameters: MenusCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MenuCategory>>;

    /**
     * 
     * @param {string} business_id 
     * @param {number} id A unique integer value identifying this Menu Item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    menusItemsFavoriteCreateRaw(requestParameters: MenusItemsFavoriteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    menusItemsFavoriteCreate(requestParameters: MenusItemsFavoriteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} business_id 
     * @param {number} [category] 
     * @param {boolean} [is_favorite] Is Favorite
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    menusItemsListRaw(requestParameters: MenusItemsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMenuItemListList>>;

    /**
     */
    menusItemsList(requestParameters: MenusItemsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMenuItemListList>;

    /**
     * 
     * @param {string} business_id 
     * @param {number} id A unique integer value identifying this Menu Item.
     * @param {MenuItemRating} MenuItemRating 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    menusItemsRateCreateRaw(requestParameters: MenusItemsRateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    menusItemsRateCreate(requestParameters: MenusItemsRateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} business_id 
     * @param {number} id A unique integer value identifying this Menu Item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    menusItemsRetrieveRaw(requestParameters: MenusItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuItem>>;

    /**
     */
    menusItemsRetrieve(requestParameters: MenusItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuItem>;

    /**
     * 
     * @param {string} business_id 
     * @param {number} id A unique integer value identifying this Menu Item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenusApiInterface
     */
    menusItemsUnfavoriteCreateRaw(requestParameters: MenusItemsUnfavoriteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    menusItemsUnfavoriteCreate(requestParameters: MenusItemsUnfavoriteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class MenusApi extends runtime.BaseAPI implements MenusApiInterface {

    /**
     */
    async menusCategoriesListRaw(requestParameters: MenusCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MenuCategory>>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling menusCategoriesList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/menus/{business_id}/categories/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MenuCategoryFromJSON));
    }

    /**
     */
    async menusCategoriesList(requestParameters: MenusCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MenuCategory>> {
        const response = await this.menusCategoriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async menusItemsFavoriteCreateRaw(requestParameters: MenusItemsFavoriteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling menusItemsFavoriteCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling menusItemsFavoriteCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/menus/{business_id}/items/{id}/favorite/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async menusItemsFavoriteCreate(requestParameters: MenusItemsFavoriteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.menusItemsFavoriteCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async menusItemsListRaw(requestParameters: MenusItemsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMenuItemListList>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling menusItemsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.is_favorite !== undefined) {
            queryParameters['is_favorite'] = requestParameters.is_favorite;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/menus/{business_id}/items/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMenuItemListListFromJSON(jsonValue));
    }

    /**
     */
    async menusItemsList(requestParameters: MenusItemsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMenuItemListList> {
        const response = await this.menusItemsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async menusItemsRateCreateRaw(requestParameters: MenusItemsRateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling menusItemsRateCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling menusItemsRateCreate.');
        }

        if (requestParameters.MenuItemRating === null || requestParameters.MenuItemRating === undefined) {
            throw new runtime.RequiredError('MenuItemRating','Required parameter requestParameters.MenuItemRating was null or undefined when calling menusItemsRateCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/menus/{business_id}/items/{id}/rate/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MenuItemRatingToJSON(requestParameters.MenuItemRating),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async menusItemsRateCreate(requestParameters: MenusItemsRateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.menusItemsRateCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async menusItemsRetrieveRaw(requestParameters: MenusItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuItem>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling menusItemsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling menusItemsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/menus/{business_id}/items/{id}/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuItemFromJSON(jsonValue));
    }

    /**
     */
    async menusItemsRetrieve(requestParameters: MenusItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuItem> {
        const response = await this.menusItemsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async menusItemsUnfavoriteCreateRaw(requestParameters: MenusItemsUnfavoriteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling menusItemsUnfavoriteCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling menusItemsUnfavoriteCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/menus/{business_id}/items/{id}/unfavorite/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async menusItemsUnfavoriteCreate(requestParameters: MenusItemsUnfavoriteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.menusItemsUnfavoriteCreateRaw(requestParameters, initOverrides);
    }

}
