/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContestClaim
 */
export interface ContestClaim {
    /**
     * 
     * @type {boolean}
     * @memberof ContestClaim
     */
    readonly is_claimed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContestClaim
     */
    claimed_prize: string;
    /**
     * 
     * @type {string}
     * @memberof ContestClaim
     */
    prize_delivery_address_recipient: string;
    /**
     * 
     * @type {string}
     * @memberof ContestClaim
     */
    prize_delivery_address_street: string;
    /**
     * 
     * @type {string}
     * @memberof ContestClaim
     */
    prize_delivery_address_unit?: string;
    /**
     * 
     * @type {string}
     * @memberof ContestClaim
     */
    prize_delivery_address_city: string;
    /**
     * 
     * @type {string}
     * @memberof ContestClaim
     */
    prize_delivery_address_state: string;
    /**
     * 
     * @type {string}
     * @memberof ContestClaim
     */
    prize_delivery_address_postal: string;
    /**
     * 
     * @type {string}
     * @memberof ContestClaim
     */
    prize_delivery_address_country: string;
}

/**
 * Check if a given object implements the ContestClaim interface.
 */
export function instanceOfContestClaim(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "is_claimed" in value;
    isInstance = isInstance && "claimed_prize" in value;
    isInstance = isInstance && "prize_delivery_address_recipient" in value;
    isInstance = isInstance && "prize_delivery_address_street" in value;
    isInstance = isInstance && "prize_delivery_address_city" in value;
    isInstance = isInstance && "prize_delivery_address_state" in value;
    isInstance = isInstance && "prize_delivery_address_postal" in value;
    isInstance = isInstance && "prize_delivery_address_country" in value;

    return isInstance;
}

export function ContestClaimFromJSON(json: any): ContestClaim {
    return ContestClaimFromJSONTyped(json, false);
}

export function ContestClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContestClaim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_claimed': json['is_claimed'],
        'claimed_prize': json['claimed_prize'],
        'prize_delivery_address_recipient': json['prize_delivery_address_recipient'],
        'prize_delivery_address_street': json['prize_delivery_address_street'],
        'prize_delivery_address_unit': !exists(json, 'prize_delivery_address_unit') ? undefined : json['prize_delivery_address_unit'],
        'prize_delivery_address_city': json['prize_delivery_address_city'],
        'prize_delivery_address_state': json['prize_delivery_address_state'],
        'prize_delivery_address_postal': json['prize_delivery_address_postal'],
        'prize_delivery_address_country': json['prize_delivery_address_country'],
    };
}

export function ContestClaimToJSON(value?: ContestClaim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'claimed_prize': value.claimed_prize,
        'prize_delivery_address_recipient': value.prize_delivery_address_recipient,
        'prize_delivery_address_street': value.prize_delivery_address_street,
        'prize_delivery_address_unit': value.prize_delivery_address_unit,
        'prize_delivery_address_city': value.prize_delivery_address_city,
        'prize_delivery_address_state': value.prize_delivery_address_state,
        'prize_delivery_address_postal': value.prize_delivery_address_postal,
        'prize_delivery_address_country': value.prize_delivery_address_country,
    };
}

