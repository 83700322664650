import { NextRouter } from "next/router"

import { PaymentSession, ProStatus, Testimonial } from "../../sdk"
import { UNAUTHENTICATED_USER_MESSAGE, handleUnauthenticatedUser } from "../components/utils/AuthCheck"
import { ENDPOINTS } from "../constants/endpoints"
import JSONFormError, { JSONError } from "../errors/JSONFormError"
import { externalLinkHandler } from "../utils/links"

export async function createCheckoutSession(router: NextRouter, clearAuth: () => void, token: string) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.PAYMENT_CREATE_CHECKOUT_SESSION, {
        method: "POST",
        headers: headers,
    })
    const response = await fetch(request)
    const responseJSON = (await response.json()) as PaymentSession

    if (response.ok) {
        await externalLinkHandler(responseJSON.session_url)
    } else {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new JSONFormError({
                status: response.status,
                errors: responseJSON as JSONError<unknown>,
            })
        }
    }
}

export async function createPortalSession(router: NextRouter, clearAuth: () => void, token: string) {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.PAYMENT_CREATE_PORTAL_SESSION, {
        method: "POST",
        headers: headers,
    })
    const response = await fetch(request)
    const responseJSON = (await response.json()) as PaymentSession

    if (response.ok) {
        await externalLinkHandler(responseJSON.session_url)
    } else {
        if (response.status === 403) {
            handleUnauthenticatedUser(router, clearAuth)
        } else {
            throw new JSONFormError({
                status: response.status,
                errors: responseJSON as JSONError<unknown>,
            })
        }
    }
}

export async function fetchUserProStatus(token: string): Promise<ProStatus> {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const request = new Request(ENDPOINTS.CURRENT_USER_PRO_STATUS, { method: "GET", headers: headers })
    const response = await fetch(request)

    if (!response.ok) {
        if (response.status === 403) {
            throw new Error(UNAUTHENTICATED_USER_MESSAGE)
        } else {
            throw new Error(`There was an error while attempting to load pro status.`)
        }
    }

    return (await response.json()) as ProStatus
}

export async function fetchTestimonials(token: string): Promise<Array<Testimonial>> {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const endpoint = ENDPOINTS.LIST_TESTIMONIALS

    const request = new Request(endpoint, { method: "GET", headers: headers })
    const response = await fetch(request)

    if (!response.ok) {
        if (response.status === 403) {
            throw new Error(UNAUTHENTICATED_USER_MESSAGE)
        } else {
            throw new Error(`There was an error while attempting to load testimonials. They were good too 😞`)
        }
    }

    return (await response.json()) as Array<Testimonial>
}
