/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationTypeEnum } from './NotificationTypeEnum';
import {
    NotificationTypeEnumFromJSON,
    NotificationTypeEnumFromJSONTyped,
    NotificationTypeEnumToJSON,
} from './NotificationTypeEnum';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    readonly id: number;
    /**
     * 
     * @type {NotificationTypeEnum}
     * @memberof Notification
     */
    readonly notification_type: NotificationTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    readonly payload_title: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    readonly payload_body: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    readonly payload_image: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    readonly payload_destination: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    readonly payload_destination_deeplinked: string;
    /**
     * 
     * @type {any}
     * @memberof Notification
     */
    readonly payload_metadata: any | null;
}

/**
 * Check if a given object implements the Notification interface.
 */
export function instanceOfNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "notification_type" in value;
    isInstance = isInstance && "payload_title" in value;
    isInstance = isInstance && "payload_body" in value;
    isInstance = isInstance && "payload_image" in value;
    isInstance = isInstance && "payload_destination" in value;
    isInstance = isInstance && "payload_destination_deeplinked" in value;
    isInstance = isInstance && "payload_metadata" in value;

    return isInstance;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'notification_type': NotificationTypeEnumFromJSON(json['notification_type']),
        'payload_title': json['payload_title'],
        'payload_body': json['payload_body'],
        'payload_image': json['payload_image'],
        'payload_destination': json['payload_destination'],
        'payload_destination_deeplinked': json['payload_destination_deeplinked'],
        'payload_metadata': json['payload_metadata'],
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

