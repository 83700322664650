/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SexReportTotals
 */
export interface SexReportTotals {
    /**
     * 
     * @type {number}
     * @memberof SexReportTotals
     */
    male: number;
    /**
     * 
     * @type {number}
     * @memberof SexReportTotals
     */
    female: number;
    /**
     * 
     * @type {number}
     * @memberof SexReportTotals
     */
    unstated: number;
    /**
     * 
     * @type {number}
     * @memberof SexReportTotals
     */
    total: number;
    /**
     * 
     * @type {Date}
     * @memberof SexReportTotals
     */
    first_redemption: Date | null;
}

/**
 * Check if a given object implements the SexReportTotals interface.
 */
export function instanceOfSexReportTotals(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "male" in value;
    isInstance = isInstance && "female" in value;
    isInstance = isInstance && "unstated" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "first_redemption" in value;

    return isInstance;
}

export function SexReportTotalsFromJSON(json: any): SexReportTotals {
    return SexReportTotalsFromJSONTyped(json, false);
}

export function SexReportTotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SexReportTotals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'male': json['male'],
        'female': json['female'],
        'unstated': json['unstated'],
        'total': json['total'],
        'first_redemption': (json['first_redemption'] === null ? null : new Date(json['first_redemption'])),
    };
}

export function SexReportTotalsToJSON(value?: SexReportTotals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'male': value.male,
        'female': value.female,
        'unstated': value.unstated,
        'total': value.total,
        'first_redemption': (value.first_redemption === null ? null : value.first_redemption.toISOString()),
    };
}

