import { AnalyticsBrowser } from "@june-so/analytics-next"
import { useEffect, useState } from "react"

const JUNE_API_KEY = process.env.JUNE_API_KEY || process.env.NEXT_PUBLIC_JUNE_API_KEY

export default function useJune() {
    const [analytics, setAnalytics] = useState<AnalyticsBrowser>(undefined)

    const loadAnalytics = () => {
        const response = AnalyticsBrowser.load({ writeKey: JUNE_API_KEY })
        setAnalytics(response)
    }

    useEffect(() => {
        loadAnalytics()
    }, [])

    return analytics
}
