import { Giveaway } from "../../sdk"
import { UNAUTHENTICATED_USER_MESSAGE } from "../components/utils/AuthCheck"
import { ENDPOINTS } from "../constants/endpoints"

export async function fetchGiveaways(token: string): Promise<Array<Giveaway>> {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const searchParams = new URLSearchParams()
    searchParams.set("displayable_modal", "true")

    const endpoint = `${ENDPOINTS.LIST_GIVEAWAYS}?${searchParams.toString()}`

    const request = new Request(endpoint, { method: "GET", headers: headers })
    const response = await fetch(request)

    if (!response.ok) {
        if (response.status === 403) {
            throw new Error(UNAUTHENTICATED_USER_MESSAGE)
        } else {
            throw new Error(`There was an error while attempting to load giveaways.`)
        }
    }

    return (await response.json()) as Array<Giveaway>
}

export async function fetchGiveaway(giveawayID: string, token: string): Promise<Giveaway> {
    const headers = new Headers()
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Token ${token}`)

    const endpoint = ENDPOINTS.RETRIEVE_GIVEAWAY.replace("{id}", giveawayID)

    const request = new Request(endpoint, { method: "GET", headers: headers })
    const response = await fetch(request)

    if (!response.ok) {
        if (response.status === 403) {
            throw new Error(UNAUTHENTICATED_USER_MESSAGE)
        } else {
            throw new Error(`There was an error while attempting to load this giveaway.`)
        }
    }

    return (await response.json()) as Giveaway
}
