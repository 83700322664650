import { useQuery } from "@tanstack/react-query"
import clsx from "clsx"
import { useAtom } from "jotai"
import Image from "next/image"
import { useRouter } from "next/router"
import { useCallback, useMemo, useState } from "react"
import Modal from "rsuite/Modal"

import { Giveaway } from "../../../../sdk"
import useUser from "../../../hooks/User/useUser"
import useJune from "../../../hooks/useJune"
import { fetchGiveaways } from "../../../requests/giveaways"
import { hiddenGiveawaysPreferenceAtom } from "../../../storage/preferences.store"
import { QueryError } from "../../../types/ReactQuery"
import Button from "../../buttons/Button/Button"
import MarkdownDisplay from "../../displays/MarkdownDisplay"
import { UNAUTHENTICATED_USER_MESSAGE, handleUnauthenticatedUser } from "../../utils/AuthCheck"
import styles from "./GiveawayModal.module.scss"
import { dismissedGiveawaysAtom } from "./GiveawayModal.store"

export default function GiveawayModal() {
    const { user, clearAuth } = useUser()

    const router = useRouter()
    const analytics = useJune()

    const [dismissedGiveaways, setDismissedGiveaways] = useAtom(dismissedGiveawaysAtom)
    const [hiddenGiveaways, setHiddenGiveaways] = useAtom(hiddenGiveawaysPreferenceAtom)
    const hiddenGiveawaysSet = useMemo(() => new Set(hiddenGiveaways), [hiddenGiveaways])

    const [splashContinued, setSplashContinued] = useState(false)

    const dismissGiveaway = useCallback(
        (giveawayID: number) => {
            analytics?.track("Dismiss Giveaway", { giveaway_id: giveawayID })
            setDismissedGiveaways(new Set(dismissedGiveaways).add(giveawayID))
        },
        [analytics, dismissedGiveaways, setDismissedGiveaways],
    )

    const hideGiveaway = useCallback(
        (giveawayID: number) => {
            analytics?.track("Hide Giveaway", { giveaway_id: giveawayID })
            setHiddenGiveaways(Array.from(hiddenGiveawaysSet.add(giveawayID)))
        },
        [analytics, hiddenGiveawaysSet, setHiddenGiveaways],
    )

    const {
        data: giveaways,
        error,
        isLoading,
        isError,
    } = useQuery<Array<Giveaway>, QueryError, Array<Giveaway>, string[]>({
        queryKey: ["giveaway_list", user?.token, user?.current_city?.toString()],
        queryFn: () => fetchGiveaways(user?.token),
        staleTime: 600000,
        enabled: !!user,
    })

    const displayableGiveaways = (giveaways || []).filter(
        (giveaway) => !dismissedGiveaways.has(giveaway.id) && !hiddenGiveawaysSet.has(giveaway.id),
    )

    if (
        isLoading ||
        !user ||
        router.pathname.includes("auth") ||
        router.pathname.includes("announcements") ||
        router.pathname.includes("giveaways") ||
        displayableGiveaways.length === 0
    ) {
        return null
    } else if (isError) {
        if (error?.message === UNAUTHENTICATED_USER_MESSAGE) {
            handleUnauthenticatedUser(router, clearAuth)
        }

        return (
            <div className={styles.message}>
                <span>{error?.message}</span>
            </div>
        )
    } else {
        const giveaway = displayableGiveaways[0]

        if (giveaway.show_splash_screen && !splashContinued) {
            return (
                <Modal
                    id="giveawaySplashModal"
                    className={styles.splash}
                    size="xs"
                    open={router.pathname !== "/"}
                    onClose={() => {
                        dismissGiveaway(giveaway.id)
                    }}
                >
                    <div className={styles.splashBody}>
                        <div className={styles.splashHeading}>
                            {giveaway.subtitle && <span className={styles.splashSubtitle}>{giveaway.subtitle}</span>}
                            <span className={styles.splashTitle}>{giveaway.title}</span>
                        </div>
                        <div className={styles.splashImage}>
                            {giveaway.splash_screen_image && (
                                <Image
                                    src={giveaway.splash_screen_image}
                                    alt={`Image of ${giveaway.title}`}
                                    width={210}
                                    height={440}
                                    priority={true}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.modalButtons}>
                        <Button
                            variant="black"
                            content="Next"
                            clickHandler={() => {
                                analytics?.track("Click Giveaway Splash Next", {
                                    giveaway_id: giveaway.id,
                                })
                                setSplashContinued(true)
                            }}
                        />
                    </div>
                </Modal>
            )
        } else {
            return (
                <Modal
                    id="giveawayModal"
                    className={clsx(styles.modal, { [styles.nonPersistent]: !giveaway.is_persistent })}
                    size="xs"
                    open={router.pathname !== "/"}
                    onClose={() => {
                        if (giveaway.is_persistent) {
                            dismissGiveaway(giveaway.id)
                        } else {
                            hideGiveaway(giveaway.id)
                        }
                    }}
                >
                    <Modal.Header className={styles.header} style={{ backgroundImage: `url(${giveaway.image})` }}>
                        <Modal.Title />
                    </Modal.Header>
                    <div className={styles.body}>
                        <div className={styles.contentHeading}>
                            {giveaway.subtitle && <span className={styles.heading}>{giveaway.subtitle}</span>}
                            <span className={styles.headingFancy}>{giveaway.title}</span>
                        </div>
                        <div className={styles.details}>
                            <MarkdownDisplay>{giveaway.body}</MarkdownDisplay>
                        </div>
                    </div>
                    <div className={styles.modalButtons}>
                        {user.is_pro ? (
                            <Button
                                variant="primary"
                                content={giveaway.pro_cta_text}
                                clickHandler={() => {
                                    analytics?.track("Click Giveaway CTA", { giveaway_id: giveaway.id, is_pro: true })
                                    if (giveaway.is_persistent) {
                                        dismissGiveaway(giveaway.id)
                                    } else {
                                        hideGiveaway(giveaway.id)
                                    }
                                    router.push(giveaway.pro_cta_destination)
                                }}
                            />
                        ) : (
                            <Button
                                variant="primary"
                                content={giveaway.cta_text}
                                clickHandler={() => {
                                    analytics?.track("Click Giveaway CTA", { giveaway_id: giveaway.id, is_pro: false })
                                    if (giveaway.is_persistent) {
                                        dismissGiveaway(giveaway.id)
                                    } else {
                                        hideGiveaway(giveaway.id)
                                    }
                                    router.push(giveaway.cta_destination)
                                }}
                            />
                        )}
                        {giveaway.is_persistent && (
                            <Button
                                variant="text"
                                content="Don't show this message again"
                                extraClasses={[styles.smallButton]}
                                clickHandler={() => hideGiveaway(giveaway.id)}
                            />
                        )}
                    </div>
                </Modal>
            )
        }
    }
}
