/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BusinessPhysicalAddressGeoPoint } from './BusinessPhysicalAddressGeoPoint';
import {
    BusinessPhysicalAddressGeoPointFromJSON,
    BusinessPhysicalAddressGeoPointFromJSONTyped,
    BusinessPhysicalAddressGeoPointToJSON,
} from './BusinessPhysicalAddressGeoPoint';

/**
 * 
 * @export
 * @interface BusinessMapMarker
 */
export interface BusinessMapMarker {
    /**
     * 
     * @type {string}
     * @memberof BusinessMapMarker
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessMapMarker
     */
    readonly name: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessMapMarker
     */
    readonly is_pro: boolean;
    /**
     * 
     * @type {BusinessPhysicalAddressGeoPoint}
     * @memberof BusinessMapMarker
     */
    physical_address_geo_point: BusinessPhysicalAddressGeoPoint | null;
}

/**
 * Check if a given object implements the BusinessMapMarker interface.
 */
export function instanceOfBusinessMapMarker(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "is_pro" in value;
    isInstance = isInstance && "physical_address_geo_point" in value;

    return isInstance;
}

export function BusinessMapMarkerFromJSON(json: any): BusinessMapMarker {
    return BusinessMapMarkerFromJSONTyped(json, false);
}

export function BusinessMapMarkerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessMapMarker {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'is_pro': json['is_pro'],
        'physical_address_geo_point': BusinessPhysicalAddressGeoPointFromJSON(json['physical_address_geo_point']),
    };
}

export function BusinessMapMarkerToJSON(value?: BusinessMapMarker | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physical_address_geo_point': BusinessPhysicalAddressGeoPointToJSON(value.physical_address_geo_point),
    };
}

