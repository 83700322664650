import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react"

import Button from "../../buttons/Button/Button"
import styles from "./Accordion.module.scss"
import { AccordionForwardedRef, AccordionProps } from "./Accordion.types"

function Accordion(props: AccordionProps, ref: ForwardedRef<AccordionForwardedRef>) {
    const { headerContent, bodyContent, bodyCentered = false, startOpen = false, onOpen = undefined } = props

    const [isOpen, setIsOpen] = useState(startOpen)

    // Establish forward ref functions
    useImperativeHandle(
        ref,
        () => ({
            close: () => setIsOpen(false),
        }),
        [],
    )

    return (
        <div className={clsx(styles.container, { [styles.open]: isOpen })}>
            <Button
                variant="wrapper"
                content={
                    <div className={styles.header}>
                        <div className={styles.headerContent}>{headerContent}</div>
                        <div className={styles.headerIndicator}>
                            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                        </div>
                    </div>
                }
                clickHandler={() => {
                    setIsOpen((value) => !value)
                    onOpen && onOpen()
                }}
                ignoreDebounce={true}
            />
            {isOpen && <div className={clsx(styles.body, { [styles.bodyCentered]: bodyCentered })}>{bodyContent}</div>}
        </div>
    )
}

export default forwardRef(Accordion)
