import { Capacitor } from "@capacitor/core"
import { useAtom } from "jotai"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import useScanPermissions from "../../../hooks/useScanPermissions"
import PermissionsScreen from "../PermissionsScreen/PermissionsScreen"
import { skipPermissionsCheckAtom } from "./PermissionsCheck.store"

export default function PermissionsCheck(props: { children: JSX.Element }): JSX.Element {
    const { children } = props

    const router = useRouter()
    const [permissionsSkipped] = useAtom(skipPermissionsCheckAtom)
    const { scannerGranted, locationGranted } = useScanPermissions()
    const [renderScreen, setRenderScreen] = useState(false)

    // Render the permissions screen to native users if the user has not skipped or completed the permissions check
    // and location or scanner permissions are not granted
    useEffect(() => {
        if (
            Capacitor.isNativePlatform() &&
            permissionsSkipped !== undefined &&
            scannerGranted !== null &&
            locationGranted !== null
        ) {
            setRenderScreen(!permissionsSkipped && (!scannerGranted || !locationGranted))
        }
    }, [permissionsSkipped, locationGranted, scannerGranted])

    if (!router.pathname.includes("auth") && renderScreen) {
        return (
            <main>
                <PermissionsScreen setRenderScreen={setRenderScreen} />
            </main>
        )
    } else {
        return children
    }
}
