import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { faTrophy } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useAtom } from "jotai"
import { useRouter } from "next/router"
import pluralize from "pluralize"
import { useEffect, useMemo, useState } from "react"

import { Contest, ContestsListStatusEnum, PaginatedContestList } from "../../../../sdk"
import { PAGE_ROUTES } from "../../../constants/routes"
import useUser from "../../../hooks/User/useUser"
import useJune from "../../../hooks/useJune"
import { fetchContests } from "../../../requests/contests"
import {
    checkedContestsPreferenceAtom,
    dismissedContestAnnouncementsPreferenceAtom,
} from "../../../storage/preferences.store"
import { QueryError } from "../../../types/ReactQuery"
import Button from "../../buttons/Button/Button"
import styles from "./LatestContestAnnouncement.module.scss"

export default function LatestContestAnnouncement() {
    const router = useRouter()
    const analytics = useJune()
    const { user } = useUser()

    const [latestContest, setLatestContest] = useState<Contest>(null)

    const recentSearchParams = new URLSearchParams()
    recentSearchParams.set("status", ContestsListStatusEnum.recent)

    const { data: contests } = useQuery<PaginatedContestList, QueryError, PaginatedContestList, string[]>({
        queryKey: ["contests_check", user?.token, user?.current_city?.toString(), recentSearchParams.toString()],
        queryFn: () => fetchContests(user?.token, recentSearchParams.toString()),
        enabled: !!user,
        staleTime: 3600000,
    })

    const [dismissedAnnouncements, setDismissedAnnouncements] = useAtom(dismissedContestAnnouncementsPreferenceAtom)
    const dismissedAnnouncementsSet = useMemo(
        () => (dismissedAnnouncements !== undefined ? new Set(dismissedAnnouncements) : null),
        [dismissedAnnouncements],
    )

    const [checkedContests] = useAtom(checkedContestsPreferenceAtom)
    const checkedContestsSet = useMemo(() => new Set(checkedContests), [checkedContests])

    useEffect(() => {
        if (
            contests &&
            contests.count > 0 &&
            dismissedAnnouncementsSet !== null &&
            user &&
            user.feature_flags?.contests
        ) {
            const latestContest = contests.results[0]
            const dismissed = dismissedAnnouncementsSet.has(latestContest.id)
            const winnerChecked = checkedContestsSet.has(latestContest.id)
            const participated = latestContest.current_user_qualification.user_qualifies

            if (
                participated &&
                !dismissed &&
                latestContest.winner_id &&
                !winnerChecked &&
                !router.pathname.includes("contests")
            ) {
                setLatestContest(latestContest)
            } else {
                setLatestContest(null)
            }
        } else {
            setLatestContest(null)
        }
    }, [contests, dismissedAnnouncementsSet, checkedContestsSet, router.pathname, user])

    if (latestContest) {
        const userIsWinner = latestContest.winner_id ? user.id === latestContest.winner_id : null
        const entryCount = latestContest.current_user_qualification.entry_count

        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.body}>
                        <div className={styles.details}>
                            <div className={styles.title}>
                                {dayjs(latestContest.end_at).format("MMM DD")} Winner Announcement!
                            </div>
                            <p>
                                {`You entered ${entryCount} ${pluralize("ticket", entryCount)} into this contest ` +
                                    `for a chance to win $${parseFloat(latestContest.prize_value)}!`}
                            </p>
                        </div>
                        <div className={styles.close}>
                            <Button
                                variant="closeInverse"
                                content={<FontAwesomeIcon icon={faXmark} />}
                                clickHandler={() => {
                                    analytics?.track("Click Dismiss Contest Announcement", {
                                        contest_id: latestContest.id,
                                        contest_entry_type: latestContest.entry_type,
                                        contest_entry_threshold: latestContest.entry_threshold,
                                        contest_prize_type: latestContest.prize_type,
                                        contest_prize_value: latestContest.prize_value,
                                        contest_status: latestContest.status,
                                        user_is_winner: userIsWinner,
                                    })
                                    setLatestContest(null)
                                    setDismissedAnnouncements(
                                        Array.from(dismissedAnnouncementsSet.add(latestContest.id)),
                                    )
                                }}
                                ignoreDebounce={true}
                            />
                        </div>
                    </div>
                    <div className={styles.action}>
                        <Button
                            variant="gold"
                            content={
                                <>
                                    <FontAwesomeIcon icon={faTrophy} />
                                    <span>Let&apos;s see if you won!</span>
                                </>
                            }
                            clickHandler={() => {
                                analytics?.track("Click View Contest Announcement", {
                                    contest_id: latestContest.id,
                                    contest_entry_type: latestContest.entry_type,
                                    contest_entry_threshold: latestContest.entry_threshold,
                                    contest_prize_type: latestContest.prize_type,
                                    contest_prize_value: latestContest.prize_value,
                                    contest_status: latestContest.status,
                                    user_is_winner: userIsWinner,
                                })
                                setLatestContest(null)
                                setDismissedAnnouncements(Array.from(dismissedAnnouncementsSet.add(latestContest.id)))
                                router.push(PAGE_ROUTES.CONTESTS_RECENT)
                            }}
                            ignoreDebounce={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
