import { ReasonEnum } from "../../sdk"

export const SEXES = {
    1: "Male",
    2: "Female",
    0: "Rather not say",
}

export const UTM_MEDIUMS = {
    cal_poly_booth: "Cal Poly Booth",
    cuesta_booth: "Cuesta Booth",
    campus_bulletin_board: "Campus Bulletin Board",
    campus_flyer: "Campus Flyer",
    car_flyer: "Car Flyer",
    door_hanger: "Door Hanger",
    farmers_booth: "Farmer's Market Booth",
    facebook_ad: "Facebook Ad",
    google_search: "Google Search",
    instagram_ad: "Instagram Ad",
    local_influencer: "Local Influencer",
    local_news: "Local News",
    referral: "Referral (Friend)",
    restaurant_qr_code: "Restaurant QR Code",
    tshirt: "T-Shirt",
    quickie: "Quickie",
    other: "Other",
}

export const CANCELLATION_REASONS: { [key in ReasonEnum]: string } = {
    lack_of_deals: "Restaurants I wanted don't offer deals",
    not_worth_it: "The deals aren't worth $9/month",
    not_eating_out: "I don't eat out as much as I thought",
    left_slo: "I no longer live in SLO",
    reducing_subscriptions: "I'm cutting down on subscriptions",
    missing_features: "SLOeats doesn't have a feature I want",
    other: "Other",
}

export const PRICE_LEVELS = {
    1: "$",
    2: "$$",
    3: "$$$",
    4: "$$$$",
}

export const CUISINES = {
    1: "African",
    2: "American",
    3: "Asian Fusion",
    4: "BBQ",
    5: "Bar Food",
    6: "Belgian",
    7: "Breakfast",
    8: "Beer",
    9: "Burgers",
    10: "Coffee",
    11: "Cajun",
    12: "Californian",
    13: "Caribbean",
    14: "Chicken",
    15: "Chinese",
    16: "Coffee and Tea",
    17: "Crêpes",
    18: "Deli Food",
    19: "Desserts",
    20: "Diner Food",
    21: "Donuts",
    22: "Drinks Only",
    23: "European",
    24: "Fast Food",
    25: "Filipino",
    26: "French",
    27: "Fusion",
    28: "German",
    29: "Greek",
    30: "Hawaiian",
    31: "Healthy Food",
    32: "Ice Cream",
    33: "Indian",
    34: "Italian",
    35: "Japanese",
    36: "Korean",
    37: "Latin American",
    38: "Mediterranean",
    39: "Mexican",
    40: "Middle Eastern",
    41: "Moroccan",
    42: "New American",
    43: "Pakistani",
    44: "Peruvian",
    45: "Pizza",
    46: "Poke",
    47: "Portuguese",
    48: "Pub Food",
    49: "Ramen",
    50: "Salads",
    51: "Sandwiches",
    52: "Scandinavian",
    53: "Seafood",
    54: "Southern",
    55: "Southwestern",
    56: "Spanish",
    57: "Steakhouse",
    58: "Sushi",
    59: "Tapas",
    60: "Tea",
    61: "Thai",
    62: "Vietnamese",
    63: "Wine",
    64: "Baked Goods",
    65: "Cheese",
    66: "Bagels",
    67: "Juice and Smoothies",
    68: "Hotdogs",
    69: "Smoked Meats",
    70: "Cocktails",
    71: "Tacos",
    72: "Groceries",
    73: "Turkish",
    74: "Chocolate",
    75: "Bubble Tea",
    76: "Macaroons",
    77: "Acai Bowls",
    78: "Wraps",
    79: "Candy",
    80: "Pretzels",
    81: "Vegan",
    82: "Vitamins and Supplements",
    83: "Pastries",
}

export const DIETS = {
    1: "Dairy Free",
    2: "Gluten Free",
    3: "Keto",
    4: "Paleo",
    5: "Vegan",
    6: "Vegetarian",
    7: "Whole 30",
}

export const AMENITIES = {
    1: "Free WiFi",
    2: "Live Music",
    3: "Brunch",
    4: "Vegetarian Options",
    5: "Vegan Options",
    6: "Outdoor Seating",
    7: "Offers Takeout",
    8: "Offers Delivery",
    9: "Kid Friendly",
    10: "Wheelchair Accessible",
    11: "Takes Reservations",
    12: "Valet",
    13: "Private Parking Available",
    14: "Dogs Allowed",
    15: "Has TV",
    16: "Offers Military Discount",
    17: "Happy Hour Specials",
    18: "Full Bar",
    19: "Beer and Wine Only",
    20: "Arcade",
    21: "Billiards",
    22: "Darts",
    23: "Shuffleboard",
    24: "Trivia",
    25: "Karaoke",
    26: "Live Sports",
}
