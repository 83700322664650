/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BusinessImage
 */
export interface BusinessImage {
    /**
     * 
     * @type {number}
     * @memberof BusinessImage
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessImage
     */
    readonly index: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessImage
     */
    readonly image: string;
}

/**
 * Check if a given object implements the BusinessImage interface.
 */
export function instanceOfBusinessImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "image" in value;

    return isInstance;
}

export function BusinessImageFromJSON(json: any): BusinessImage {
    return BusinessImageFromJSONTyped(json, false);
}

export function BusinessImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'index': json['index'],
        'image': json['image'],
    };
}

export function BusinessImageToJSON(value?: BusinessImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

