/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContestClaim,
  PaginatedContestList,
} from '../models';
import {
    ContestClaimFromJSON,
    ContestClaimToJSON,
    PaginatedContestListFromJSON,
    PaginatedContestListToJSON,
} from '../models';

export interface ContestsClaimCreateRequest {
    id: number;
    ContestClaim: ContestClaim;
}

export interface ContestsListRequest {
    historical?: boolean;
    limit?: number;
    offset?: number;
    status?: ContestsListStatusEnum;
}

/**
 * ContestsApi - interface
 * 
 * @export
 * @interface ContestsApiInterface
 */
export interface ContestsApiInterface {
    /**
     * 
     * @param {number} id A unique integer value identifying this Contest.
     * @param {ContestClaim} ContestClaim 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestsApiInterface
     */
    contestsClaimCreateRaw(requestParameters: ContestsClaimCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContestClaim>>;

    /**
     */
    contestsClaimCreate(requestParameters: ContestsClaimCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContestClaim>;

    /**
     * 
     * @param {boolean} [historical] Historical
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {'archived' | 'live' | 'recent' | 'upcoming'} [status] Status  * &#x60;upcoming&#x60; - Upcoming * &#x60;live&#x60; - Live * &#x60;recent&#x60; - Recent * &#x60;archived&#x60; - Archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContestsApiInterface
     */
    contestsListRaw(requestParameters: ContestsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedContestList>>;

    /**
     */
    contestsList(requestParameters: ContestsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedContestList>;

}

/**
 * 
 */
export class ContestsApi extends runtime.BaseAPI implements ContestsApiInterface {

    /**
     */
    async contestsClaimCreateRaw(requestParameters: ContestsClaimCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContestClaim>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contestsClaimCreate.');
        }

        if (requestParameters.ContestClaim === null || requestParameters.ContestClaim === undefined) {
            throw new runtime.RequiredError('ContestClaim','Required parameter requestParameters.ContestClaim was null or undefined when calling contestsClaimCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/contests/{id}/claim/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContestClaimToJSON(requestParameters.ContestClaim),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContestClaimFromJSON(jsonValue));
    }

    /**
     */
    async contestsClaimCreate(requestParameters: ContestsClaimCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContestClaim> {
        const response = await this.contestsClaimCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contestsListRaw(requestParameters: ContestsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedContestList>> {
        const queryParameters: any = {};

        if (requestParameters.historical !== undefined) {
            queryParameters['historical'] = requestParameters.historical;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/contests/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedContestListFromJSON(jsonValue));
    }

    /**
     */
    async contestsList(requestParameters: ContestsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedContestList> {
        const response = await this.contestsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ContestsListStatusEnum = {
    archived: 'archived',
    live: 'live',
    recent: 'recent',
    upcoming: 'upcoming'
} as const;
export type ContestsListStatusEnum = typeof ContestsListStatusEnum[keyof typeof ContestsListStatusEnum];
