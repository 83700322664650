import "@fontsource-variable/mulish"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import Head from "next/head"
import { Fragment, useCallback, useEffect } from "react"
import "rsuite/dist/rsuite.min.css"
import { register as registerSwiper } from "swiper/element/bundle"

import LatestContestAnnouncement from "../components/announcements/LatestContestAnnouncement/LatestContestAnnouncement"
import ClientError from "../components/errors/ClientError/ClientError"
import GiveawayModal from "../components/modals/GiveawayModal/GiveawayModal"
import AnalyticsInitializer from "../components/utils/AnalyticsInitializer"
import AppUrlListener from "../components/utils/AppUrlListener"
import AuthCheck from "../components/utils/AuthCheck"
import NotificationBadgeProvider from "../components/utils/NotificationBadgeProvider"
import PermissionsCheck from "../components/utils/PermissionsCheck/PermissionsCheck"
import ProStatusListener from "../components/utils/ProStatusListener"
import PushNotificationListener from "../components/utils/PushNotificationListener"
import SupportChatInitializer from "../components/utils/SupportChatInitializer"
import UpdateCheck from "../components/utils/UpdateCheck"
import UserStoreProvider from "../components/utils/UserStoreProvider"
import "../styles/global.scss"

config.autoAddCss = false

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_RELEASE = process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE || "unknown"
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "unknown"

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || process.env.NEXT_PUBLIC_INTERCOM_APP_ID

Sentry.init({
    dsn: SENTRY_DSN,
    release: SENTRY_RELEASE,
    environment: SENTRY_ENVIRONMENT,
    sendDefaultPii: true,
    tracesSampleRate: 0.05,
    profilesSampleRate: 0.05,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [
        "https://api.sloeats.com",
        "https://staging.api.sloeats.com",
        "https://demo.api.sloeats.com",
    ],
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
})

const queryClient = new QueryClient()

function App({ Component, pageProps: { protectedPage = true, ...restPageProps } }) {
    registerSwiper()

    // Scroll to the top of the page if the statusbar is tapped (iOS only)
    const onStatusTap = useCallback(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        window.addEventListener("statusTap", onStatusTap)

        return () => {
            window.removeEventListener("statusTap", onStatusTap)
        }
    }, [onStatusTap])

    useEffect(() => {
        window.intercomSettings = {
            app_id: INTERCOM_APP_ID,
            custom_launcher_selector: ".SupportButton",
        }

        // Intercom code snippet
        ;(function () {
            const ic = window.Intercom
            if (typeof ic === "function") {
                ic("reattach_activator")
                ic("update", window.intercomSettings)
            } else {
                const d = document
                const i = function () {
                    // eslint-disable-next-line prefer-rest-params
                    i.c(arguments)
                }
                i.q = []
                i.c = function (args) {
                    i.q.push(args)
                }
                // @ts-expect-error - Intercom is not typed well
                window.Intercom = i
                const l = function () {
                    const s = d.createElement("script")
                    s.type = "text/javascript"
                    s.async = true
                    s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`
                    const x = d.getElementsByTagName("script")[0]
                    x.parentNode.insertBefore(s, x)
                }
                if (document.readyState === "complete") {
                    l()
                } else {
                    window.addEventListener("load", l, false)
                }
            }
        })()
    }, [])

    // Intercom clears the inset area variable, so we store it in a body variable for later retrieval
    useEffect(() => {
        const setInsetArea = () => {
            const insetArea = getComputedStyle(document.body).getPropertyValue("--inset-area")
            document.body.style.setProperty("--inset-area-saved", insetArea)
        }

        setTimeout(() => {
            setInsetArea()
        }, 1000)
    }, [])

    // If this page doesn't need to be protected, render the children
    const AuthCheckComponent = protectedPage ? AuthCheck : Fragment

    return (
        <QueryClientProvider client={queryClient}>
            <UserStoreProvider>
                <Sentry.ErrorBoundary fallback={<ClientError />}>
                    <Head>
                        <title>SLOeats</title>

                        <meta charSet="utf-8" />
                        <meta
                            name="viewport"
                            content="initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, width=device-width, height=device-height viewport-fit=cover"
                        ></meta>
                        <meta name="description" content="The Ultimate Restaurant Membership" />

                        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
                        <link rel="manifest" href="/manifest.json" />
                    </Head>
                    <AppUrlListener />
                    <PushNotificationListener />
                    <AnalyticsInitializer />
                    <SupportChatInitializer />
                    <NotificationBadgeProvider />
                    <ProStatusListener />
                    <UpdateCheck>
                        <AuthCheckComponent>
                            <PermissionsCheck>
                                <>
                                    <Component protectedPage={protectedPage} {...restPageProps} />
                                    <LatestContestAnnouncement />
                                    <GiveawayModal />
                                </>
                            </PermissionsCheck>
                        </AuthCheckComponent>
                    </UpdateCheck>
                    <ReactQueryDevtools initialIsOpen={false} />
                </Sentry.ErrorBoundary>
            </UserStoreProvider>
        </QueryClientProvider>
    )
}

export default Sentry.withProfiler(App)
