/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RedemptionGeoPoint
 */
export interface RedemptionGeoPoint {
    /**
     * 
     * @type {string}
     * @memberof RedemptionGeoPoint
     */
    type?: RedemptionGeoPointTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof RedemptionGeoPoint
     */
    coordinates?: Array<number>;
}


/**
 * @export
 */
export const RedemptionGeoPointTypeEnum = {
    Point: 'Point'
} as const;
export type RedemptionGeoPointTypeEnum = typeof RedemptionGeoPointTypeEnum[keyof typeof RedemptionGeoPointTypeEnum];


/**
 * Check if a given object implements the RedemptionGeoPoint interface.
 */
export function instanceOfRedemptionGeoPoint(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RedemptionGeoPointFromJSON(json: any): RedemptionGeoPoint {
    return RedemptionGeoPointFromJSONTyped(json, false);
}

export function RedemptionGeoPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedemptionGeoPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
    };
}

export function RedemptionGeoPointToJSON(value?: RedemptionGeoPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'coordinates': value.coordinates,
    };
}

