/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgeReportTimeseries,
  AgeReportTotals,
  RedemptionsReportTimeseries,
  RedemptionsReportTotals,
  SalesReportList,
  SalesReportTimeseries,
  SalesReportTotals,
  SexReportTimeseries,
  SexReportTotals,
} from '../models';
import {
    AgeReportTimeseriesFromJSON,
    AgeReportTimeseriesToJSON,
    AgeReportTotalsFromJSON,
    AgeReportTotalsToJSON,
    RedemptionsReportTimeseriesFromJSON,
    RedemptionsReportTimeseriesToJSON,
    RedemptionsReportTotalsFromJSON,
    RedemptionsReportTotalsToJSON,
    SalesReportListFromJSON,
    SalesReportListToJSON,
    SalesReportTimeseriesFromJSON,
    SalesReportTimeseriesToJSON,
    SalesReportTotalsFromJSON,
    SalesReportTotalsToJSON,
    SexReportTimeseriesFromJSON,
    SexReportTimeseriesToJSON,
    SexReportTotalsFromJSON,
    SexReportTotalsToJSON,
} from '../models';

export interface ReportsAgeTimeseriesListRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
    descending?: boolean;
    period: ReportsAgeTimeseriesListPeriodEnum;
}

export interface ReportsAgeTotalsRetrieveRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
}

export interface ReportsRedemptionsTimeseriesListRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
    descending?: boolean;
    period: ReportsRedemptionsTimeseriesListPeriodEnum;
}

export interface ReportsRedemptionsTotalsRetrieveRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
}

export interface ReportsSalesListRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
}

export interface ReportsSalesTimeseriesListRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
    descending?: boolean;
    period: ReportsSalesTimeseriesListPeriodEnum;
}

export interface ReportsSalesTotalsRetrieveRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
}

export interface ReportsSexTimeseriesListRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
    descending?: boolean;
    period: ReportsSexTimeseriesListPeriodEnum;
}

export interface ReportsSexTotalsRetrieveRequest {
    business_id: string;
    created_at__after?: Date;
    created_at__before?: Date;
    deal?: number;
}

/**
 * ReportsApi - interface
 * 
 * @export
 * @interface ReportsApiInterface
 */
export interface ReportsApiInterface {
    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] Filter for redemptions for a given deal ID.
     * @param {boolean} [descending] Whether the timeseries list should be ordered in descending order.
     * @param {'day' | 'month' | 'quarter' | 'week' | 'year'} period The period by which the data should be grouped.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsAgeTimeseriesListRaw(requestParameters: ReportsAgeTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgeReportTimeseries>>>;

    /**
     */
    reportsAgeTimeseriesList(requestParameters: ReportsAgeTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgeReportTimeseries>>;

    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] Filter for redemptions for a given deal ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsAgeTotalsRetrieveRaw(requestParameters: ReportsAgeTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgeReportTotals>>;

    /**
     */
    reportsAgeTotalsRetrieve(requestParameters: ReportsAgeTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgeReportTotals>;

    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] Filter for redemptions for a given deal ID.
     * @param {boolean} [descending] Whether the timeseries list should be ordered in descending order.
     * @param {'day' | 'month' | 'quarter' | 'week' | 'year'} period The period by which the data should be grouped.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsRedemptionsTimeseriesListRaw(requestParameters: ReportsRedemptionsTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RedemptionsReportTimeseries>>>;

    /**
     */
    reportsRedemptionsTimeseriesList(requestParameters: ReportsRedemptionsTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RedemptionsReportTimeseries>>;

    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] Filter for redemptions for a given deal ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsRedemptionsTotalsRetrieveRaw(requestParameters: ReportsRedemptionsTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedemptionsReportTotals>>;

    /**
     */
    reportsRedemptionsTotalsRetrieve(requestParameters: ReportsRedemptionsTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedemptionsReportTotals>;

    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsSalesListRaw(requestParameters: ReportsSalesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesReportList>>>;

    /**
     */
    reportsSalesList(requestParameters: ReportsSalesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesReportList>>;

    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] Filter for redemptions for a given deal ID.
     * @param {boolean} [descending] Whether the timeseries list should be ordered in descending order.
     * @param {'day' | 'month' | 'quarter' | 'week' | 'year'} period The period by which the data should be grouped.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsSalesTimeseriesListRaw(requestParameters: ReportsSalesTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesReportTimeseries>>>;

    /**
     */
    reportsSalesTimeseriesList(requestParameters: ReportsSalesTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesReportTimeseries>>;

    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] Filter for redemptions for a given deal ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsSalesTotalsRetrieveRaw(requestParameters: ReportsSalesTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SalesReportTotals>>;

    /**
     */
    reportsSalesTotalsRetrieve(requestParameters: ReportsSalesTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SalesReportTotals>;

    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] Filter for redemptions for a given deal ID.
     * @param {boolean} [descending] Whether the timeseries list should be ordered in descending order.
     * @param {'day' | 'month' | 'quarter' | 'week' | 'year'} period The period by which the data should be grouped.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsSexTimeseriesListRaw(requestParameters: ReportsSexTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SexReportTimeseries>>>;

    /**
     */
    reportsSexTimeseriesList(requestParameters: ReportsSexTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SexReportTimeseries>>;

    /**
     * 
     * @param {string} business_id 
     * @param {Date} [created_at__after] Filter for redemptions that were created after a given datetime.
     * @param {Date} [created_at__before] Filter for redemptions that were created before a given datetime.
     * @param {number} [deal] Filter for redemptions for a given deal ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    reportsSexTotalsRetrieveRaw(requestParameters: ReportsSexTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SexReportTotals>>;

    /**
     */
    reportsSexTotalsRetrieve(requestParameters: ReportsSexTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SexReportTotals>;

}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI implements ReportsApiInterface {

    /**
     */
    async reportsAgeTimeseriesListRaw(requestParameters: ReportsAgeTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgeReportTimeseries>>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsAgeTimeseriesList.');
        }

        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling reportsAgeTimeseriesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        if (requestParameters.descending !== undefined) {
            queryParameters['descending'] = requestParameters.descending;
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/age/timeseries/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgeReportTimeseriesFromJSON));
    }

    /**
     */
    async reportsAgeTimeseriesList(requestParameters: ReportsAgeTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgeReportTimeseries>> {
        const response = await this.reportsAgeTimeseriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsAgeTotalsRetrieveRaw(requestParameters: ReportsAgeTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgeReportTotals>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsAgeTotalsRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/age/totals/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeReportTotalsFromJSON(jsonValue));
    }

    /**
     */
    async reportsAgeTotalsRetrieve(requestParameters: ReportsAgeTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgeReportTotals> {
        const response = await this.reportsAgeTotalsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsRedemptionsTimeseriesListRaw(requestParameters: ReportsRedemptionsTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RedemptionsReportTimeseries>>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsRedemptionsTimeseriesList.');
        }

        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling reportsRedemptionsTimeseriesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        if (requestParameters.descending !== undefined) {
            queryParameters['descending'] = requestParameters.descending;
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/redemptions/timeseries/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RedemptionsReportTimeseriesFromJSON));
    }

    /**
     */
    async reportsRedemptionsTimeseriesList(requestParameters: ReportsRedemptionsTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RedemptionsReportTimeseries>> {
        const response = await this.reportsRedemptionsTimeseriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsRedemptionsTotalsRetrieveRaw(requestParameters: ReportsRedemptionsTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedemptionsReportTotals>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsRedemptionsTotalsRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/redemptions/totals/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedemptionsReportTotalsFromJSON(jsonValue));
    }

    /**
     */
    async reportsRedemptionsTotalsRetrieve(requestParameters: ReportsRedemptionsTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedemptionsReportTotals> {
        const response = await this.reportsRedemptionsTotalsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsSalesListRaw(requestParameters: ReportsSalesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesReportList>>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsSalesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/sales/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SalesReportListFromJSON));
    }

    /**
     */
    async reportsSalesList(requestParameters: ReportsSalesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesReportList>> {
        const response = await this.reportsSalesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsSalesTimeseriesListRaw(requestParameters: ReportsSalesTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesReportTimeseries>>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsSalesTimeseriesList.');
        }

        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling reportsSalesTimeseriesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        if (requestParameters.descending !== undefined) {
            queryParameters['descending'] = requestParameters.descending;
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/sales/timeseries/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SalesReportTimeseriesFromJSON));
    }

    /**
     */
    async reportsSalesTimeseriesList(requestParameters: ReportsSalesTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesReportTimeseries>> {
        const response = await this.reportsSalesTimeseriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsSalesTotalsRetrieveRaw(requestParameters: ReportsSalesTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SalesReportTotals>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsSalesTotalsRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/sales/totals/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SalesReportTotalsFromJSON(jsonValue));
    }

    /**
     */
    async reportsSalesTotalsRetrieve(requestParameters: ReportsSalesTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SalesReportTotals> {
        const response = await this.reportsSalesTotalsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsSexTimeseriesListRaw(requestParameters: ReportsSexTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SexReportTimeseries>>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsSexTimeseriesList.');
        }

        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling reportsSexTimeseriesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        if (requestParameters.descending !== undefined) {
            queryParameters['descending'] = requestParameters.descending;
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/sex/timeseries/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SexReportTimeseriesFromJSON));
    }

    /**
     */
    async reportsSexTimeseriesList(requestParameters: ReportsSexTimeseriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SexReportTimeseries>> {
        const response = await this.reportsSexTimeseriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsSexTotalsRetrieveRaw(requestParameters: ReportsSexTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SexReportTotals>> {
        if (requestParameters.business_id === null || requestParameters.business_id === undefined) {
            throw new runtime.RequiredError('business_id','Required parameter requestParameters.business_id was null or undefined when calling reportsSexTotalsRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.created_at__after !== undefined) {
            queryParameters['created_at__after'] = (requestParameters.created_at__after as any).toISOString();
        }

        if (requestParameters.created_at__before !== undefined) {
            queryParameters['created_at__before'] = (requestParameters.created_at__before as any).toISOString();
        }

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/reports/{business_id}/sex/totals/`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters.business_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SexReportTotalsFromJSON(jsonValue));
    }

    /**
     */
    async reportsSexTotalsRetrieve(requestParameters: ReportsSexTotalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SexReportTotals> {
        const response = await this.reportsSexTotalsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ReportsAgeTimeseriesListPeriodEnum = {
    day: 'day',
    month: 'month',
    quarter: 'quarter',
    week: 'week',
    year: 'year'
} as const;
export type ReportsAgeTimeseriesListPeriodEnum = typeof ReportsAgeTimeseriesListPeriodEnum[keyof typeof ReportsAgeTimeseriesListPeriodEnum];
/**
 * @export
 */
export const ReportsRedemptionsTimeseriesListPeriodEnum = {
    day: 'day',
    month: 'month',
    quarter: 'quarter',
    week: 'week',
    year: 'year'
} as const;
export type ReportsRedemptionsTimeseriesListPeriodEnum = typeof ReportsRedemptionsTimeseriesListPeriodEnum[keyof typeof ReportsRedemptionsTimeseriesListPeriodEnum];
/**
 * @export
 */
export const ReportsSalesTimeseriesListPeriodEnum = {
    day: 'day',
    month: 'month',
    quarter: 'quarter',
    week: 'week',
    year: 'year'
} as const;
export type ReportsSalesTimeseriesListPeriodEnum = typeof ReportsSalesTimeseriesListPeriodEnum[keyof typeof ReportsSalesTimeseriesListPeriodEnum];
/**
 * @export
 */
export const ReportsSexTimeseriesListPeriodEnum = {
    day: 'day',
    month: 'month',
    quarter: 'quarter',
    week: 'week',
    year: 'year'
} as const;
export type ReportsSexTimeseriesListPeriodEnum = typeof ReportsSexTimeseriesListPeriodEnum[keyof typeof ReportsSexTimeseriesListPeriodEnum];
