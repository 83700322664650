import { Preferences } from "@capacitor/preferences"
import { Badge } from "@capawesome/capacitor-badge"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useAtom, useSetAtom } from "jotai"
import { loadable } from "jotai/utils"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"

import { fetchUser, logOutUser } from "../../requests/auth"
import { authTokenPreferenceAtom } from "../../storage/preferences.store"
import { QueryError } from "../../types/ReactQuery"
import { CleanUser } from "../../types/User"
import useExpirationCheck from "../useExpirationCheck"
import { userAtom } from "./useUser.store"
import { UserResult } from "./useUser.types"

export default function useUser(): UserResult {
    const queryClient = useQueryClient()
    const router = useRouter()

    // Pull the token from storage
    const [loadableToken] = useAtom(loadable(authTokenPreferenceAtom))
    const setToken = useSetAtom(authTokenPreferenceAtom)
    const token = useMemo(() => (loadableToken.state === "hasData" ? loadableToken.data : null), [loadableToken])

    // Store the user in memory to enable modifications
    const [storedUser, setStoredUser] = useAtom(userAtom)
    const [reloadReqeusted, setReloadRequested] = useState(false)

    // Pull a fresh user from the server using the token (if it exists)
    const {
        data: user,
        error,
        isLoading,
        isError,
    } = useQuery<CleanUser, QueryError, CleanUser, string[]>({
        queryKey: ["user", token],
        queryFn: () => fetchUser(token),
        staleTime: 60000,
        enabled: loadableToken.state === "hasData" && token !== null,
    })

    // Set the user in memory if it is not set
    useEffect(() => {
        if (user && (storedUser === null || reloadReqeusted)) {
            setStoredUser(user)
            setReloadRequested(false)
        }
    }, [user, storedUser, setStoredUser, reloadReqeusted])

    const [lockExpirationStatuses, drinkLockActive, drinkLockExpires] = useExpirationCheck(
        storedUser?.redemption_locks || {},
        storedUser?.drink_lock_expirations || [],
    )

    const reloadUser = useCallback(() => {
        setReloadRequested(true)
        queryClient.invalidateQueries({
            queryKey: ["user"],
        })
    }, [queryClient])

    const clearAuth = useCallback(() => {
        queryClient.clear()
        setStoredUser(null)
        setToken(null)
    }, [queryClient, setStoredUser, setToken])

    const logOut = useCallback(
        async (userToken: string) => {
            await Preferences.clear()
            try {
                await Badge.clear()
            } catch {}
            queryClient.clear()
            setStoredUser(null)
            setToken(null)

            logOutUser(router, clearAuth, userToken)
        },
        [queryClient, router, setStoredUser, setToken, clearAuth],
    )

    return {
        user: storedUser ?? null,
        setUser: setStoredUser,
        reloadUser: reloadUser,
        clearAuth: clearAuth,
        logOutUser: logOut,
        userLoading: loadableToken.state === "loading" || isLoading || user?.token !== storedUser?.token,
        userError: isError,
        userErrorMessage: error,
        lockExpirationStatuses,
        drinkLockActive,
        drinkLockExpires,
    }
}
