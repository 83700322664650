import { Capacitor } from "@capacitor/core"
import { AppUpdate, AppUpdateInfo } from "@capawesome/capacitor-app-update"
import Image from "next/image"
import { useMemo } from "react"

import { externalLinkHandler } from "../../../utils/links"
import Button from "../../buttons/Button/Button"
import styles from "./UpdateScreen.module.scss"

export default function UpdateScreen(props: { updateInfo: AppUpdateInfo }) {
    const { updateInfo } = props

    const handleUpdate = async () => {
        await AppUpdate.openAppStore()
    }

    const [currentVersion, availableVersion] = useMemo(() => {
        if (Capacitor.getPlatform() === "android") {
            return [updateInfo.currentVersionCode, updateInfo.availableVersionCode]
        } else {
            return [updateInfo.currentVersionName, updateInfo.availableVersionName]
        }
    }, [updateInfo])

    return (
        <div className={styles.container}>
            <div className={styles.logotype}>
                <Image src="/logotype.svg" priority={true} width={126.16} height={22.43} alt="SLOeats" />
            </div>
            <div className={styles.title}>
                It&apos;s Time For Something <strong>New.</strong>
            </div>
            <p className={styles.subtitle}>Update the SLOeats App for new features, deals, bug fixes, and more!</p>
            <div className={styles.image}>
                <Image src="/update_image.svg" width={310} height={310} alt="Image of app under construction." />
            </div>
            <Button
                variant="primary"
                content="Update to Latest Version"
                clickHandler={() => handleUpdate()}
                ignoreDebounce={true}
                extraClasses={[styles.updateButton]}
            />
            <span className={styles.versionDetails}>
                Version {currentVersion} &rarr; Version {availableVersion}
            </span>
            <div className={styles.legal}>
                <p>
                    {"By clicking Update to Latest Version, you agree to our "}
                    <Button
                        variant="text"
                        content="terms of service"
                        clickHandler={() => externalLinkHandler("https://sloeats.com/terms-of-service")}
                    />
                    {", "}
                    <Button
                        variant="text"
                        content="contest terms and conditions"
                        clickHandler={() => externalLinkHandler("https://sloeats.com/contest-terms-and-conditions")}
                    />
                    {", "}
                    <Button
                        variant="text"
                        content="referral terms and conditions"
                        clickHandler={() => externalLinkHandler("https://sloeats.com/referral-terms-and-conditions")}
                    />
                    {", and our "}
                    <Button
                        variant="text"
                        content="privacy policy"
                        clickHandler={() => externalLinkHandler("https://sloeats.com/privacy-policy")}
                    />
                    {"."}
                </p>
            </div>
        </div>
    )
}
