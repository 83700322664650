import Image from "next/image"

import useUser from "../../../hooks/User/useUser"
import useJune from "../../../hooks/useJune"
import { appLinkHandler } from "../../../utils/links"
import Button from "../../buttons/Button/Button"
import styles from "./ClientError.module.scss"

export default function ClientError() {
    const analytics = useJune()
    const { user } = useUser()

    return (
        <main className={styles.container}>
            <div className={styles.image}>
                <Image src="/error_image.svg" width={310} height={310} alt="Image of broken robot with Bug caption" />
            </div>
            <div className={styles.message}>
                <p className={styles.primary}>
                    <span className={styles.primaryTitle}>Oops! You found an error.</span>
                    <span className={styles.primarySubtitle}>Don&apos;t worry, try these options to fix it:</span>
                </p>
                <p className={styles.secondary}>
                    <ol>
                        <li>Update the SLOeats app.</li>
                        <li>
                            Go to your phone settings and update your operating system to the latest version. Then
                            completely close and reopen the SLOeats app.
                        </li>
                    </ol>
                </p>
                <p className={styles.tertiary}>
                    Still an issue?{" "}
                    <Button
                        variant="text"
                        clickHandler={() => {
                            analytics?.track("Click Support Email on Error Page")
                            appLinkHandler(
                                `mailto:support@sloeats.com?subject=App Error&body=Hi! I ran into an error in the SLOeats app and updating didn't help. Can you take a closer look? The phone number I use to log in to my SLOeats account is: ${user?.phone}.`,
                            )
                        }}
                        content="Email us."
                    />{" "}
                    We&apos;re fast to respond!
                </p>
            </div>
        </main>
    )
}
